import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Spinner } from '@aviobook/_shared';
import { LicenseAgreement } from '@aviobook/license-agreement/LicenseAgreement';
import Cookies from 'js-cookie';
import { COOKIE_ACCESS_TOKEN_NAME, useAuthenticate, useAuthenticationContext } from 'shared';
import { AuthenticatedUser } from 'types';

import { AuthorizedLayout } from './_routing/authorized-layout/AuthorizedLayout';
import { Login } from './auth/login/Login';
import { LoginFeedback } from './auth/loginFeedback/LoginFeedback';
import { CreateSmartAction } from './connect/_screens/general/_tabs';
import { UpdateSmartAction } from './connect/_screens/general/_tabs/smart-actions/smart-action/UpdateSmartAction';
import { Role } from './connect/_screens/roles/role/Role';
import { Connect } from './connect/Connect';

import './index-cms.scss';

export const AppCms: FC = () => {
  document.title = 'Connect Admin';
  const { t } = useTranslation();
  const { setAccessToken, setUser } = useAuthenticationContext();
  const location = useLocation();
  const { pathname, search } = location;

  const shouldAuthenticate = !search.includes('logout') && !pathname.includes('/auth/license-agreement');

  const { data: user, isLoading } = useAuthenticate(true, {
    enabled: shouldAuthenticate,
    onSuccess: user => {
      const accessToken = Cookies.get(COOKIE_ACCESS_TOKEN_NAME);
      setUser(user);
      setAccessToken(accessToken);
    },
  });

  if (isLoading && shouldAuthenticate) {
    return <Spinner overlay>{t('SHARED.LOADING_APPLICATION')}</Spinner>;
  }

  return getRoutes(user);
};

const getRoutes = (user: AuthenticatedUser) => {
  if (user) {
    if (user.hasAcceptedLicenseAgreement) {
      return <AuthenticatedRoutes />;
    }
    return <LicenseAgreementRoutes />;
  }
  return <NotAuthenticatedRoutes />;
};

const AuthenticatedRoutes: FC = () => {
  document.title = 'Connect Admin';

  return (
    <Routes>
      <Route element={<AuthorizedLayout />} path="connect/">
        <Route element={<Connect />} index />
        <Route element={<Navigate to="../?tab=Roles" />} path="roles" />
        <Route element={<UpdateSmartAction />} path="general/smart-actions/:smartActionName" />
        <Route element={<CreateSmartAction />} path="general/smart-actions" />
        <Route element={<Role />} path="roles/:roleId" />
      </Route>
      <Route element={<Navigate to="connect" />} path="*" />
    </Routes>
  );
};

const NotAuthenticatedRoutes: FC = () => {
  const { t } = useTranslation();

  return (
    <Routes>
      <Route element={<Login />} path="login" />
      <Route
        element={<LoginFeedback description={t('PERMISSIONDENIED_DESCRIPTION')} title={t('PERMISSIONDENIED_TITLE')} />}
        path="permission-denied"
      />
      <Route element={<Navigate to="login" />} path="*" />
    </Routes>
  );
};

const LicenseAgreementRoutes: FC = () => {
  return (
    <Routes>
      <Route element={<LicenseAgreement />} path="*" />
    </Routes>
  );
};
