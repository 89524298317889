import { AuthenticatedUser, FlightRoom, PaginationV1, RequestOptionsV1 } from 'types';

import { MessageUnion } from '../hooks';
import { API_FACTORY, getUrlWithQueryParams, HttpClient, parseRequestOptionsV1ToUrlParams } from '../utils';

export const getMessages = (
  roomId: FlightRoom['externalId'],
  externalUserId: AuthenticatedUser['externalId'],
  options: RequestOptionsV1<MessageUnion>,
) =>
  HttpClient.get<PaginationV1<MessageUnion>>(
    getUrlWithQueryParams(API_FACTORY.v3(`messages/${roomId}`), {
      query: `room=='${roomId}';(recipients.0=exists=false,recipients==${externalUserId})`,
    }),
    parseRequestOptionsV1ToUrlParams(options),
  );
