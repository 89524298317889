import { NestedObjectsPathKeys } from '../nestedObjectPathKeys';

export enum SortOptionsV1 {
  ASC = 'asc',
  DESC = 'desc',
}

export type Sorting<
  T extends object,
  Type extends SortOptionsV1,
  K extends string = NestedObjectsPathKeys<Required<T>>,
> = `${K}:${Type}`;
