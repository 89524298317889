import * as React from "react";
const doubleCheckmark: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2197 6.96967C15.5126 6.67678 15.9874 6.67678 16.2803 6.96967C16.5732 7.26256 16.5732 7.73744 16.2803 8.03033L12.1553 12.1553C11.8624 12.4482 11.3876 12.4482 11.0947 12.1553C10.8018 11.8624 10.8018 11.3876 11.0947 11.0947L15.2197 6.96967ZM7.71967 11.4697C8.01256 11.1768 8.48744 11.1768 8.78033 11.4697L12.7499 15.4392L21.2197 6.96967C21.4916 6.6977 21.9205 6.67827 22.2149 6.91139L22.2803 6.96967C22.5732 7.26256 22.5732 7.73744 22.2803 8.03033L13.5455 16.7652C13.1062 17.2045 12.3938 17.2045 11.9545 16.7652L7.71967 12.5303C7.42678 12.2374 7.42678 11.7626 7.71967 11.4697ZM2.78033 11.4697C2.48744 11.1768 2.01256 11.1768 1.71967 11.4697C1.42678 11.7626 1.42678 12.2374 1.71967 12.5303L5.9545 16.7652C6.39384 17.2045 7.10616 17.2045 7.5455 16.7652L8.40533 15.9053C8.69822 15.6124 8.69822 15.1376 8.40533 14.8447L8.33991 14.7864C8.04553 14.5533 7.61664 14.5727 7.34467 14.8447L6.74985 15.4392L2.78033 11.4697Z"
      fill="#10394E"
    />
  </svg>
);
export default doubleCheckmark;
