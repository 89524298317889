import { NotificationV1 } from 'types';

export enum NotificationsActionType {
  ADD_NOTIFICATION = 'ADD_NOTIFICATION',
  ADD_NOTIFICATIONS = 'ADD_NOTIFICATIONS',
  UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION',
}

export type NotificationAction =
  | {
      payload: NotificationV1;
      type: NotificationsActionType.ADD_NOTIFICATION;
    }
  | {
      payload: { notifications: NotificationV1[]; unreadCount: number };
      type: NotificationsActionType.ADD_NOTIFICATIONS;
    }
  | {
      payload: NotificationV1;
      type: NotificationsActionType.UPDATE_NOTIFICATION;
    };
