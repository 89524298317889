import { useQuery } from '@tanstack/react-query';
import { HttpClient } from 'shared';
import { API_FACTORY } from 'shared/src/utils/factories/api.factory';
import { ApiError, QueryKeys, SmartAction } from 'types';

const smartAction = (smartActionId: string): Promise<SmartAction> =>
  HttpClient.get(API_FACTORY.v1(`admin/smart-actions/${smartActionId}`));

export const useSmartAction = (smartActionId: string) =>
  useQuery<SmartAction, ApiError>([QueryKeys.SMART_ACTION, smartActionId], () => smartAction(smartActionId));
