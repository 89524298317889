import * as React from "react";
const checkCircleOutline: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 .375a9.625 9.625 0 1 1 0 19.25 9.625 9.625 0 0 1 0-19.25zm0 1.375a8.25 8.25 0 1 0 0 16.5 8.25 8.25 0 0 0 0-16.5zm4.664 5.101.06.054a.688.688 0 0 1-.002.972l-5.607 5.587c-.403.401-1.054.401-1.456 0L5.207 11.02a.688.688 0 0 1 .97-.974l2.21 2.203 5.365-5.346a.688.688 0 0 1 .912-.052z"
      fill="#10394E"
      fillRule="evenodd"
    />
  </svg>
);
export default checkCircleOutline;
