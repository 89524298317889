import { FC } from 'react';

import { Tab } from './PageContent';

type TabContentProps = {
  selectedTab: number;
  tabs: Tab[];
};

export const TabContent: FC<TabContentProps> = ({ selectedTab, tabs }) => {
  const MyTab = tabs.at(selectedTab);

  if (MyTab) {
    return MyTab.screen();
  }

  return null;
};
