import * as React from "react";
const doubleCheckmarkBgCircle: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="10.5" fill="#25C164" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.71886 12.0519C5.01131 11.7586 5.48618 11.7578 5.77952 12.0503L8.18918 14.4526L8.71744 13.9263L9.77811 14.9869L8.98313 15.779C8.54403 16.2165 7.83378 16.2164 7.39482 15.7787L4.72048 13.1126C4.42714 12.8201 4.42642 12.3452 4.71886 12.0519ZM12.2724 12.5018L11.2117 11.4412L14.042 8.62128C14.3354 8.32893 14.8103 8.3298 15.1027 8.62323C15.395 8.91667 15.3941 9.39154 15.1007 9.68389L12.2724 12.5018ZM10.2795 12.0503C9.98618 11.7578 9.51131 11.7586 9.21886 12.0519C8.92642 12.3452 8.92714 12.8201 9.22048 13.1126L11.8948 15.7787C12.3338 16.2164 13.044 16.2165 13.4831 15.779L19.6007 9.68389C19.8941 9.39154 19.895 8.91667 19.6027 8.62324C19.3103 8.3298 18.8354 8.32893 18.542 8.62128L12.6892 14.4526L10.2795 12.0503Z"
      fill="white"
    />
  </svg>
);
export default doubleCheckmarkBgCircle;
