import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@aviobook/_shared';
import { IconName } from 'assets';
import { COLORS } from 'styles';
import { GiftedMessage } from 'types';

type Props = {
  user: GiftedMessage<IconName>['user'];
};

export const UserInfo: FC<Props> = ({ user }) => {
  const { t } = useTranslation();

  if (!user?._id) {
    return (
      <div className="message-bubble-avatar-name">
        <Text color={COLORS.zulu.$08} size="SM" uppercase weight="bold">
          {t('USER.UNKNOWN')}
        </Text>
      </div>
    );
  }

  return (
    <div className="message-bubble-avatar-name">
      <Text color={COLORS.zulu.$08} size="SM" uppercase weight="bold">
        {user.title}
      </Text>
      {user.name ? (
        <Text color={COLORS.zulu.$04} size="XS" weight="semiBold">
          &nbsp;-&nbsp;{user.name}
        </Text>
      ) : null}
    </div>
  );
};
