import React, { PropsWithChildren } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { DEFAULT_FLIGHTROOMS_CONTEXT, FlightroomsContext } from '../../context';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

export const wrapper = ({ children }: PropsWithChildren) => (
  <QueryClientProvider client={queryClient}>
    <FlightroomsContext.Provider value={{ ...DEFAULT_FLIGHTROOMS_CONTEXT }}>{children}</FlightroomsContext.Provider>
  </QueryClientProvider>
);
