import * as React from "react";
const exclamationCircleOutline: React.FC<
  React.SVGProps<SVGSVGElement>
> = () => (
  <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 1.375a9.625 9.625 0 1 1 0 19.25 9.625 9.625 0 0 1 0-19.25zm0 1.375a8.25 8.25 0 1 0 0 16.5 8.25 8.25 0 0 0 0-16.5zm0 12.031a.687.687 0 1 1 0 1.375.687.687 0 0 1 0-1.375zM11 5.5c.38 0 .688.308.688.688v6.875a.687.687 0 1 1-1.376 0V6.186c0-.38.308-.687.688-.687z"
      fill="#10394E"
      fillRule="evenodd"
    />
  </svg>
);
export default exclamationCircleOutline;
