export interface ProviderComposerProps extends React.PropsWithChildren {
  providers: React.FC<React.PropsWithChildren>[];
}

const ComposerFragment: React.FC<React.PropsWithChildren> = ({ children }): JSX.Element => <>{children}</>;

const providerReducer =
  (ParentProvider: React.FC<React.PropsWithChildren>, ChildProvider: React.FC<React.PropsWithChildren>) =>
  ({ children }: React.PropsWithChildren) => (
    <ParentProvider>
      <ChildProvider>{children}</ChildProvider>
    </ParentProvider>
  );

export const ProviderComposer = ({ children, providers }: ProviderComposerProps) => {
  const ComposedProviders = providers.reduce(providerReducer, ComposerFragment);

  return <ComposedProviders>{children}</ComposedProviders>;
};
