import * as React from "react";
const chevronRight: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.763 6.679a1.003 1.003 0 0 1 .055-1.413.992.992 0 0 1 1.407.055l6.008 6.527c.354.385.353.978-.003 1.361l-6.008 6.47a.992.992 0 0 1-1.408.048 1.003 1.003 0 0 1-.048-1.413l5.377-5.79-5.38-5.845z"
      fillRule="evenodd"
    />
  </svg>
);
export default chevronRight;
