import * as React from "react";
const close: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5859 12L5.29297 17.2929L6.70718 18.7071L12.0001 13.4142L17.293 18.7071L18.7072 17.2929L13.4143 12L18.7072 6.70712L17.293 5.29291L12.0001 10.5858L6.70718 5.29291L5.29297 6.70712L10.5859 12Z"
      fill="#00395D"
    />
  </svg>
);
export default close;
