import { Division, JobTitleV1 } from 'types';

import { UserV1Util } from '../users';

export const isEmptyString = (value: string): boolean => !value || value.trim() === '';

export const replaceJobTitlePlaceholders = (text: string): string => {
  const regex = /\{\{(.+?)\}\}/;
  const match = text.match(regex);

  if (match && match[1]) {
    const valueToReplace = match[0];
    const replaceValue: Division = UserV1Util.getDivision(match[1] as JobTitleV1);
    text = text.replace(valueToReplace, replaceValue);
  }

  return text;
};
