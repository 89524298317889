import { FlightArrivalStatus } from 'types';

import { AvioDateTime } from '../../../utils';

const getYesterday = () => AvioDateTime().subtract({ day: 1 }).toISO8601String();
const getToday = () => AvioDateTime().toISO8601String();
const getTomorrow = () => AvioDateTime().add({ day: 1 }).toISO8601String();

const getActiveFiql = () => {
  return `((schedule.ata!=null;schedule.ata=ge=${getToday()}),(schedule.ata==null;schedule.eta!=null;schedule.eta=ge=${getToday()}),(schedule.ata==null;schedule.eta==null;schedule.sta=ge=${getToday()}));(schedule.std=ge=${getYesterday()};schedule.std=le=${getTomorrow()})`;
};
const getCompletedFiql = () => {
  return `(schedule.ata!=null;schedule.ata=ge=${getYesterday()};schedule.ata=le=${getToday()}),(schedule.ata==null;schedule.eta!=null;schedule.eta=ge=${getYesterday()};schedule.eta=le=${getToday()}),(schedule.ata==null;schedule.eta==null;schedule.sta=ge=${getYesterday()};schedule.sta=le=${getToday()})`;
};
const getActiveAndCompletedFiql = () => `(${getActiveFiql()}),(${getCompletedFiql()})`;

export const getRoomArrivalFiql = (arrivalStatus: FlightArrivalStatus): string => {
  // TODO: convert to RootFiql and fix tests

  let roomArrivalFiql: string;

  switch (arrivalStatus) {
    case FlightArrivalStatus.COMPLETED:
      roomArrivalFiql = getCompletedFiql();
      break;
    case FlightArrivalStatus.ACTIVE:
      roomArrivalFiql = getActiveFiql();
      break;
    case FlightArrivalStatus.ALL_WITHIN_RANGE:
      roomArrivalFiql = getActiveAndCompletedFiql();
      break;
  }

  return roomArrivalFiql;
};

export const getSearchFiql = (search?: string): string => {
  // TODO: convert to RootFiql and fix tests
  if (!search) {
    return getActiveAndCompletedFiql();
  }

  const flightNumberRegexFiql = `flightNumber=caseinsensitive=${search}`;
  return `(${getActiveAndCompletedFiql()});(${flightNumberRegexFiql})`;
};

export const getFlightroomByIdFiql = (id: string): string => {
  return `(${getActiveAndCompletedFiql()});(externalId==${id})`;
};
