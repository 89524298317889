import { FormEvent, useEffect, useState } from 'react';
import { deepCopy, isEmptyObject, ValidatorResponse } from 'shared';
import i18n from 'translations';
import { ApiError, ValidationError } from 'types';

import useToggle from './useToggle';

/**
 * FormValidationErrors type explanation:
 * 1. We check to see if the value of property Key is a primitive, if it is, we just require a validator response (IValidatorResponse).
 * 2. We check if the value of property Key is an array, if it is, we proceed to 3, else to 5
 * 3. We check if the Type of the element of the array, using infer, is a Primitive.
 *    If the value is not a Primitive, proceed to 4, otherwise, we just require a list of validator responses (IValidatorResponse[]).
 * 4. If the Array is not a primitive, we use the type we extracted with infer and require an array of FormValidationErrors<InferredArrayType>.
 * 5. If the array is not a primitive, and not an array, it's an object, so we just recursively use FormValidationErrors with the given type.
 */
type Primitive = string | number | boolean;
export type FormValidationErrors<Form = Record<string, unknown>> = {
  [Key in keyof Form]?: Form[Key] extends Primitive // 1.
    ? ValidatorResponse
    : Form[Key] extends Array<infer TArray> // 2.
      ? TArray extends Primitive // 3.
        ? ValidatorResponse[]
        : Array<FormValidationErrors<TArray>> // 4
      : FormValidationErrors<Form[Key]>; // 5
};

export type SubmitFormFunction<Form> = (values: Form, setFormValues: (values: Form) => void) => void;
type ValidateFormFunction<Form, FormErrors> = (values: Form) => FormValidationErrors<FormErrors>;

type Params<Form, FormErrors> = {
  error?: ApiError;
  initialForm: Form;
  submitForm: SubmitFormFunction<Form>;
  validateForm: ValidateFormFunction<Form, FormErrors>;
};

export type Response<Form, FormErrors> = {
  hasValidationErrors: boolean;
  isDirty: boolean;
  setAttribute: (value: unknown, name: string) => void;
  setValues: (values: Form) => void;
  submit: (event: FormEvent) => boolean;
  submitWithParams: (event: FormEvent, params: Partial<Params<Form, FormErrors>>) => boolean;
  validationErrors: FormValidationErrors<FormErrors>;
  values: Form;
};

export type FormHook<Form, FormErrors = Form> = Response<Form, FormErrors>;

const mapToFormValidationErrors = <Form>(error: ApiError): FormValidationErrors<Form> => {
  type ValidationMap = {
    [key: string]: ValidatorResponse | ValidationError | ValidationMap;
  };
  const mapError = (validationError: ValidationError): ValidationMap | ValidatorResponse => {
    if (validationError.children.length > 0) {
      return validationError.children.reduce(
        (acc, child) => ({
          ...acc,
          [child.property]: {
            ...mapError(child),
          },
        }),
        <ValidationMap>{},
      );
    }
    let message: string = i18n.t('ERRORS.VALIDATION.INVALID');
    if (validationError.constraints?.isNotEmpty) {
      message = i18n.t('ERRORS.VALIDATION.REQUIRED');
    }
    return { isValid: false, message };
  };

  return Object.keys(error.validationErrors).reduce(
    (acc, key) => ({
      ...acc,
      [key]: {
        ...mapError(error.validationErrors[key]),
      },
    }),
    <FormValidationErrors<Form>>{},
  );
};

const isValidatorResponse = (object: unknown): object is ValidatorResponse => Object.keys(object).includes('isValid');

export const hasValidationErrors = (errors: FormValidationErrors): boolean => {
  if (isEmptyObject(errors)) {
    return false;
  }
  if (Array.isArray(errors)) {
    return errors.some(hasValidationErrors);
  }
  if (typeof errors === 'object') {
    if (isValidatorResponse(errors)) {
      return !errors.isValid;
    }
    return Object.keys(errors).some(key => hasValidationErrors(errors[key]));
  }
  return false;
};

const useForm = <Form, FormErrors = Form>(params: Params<Form, FormErrors>): Response<Form, FormErrors> => {
  const { error, initialForm, submitForm, validateForm } = params;
  const [values, setFormValues] = useState<Form>(initialForm);
  const [validationErrors, setValidationErrors] = useState<FormValidationErrors<FormErrors>>({});
  const [isDirty, setIsDirty] = useToggle(false);

  const submit = (
    event: FormEvent,
    sumbitFunction: SubmitFormFunction<Form> = submitForm,
    validateFunction: ValidateFormFunction<Form, FormErrors> = validateForm,
  ): boolean => {
    event.preventDefault();
    const errors = validateFunction(values);
    const hasErrors = hasValidationErrors(errors);
    if (!hasErrors) {
      sumbitFunction(values, setFormValues);
      setIsDirty(false);
    }
    setValidationErrors(errors);
    return !hasErrors;
  };

  /**
   * In some cases, you want to use a different submit / validate function than the default one.
   */
  const submitWithParams = (event: FormEvent, params: Partial<Params<Form, FormErrors>>): boolean =>
    submit(event, params.submitForm, params.validateForm);

  /**
   * Use this function if the (simple) name of the field matches the name within the form.
   * Do not use it when the field is an array or (part of) a nested object. Use 'setValues' instead.
   *
   * The name of the input field should be equal to the simple property name within the form.
   * E.g. By using this function with '<Input name='title' />', the new value will be set on 'values.title'.
   */
  const setAttribute = (value: unknown, name: string) => {
    setFormValues({ ...values, [name]: value });
    setIsDirty(true);
  };

  /**
   * Use this function if you cannot change the value with 'setAttribute' because it is (part of) a nested object or an array.
   * If it is a simple value, we recommend to use 'setAttribute' for performance reasons.
   *
   * The name of the input field is not used to set any value here, as the value is set directly in the values
   */
  const setValues = (values: Form) => {
    const newValues = deepCopy(values);
    setFormValues(newValues);
    setIsDirty(true);
  };

  // Map server errors to form validation errors
  useEffect(() => {
    if (error?.validationErrors) {
      setValidationErrors(mapToFormValidationErrors(error));
    }
  }, [error]);

  useEffect(() => {
    const clearValues = () => setFormValues(initialForm);

    setFormValues(initialForm);
    setIsDirty(false);
    // Clear all if the component unmounts
    return () => {
      clearValues();
      setValidationErrors({});
    };
  }, [initialForm, setFormValues, setIsDirty]);

  return {
    hasValidationErrors: hasValidationErrors(validationErrors),
    isDirty,
    setAttribute,
    setValues,
    submit,
    submitWithParams,
    validationErrors,
    values,
  };
};

export default useForm;
