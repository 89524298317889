export const bogota = {
  $01: '#8c8c8c',
  $02: '#808080',
  $03: '#737373',
  $04: '#666666',
  $05: '#595959',
  $06: '#4d4d4d',
  $07: '#404040',
  $08: '#333333',
  $09: '#262626',
  $10: '#1a1a1a',
  $11: '#0d0d0d',
  $12: '#000000',
} as const;
