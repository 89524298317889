import * as React from "react";
const sort: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.68678 15.2738L4.49658 17.4409V0H3.14571V17.4409L0.955511 15.2738L0 16.2192L3.82114 20L7.64229 16.2192L6.68678 15.2738ZM13.3114 7.68456V1.66986H11.9606V3.00646H10.6097V4.34306H11.9606V7.68456H10.272V9.02116H15V7.68456H13.3114ZM12.4676 11.0261H12.6531C13.2753 11.0268 13.8718 11.2716 14.3118 11.707C14.7518 12.1423 14.9993 12.7325 15 13.3482V15.5037C14.999 16.3542 14.6572 17.1695 14.0494 17.7709C13.4416 18.3723 12.6175 18.7106 11.7579 18.7115H10.9474V17.3749H11.7579C12.2359 17.3737 12.6957 17.1939 13.0454 16.8716C13.3949 16.5493 13.6086 16.1084 13.6437 15.637C13.3439 15.7762 13.0085 15.8539 12.6549 15.8539H12.4676C11.8454 15.8532 11.2488 15.6083 10.8089 15.1729C10.3689 14.7376 10.1214 14.1474 10.1207 13.5317V13.3482C10.1214 12.7325 10.3689 12.1423 10.8089 11.707C11.2488 11.2716 11.8454 11.0268 12.4676 11.0261ZM12.4676 14.5182H12.6531C12.9172 14.5179 13.1704 14.414 13.3571 14.2292C13.5439 14.0445 13.6489 13.7939 13.6491 13.5326V13.3491C13.6489 13.0878 13.5439 12.8372 13.3571 12.6525C13.1704 12.4677 12.9172 12.3638 12.6531 12.3636H12.4676C12.2035 12.3638 11.9503 12.4677 11.7635 12.6525C11.5768 12.8372 11.4718 13.0878 11.4715 13.3491V13.5326C11.4718 13.7939 11.5768 14.0445 11.7635 14.2292C11.9503 14.414 12.2035 14.5179 12.4676 14.5182Z"
      fill="white"
    />
  </svg>
);
export default sort;
