import { FC, useMemo } from 'react';
import { Tab as TabType } from '@aviobook/cms/_shared/pageContent/PageContent';
import { FontSize } from 'styles';

import { Tab } from './Tab';

import './tabBar.scss';

type Props = {
  currentPage: number;
  navBarClassName?: 'top-nav-bar' | 'nav-bar';
  setTab: (tab: number) => void;
  tabRoutes: TabType[];
  textSize?: FontSize;
};

export const TabBar: FC<Props> = ({ currentPage, navBarClassName, setTab, tabRoutes, textSize }) => {
  const tabs = useMemo(() => {
    return tabRoutes.map((tab, index) => {
      const isActiveTab = index === currentPage;
      return <Tab isActiveTab={isActiveTab} key={tab.name} name={tab.label} setTab={() => setTab(index)} textSize={textSize} />;
    });
  }, [currentPage, setTab, tabRoutes, textSize]);

  return (
    <nav className={navBarClassName}>
      <ul className="nav-bar-sub-ul">{tabs}</ul>
    </nav>
  );
};
