import { useMutation } from '@tanstack/react-query';
import { ApiError, AttachmentResponseV1, QueryKeys } from 'types';

import { uploadAttachment } from '../queries/uploadAttachment';

export const useUploadAttachment = () => {
  const { mutateAsync } = useMutation<AttachmentResponseV1, ApiError, File>([QueryKeys.UPLOAD_ATTACHMENT], uploadAttachment, {});

  return (file: File) => mutateAsync(file);
};
