import { FC, useMemo } from 'react';

import { TabBar as TabBarType } from './TabBar.types';

import './tabBar.scss';

type Props = {
  selected: TabBarType['id'];
  tabs: TabBarType[];
};

export const TabBar: FC<Props> = ({ selected, tabs }) => {
  const handleTabClick = (tab: TabBarType) => {
    if (tab.onClick) {
      tab.onClick();
    }
  };

  const mappedTabs = useMemo(() => {
    return tabs.map(tab => ({
      ...tab,
      selected: tab.id === selected,
    }));
  }, [tabs, selected]);

  return (
    <div className="tab-bar">
      <ul className="tab-list">
        {mappedTabs.map(tab => (
          <li className={`tab-item ${tab.selected ? 'active' : ''}`} key={tab.id} onClick={() => handleTabClick(tab)}>
            {tab.text}
          </li>
        ))}
      </ul>
    </div>
  );
};
