import * as React from "react";
const clockScheduleOutline: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5 2.344c5.61 0 10.156 4.547 10.156 10.156 0 5.61-4.547 10.156-10.156 10.156-5.61 0-10.156-4.547-10.156-10.156C2.344 6.89 6.89 2.344 12.5 2.344zm0 1.562a8.594 8.594 0 1 0 0 17.188 8.594 8.594 0 0 0 0-17.188zm.25 3.125c.432 0 .782.35.782.782v4.512c0 .334-.142.652-.391.874l-2.464 2.202a.781.781 0 0 1-1.103-.062l-.061-.077a.781.781 0 0 1 .123-1.026l2.333-2.086V7.812c0-.431.35-.78.782-.78z"
      fill="#0F384D"
      fillRule="evenodd"
    />
  </svg>
);
export default clockScheduleOutline;
