import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { HttpClient } from 'shared';
import { API_FACTORY } from 'shared/src/utils/factories/api.factory';
import { ApiError } from 'types';

import { SettingsItems } from './types';

const updateSettings = (settings: SettingsItems): Promise<SettingsItems> =>
  HttpClient.put<SettingsItems>(API_FACTORY.v1('settings'), settings);

export const useUpdateSettings = (options?: UseMutationOptions<SettingsItems, ApiError, SettingsItems, SettingsItems>) => {
  return useMutation<SettingsItems, ApiError, SettingsItems, SettingsItems>(['settings'], updateSettings, options);
};
