import { t } from 'i18next';
import { JobTitleV1, NotificationType, NotificationV1 } from 'types';

import { DATE_TIME_FORMATS } from '../date-time';
import { parseDate } from '../date-time/lib/avio-date-time.util';
import { replaceJobTitlePlaceholders } from '../strings';
import { UserV1Util } from '../users';

export const mapNotificationItem = (notification: NotificationV1) => {
  let displayText: string;
  const timestamp = parseDate(notification.createdAt).formatUTC(DATE_TIME_FORMATS.DATE_TIME_LONG_NO_ZONE_FORMAT);

  const { crewRoleName, jobTitle, username } = notification.triggeredBy;
  const flightNumber = notification.flightNumber;

  switch (notification.type) {
    case NotificationType.ACCESS_REQUEST:
      displayText = t('NOTIFICATION_CENTER.NOTIFICATION_ITEM.ACCESS_REQUEST')
        .replace('{{userInfo}}', `${crewRoleName}, ${username}`)
        .replace('{{flightNumber}}', flightNumber);
      break;
    case NotificationType.MENTION:
      displayText = t('NOTIFICATION_CENTER.NOTIFICATION_ITEM.MENTION')
        .replace('{{username}}', username)
        .replace('{{jobTitle}}', UserV1Util.getDivision(jobTitle as JobTitleV1))
        .replace('{{flightNumber}}', flightNumber);
      break;
    default:
      displayText = t('NOTIFICATION_CENTER.NOTIFICATION_ITEM.DEFAULT').replace('{{flightNumber}}', flightNumber);
      break;
  }

  return { displayText: replaceJobTitlePlaceholders(displayText), timestamp };
};

export const sortNotificationsByCreatedAt = (n1: NotificationV1, n2: NotificationV1) => {
  const f1Std = new Date(n1.createdAt).getTime();
  const f2Std = new Date(n2.createdAt).getTime();

  return f2Std - f1Std;
};
