import { MutationOptions, useMutation } from '@tanstack/react-query';
import { HttpClient } from 'shared';
import { API_FACTORY } from 'shared/src/utils/factories/api.factory';
import { ApiError } from 'types';

export const acceptAgreement = (): Promise<void> =>
  HttpClient.post(API_FACTORY.v1('/users/accept-agreement'), { hasAccepted: true });

export const useAcceptAgreement = (options?: MutationOptions<void, ApiError>) =>
  useMutation<void, ApiError>(['acceptAgreement'], acceptAgreement, options);
