import { useQueryClient } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import { COOKIE_ACCESS_TOKEN_NAME, useEnvConfig } from 'shared';

export const useLogout = () => {
  const queryClient = useQueryClient();
  const { connectServiceApiHost } = useEnvConfig();

  return async () => {
    Cookies.remove(COOKIE_ACCESS_TOKEN_NAME);
    await queryClient.cancelQueries();
    queryClient.clear();
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = `${connectServiceApiHost}/idp/logout?origin=${connectServiceApiHost}`;
  };
};
