import * as React from "react";
const singleCheckmark: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.71966 11.4697C5.01255 11.1768 5.48743 11.1768 5.78032 11.4697L9.74984 15.4392L18.2197 6.96967C18.4916 6.6977 18.9205 6.67827 19.2149 6.91139L19.2803 6.96967C19.5732 7.26256 19.5732 7.73744 19.2803 8.03033L10.5455 16.7652C10.1061 17.2045 9.39384 17.2045 8.9545 16.7652L4.71966 12.5303C4.42677 12.2374 4.42677 11.7626 4.71966 11.4697Z"
      fill="#10394E"
    />
  </svg>
);
export default singleCheckmark;
