import { FC } from 'react';
import { useAuthenticationContext } from 'shared';
import { ConnectivityStatusV1 } from 'types';

import './statusDot.scss';

type DotSize = 'small' | 'medium' | 'large' | number;

type Props = {
  size?: DotSize;
  status: ConnectivityStatusV1;
  userId: string;
};

export const StatusDot: FC<Props> = ({ size, status, userId }) => {
  const { user } = useAuthenticationContext();

  const getDotSizeClassName = () => {
    if (typeof size === 'number') {
      return 'custom-size';
    }
    return size || 'default';
  };

  const getDotColorClassName = () => {
    if (!userId) {
      return 'default';
    }

    if (userId === user?.externalId) {
      return 'success';
    }
    switch (status) {
      case ConnectivityStatusV1.OFFLINE:
        return 'warning';
      case ConnectivityStatusV1.ONLINE:
        return 'success';
      default:
        return 'default';
    }
  };

  const dotSizeClassName = getDotSizeClassName();
  const dotColorClassName = getDotColorClassName();

  const className = `status-dot ${dotSizeClassName} ${dotColorClassName}`;

  return <div className={className} />;
};
