import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from '@aviobook/_shared';
import { useEnvConfig } from 'shared';

export const Login: FC = () => {
  const { connectServiceApiHost } = useEnvConfig();
  const { t } = useTranslation();

  useEffect(() => {
    window.location.href = `${connectServiceApiHost}/idp/login?origin=${connectServiceApiHost}/admin/connect`;
  }, [connectServiceApiHost]);

  return <Spinner overlay>{t('SHARED.LOADING_APPLICATION')}</Spinner>;
};
