import * as React from "react";
const exclamationHexagonFilled: React.FC<
  React.SVGProps<SVGSVGElement>
> = () => (
  <svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path
        d="m22.351 13.244-4.28 7.91c-.274.504-.801.818-1.375.818H8.304a1.562 1.562 0 0 1-1.374-.819l-4.281-7.91a1.563 1.563 0 0 1 0-1.487l4.28-7.91c.274-.504.801-.818 1.375-.818h8.392c.574 0 1.101.314 1.374.819l4.281 7.91a1.563 1.563 0 0 1 0 1.487z"
        fill="#D32336"
      />
      <path
        d="M12.5 6.64c.431 0 .781.35.781.782v7.812a.781.781 0 1 1-1.562 0V7.422c0-.432.35-.781.781-.781zm0 12.5a.781.781 0 1 0 0-1.562.781.781 0 0 0 0 1.563z"
        fill="#FFF"
      />
    </g>
  </svg>
);
export default exclamationHexagonFilled;
