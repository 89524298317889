import { QueryClient } from '@tanstack/react-query';

import { Config } from '../config';

export const queryClient = new QueryClient({
  defaultOptions: {
    ...Config.reactQuery,
    queries: {
      ...Config.reactQuery?.queries,
      refetchOnWindowFocus: false,
    },
  },
});
