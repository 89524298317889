import { useCallback, useEffect } from 'react';
import Cookies from 'js-cookie';
import { calculateTimeUntilRefresh, COOKIE_ACCESS_TOKEN_NAME, useAuthenticationContext } from 'shared';
import { refreshAccessToken } from 'shared/src/queries/auth';

export const useAccessTokenRefresh = () => {
  const { accessToken, setAccessToken } = useAuthenticationContext();

  const refresh = useCallback(async () => {
    try {
      const response = await refreshAccessToken();
      if (response.status >= 200 && response.status < 300) {
        setAccessToken(Cookies.get(COOKIE_ACCESS_TOKEN_NAME));
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Failed to refresh access token', e);
    }
  }, [setAccessToken]);

  useEffect(() => {
    if (!accessToken) {
      return;
    }
    const timeUntilRefresh = calculateTimeUntilRefresh(accessToken);

    if (!timeUntilRefresh) {
      return;
    }

    if (timeUntilRefresh <= 0) {
      refresh();
    } else {
      const refreshTimeout = setTimeout(refresh, timeUntilRefresh * 1000); // Convert to milliseconds

      // Clear the timeout if the component unmounts
      return () => {
        clearTimeout(refreshTimeout);
      };
    }
  }, [refresh, accessToken]);
};
