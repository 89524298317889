import BaseFiqlGroup from './base-types/base-fiql-group.abstract.class';
import BaseFiqlNode from './base-types/base-fiql-node.abstract.class';
import BaseFiql from './types/base.interface';
import FiqlGroupOperators from './types/fiql-group-operators.enum';
import FiqlOperators from './types/fiql-operators.enum';

export class RootFiql<T extends object> implements BaseFiql {
  private _fiql: BaseFiqlGroup<FiqlGroupOperators, T> | BaseFiqlNode<FiqlOperators, T>;

  public constructor(fiql: BaseFiqlGroup<FiqlGroupOperators, T> | BaseFiqlNode<FiqlOperators, T>) {
    this._fiql = fiql;
  }

  public parse(): string {
    const fiql = this._fiql.parse();

    if (fiql.startsWith('(') && fiql.endsWith(')')) {
      return fiql.substring(1, fiql.length - 1);
    }

    return fiql;
  }
}
