export enum RoomRoleTitle {
  ACM = 'ACM',
  CCM = 'CCM',
  COMM = 'COMM',
  COPILOT = 'COPILOT',
  DEADHEAD = 'DEADHEAD',
  GH = 'GH',
  SCCM = 'SCCM',
  UNKNOWN = 'UNKNOWN',
}
