import { FC } from 'react';
import { Text } from '@aviobook/_shared';
import paper_airplane from 'assets/images/paper_airplane.png';
import { COLORS } from 'styles';

type Props = {
  description: string;
  title: string;
};

export const LoginFeedback: FC<Props> = ({ description, title }) => {
  return (
    <main className="container">
      <div className="content-container confirmation">
        <img alt="logo" height={240} src={paper_airplane} />
        <Text as="h1" className="confirmation-title" color={COLORS.zulu.$08} size="XL" weight="bold">
          {title}
        </Text>
        <Text as="p" color={COLORS.zulu.$08} size="SM">
          {description}
        </Text>
      </div>
    </main>
  );
};
