import React, { FC } from 'react';
import classNames from 'classnames';
import { COLORS, FontSize } from 'styles';

import { Text } from '../text/Text';

import './unreadDot.scss';

type Props = {
  amount: number;
  className?: string;
  fontSize?: FontSize;
  size?: 'small' | 'medium' | 'large';
  testId?: string;
};

export const UnreadDot: FC<Props> = ({ amount, className, fontSize = 'SM', size = 'small', testId }) => {
  return (
    <div className={classNames('unread-dot-container', size, className)} data-test={testId}>
      <Text color={COLORS.zulu.$10} size={fontSize} weight="bold">
        {amount}
      </Text>
    </div>
  );
};
