export const uniqBy = <T>(arr: T[], iteratee: ((item: T) => T[keyof T]) | string): T[] => {
  let internalFunc: (item: T) => T[keyof T];

  if (typeof iteratee === 'string') {
    const prop = iteratee as keyof T;
    internalFunc = (item: T) => item[prop];
  } else {
    internalFunc = iteratee;
  }

  return arr.filter((x, i, self) => i === self.findIndex(y => internalFunc(x) === internalFunc(y)));
};

export const areArraysEqual = <T>(arr1: Array<T>, arr2: Array<T>, sort = false): boolean => {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
    return false;
  }
  if (arr1.length !== arr2.length) {
    return false;
  }

  if (sort) {
    arr1 = arr1.sort();
    arr2 = arr2.sort();
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
};

const isArray = <T>(value: T[]) => Array.isArray(value);

export const isEmptyArray = <T>(value: T[]): boolean => {
  if (!value) {
    return false;
  }
  return isArray(value) && value.length === 0;
};
