import * as React from "react";
const circularCrossPaddingFilled: React.FC<
  React.SVGProps<SVGSVGElement>
> = () => (
  <svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <circle fill="#144761" cx="12.5" cy="12.5" r="10.938" />
      <path
        d="M16.92 8.08c.283.284.303.73.06 1.037l-.06.068-3.315 3.315 3.314 3.315a.781.781 0 0 1-1.036 1.165l-.068-.06-3.315-3.315-3.315 3.314a.781.781 0 0 1-1.165-1.036l.06-.068 3.315-3.315L8.08 9.185A.781.781 0 0 1 9.117 8.02l.068.06 3.315 3.315 3.315-3.314a.781.781 0 0 1 1.104 0z"
        fill="#FFF"
      />
    </g>
  </svg>
);
export default circularCrossPaddingFilled;
