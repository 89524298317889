import * as React from "react";
const roleDefault: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg viewBox="0 0 25 25" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Icons/Finals/Aviation/Roles/role-default</title>
    <g
      id="Icons/Finals/Aviation/Roles/role-default"
      stroke="none"
      strokeWidth="1"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.202 1.5c3.055 0 4.757 1.4 5.269 3.678.172.767.196 1.52.13 2.36l-.052.518.01.013c.77 1.082.412 2.996-.63 3.634l-.087.048-.01.038a6.82 6.82 0 0 1-.759 1.739l-.148.236a8.64 8.64 0 0 1-.682.915l-.144.16.02.146.023.13.015.068.022.081.132.027c2.823.616 7.202 3.61 8.5 6.845l.08.211a.781.781 0 1 1-1.47.528c-1.021-2.85-5.37-5.753-7.702-6.106-.675-.102-.959-.613-1.108-1.34a4.72 4.72 0 0 1-.094-.88.781.781 0 0 1 .246-.575l.073-.073a6.993 6.993 0 0 0 .78-.99c.4-.615.68-1.261.793-1.91a.781.781 0 0 1 .77-.648c-.027 0 .118-.181.196-.524.095-.414.02-.776-.129-.897a.781.781 0 0 1-.29-.646l.06-.589c.085-.818.08-1.511-.07-2.176-.353-1.574-1.429-2.458-3.744-2.458-2.316 0-3.391.884-3.744 2.458-.133.59-.152 1.204-.096 1.908l.082.82.003.037a.781.781 0 0 1-.29.646c-.149.12-.223.483-.128.897.078.343.223.524.197.524.38 0 .705.274.77.649.111.648.392 1.294.793 1.91.19.294.396.56.6.794l.128.141.124.127c.159.15.248.358.246.576a4.719 4.719 0 0 1-.094.879c-.15.726-.433 1.238-1.108 1.34-2.575.39-6.542 2.958-7.668 6.105a.781.781 0 0 1-1.471-.526c1.289-3.604 5.44-6.408 8.504-7.05l.174-.036.023-.079.015-.068.024-.13.018-.145-.02-.02a8.215 8.215 0 0 1-.685-.875l-.12-.18a6.989 6.989 0 0 1-.908-1.976l-.01-.038-.086-.048c-1.004-.615-1.373-2.417-.708-3.515l.087-.132-.021-.2c-.1-.971-.094-1.815.1-2.678C8.445 2.9 10.146 1.5 13.202 1.5z"
      />
    </g>
  </svg>
);
export default roleDefault;
