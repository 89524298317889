import { COLORS } from 'styles';
import { License } from 'types';

import { Text } from '../../_shared';

import '../about.scss';

export const LicenseItem = ({ licenses, name, repository }: License) => {
  return (
    <li className="license-item-container">
      <Text color={COLORS.zulu.$10} size="SM" weight="semiBold">
        {name}
      </Text>
      <Text color={COLORS.zulu.$09} size="SM">
        {licenses}
      </Text>
      <a href={repository} rel="noreferrer" target="_blank">
        <Text color={COLORS.romeo.$10} size="SM" textDecoration="underline">
          {repository}
        </Text>
      </a>
    </li>
  );
};
