import * as React from "react";
const mention: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.005 10.607c.268.338.385.685.352 1.04-.151 1.692-.302 2.775-.453 3.249-.168.507-.436.888-.805 1.142-.37.253-.822.38-1.36.38-.922 0-1.618-.33-2.088-.99-.47-.676-.705-1.522-.705-2.537 0-.796.134-1.456.403-1.98.285-.524.637-.905 1.057-1.142.436-.254.872-.38 1.308-.38.437 0 .864.118 1.284.355.42.22.755.507 1.007.863z"
      fill="#47AACF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.89 24.219c6.257 0 11.329-5.072 11.329-11.328 0-6.257-5.072-11.329-11.328-11.329-6.257 0-11.329 5.072-11.329 11.329 0 6.256 5.072 11.328 11.329 11.328zm4.732-19.247c-1.309-.71-2.777-1.066-4.404-1.066-1.728 0-3.305.398-4.731 1.193a8.919 8.919 0 0 0-3.348 3.274c-.822 1.387-1.233 2.927-1.233 4.62 0 1.674.403 3.188 1.208 4.542.806 1.354 1.921 2.411 3.347 3.172 1.443.779 3.079 1.168 4.908 1.168.654 0 1.308-.05 1.963-.152.67-.102 1.224-.237 1.66-.406.252-.102.429-.203.53-.305a.51.51 0 0 0 .175-.406.725.725 0 0 0-.251-.558.547.547 0 0 0-.529-.127c-.872.22-1.51.364-1.912.431a8.138 8.138 0 0 1-1.385.102c-1.61 0-3.028-.313-4.253-.94-1.208-.625-2.147-1.497-2.818-2.613-.655-1.117-.982-2.403-.982-3.858s.327-2.766.982-3.934a7.16 7.16 0 0 1 2.768-2.766c1.191-.677 2.533-1.015 4.027-1.015 1.375 0 2.583.287 3.624.862a5.766 5.766 0 0 1 2.39 2.36c.57.999.856 2.141.856 3.427 0 .744-.092 1.489-.277 2.233-.184.728-.478 1.337-.88 1.828-.386.473-.856.71-1.41.71-.369 0-.63-.118-.78-.355-.151-.254-.227-.626-.227-1.117 0-.558.084-1.438.252-2.64l.076-.608c.1-.812.26-1.752.478-2.817.05-.254 0-.466-.151-.635-.135-.17-.361-.254-.68-.254-.503 0-.78.212-.83.635l-.076.634a3.569 3.569 0 0 0-1.283-1.116c-.52-.271-1.124-.406-1.812-.406-.856 0-1.61.22-2.265.66a4.242 4.242 0 0 0-1.485 1.725c-.352.728-.528 1.54-.528 2.437 0 .727.117 1.463.352 2.208a4.228 4.228 0 0 0 1.308 1.903c.638.525 1.494.787 2.567.787 1.343 0 2.332-.406 2.97-1.218.117.473.37.863.755 1.167.386.288.872.432 1.46.432.822 0 1.543-.305 2.164-.914.638-.61 1.124-1.396 1.46-2.36a8.975 8.975 0 0 0 .503-2.97c0-1.506-.378-2.868-1.133-4.086-.755-1.218-1.795-2.174-3.12-2.868z"
      fill="#47AACF"
    />
  </svg>
);
export default mention;
