import React, { ReactElement, useEffect, useRef } from 'react';
import { arrow, autoUpdate, FloatingArrow, offset, Placement, ReferenceType, shift, useFloating } from '@floating-ui/react';

import './tooltip.scss';

type Slot = ReactElement<{ name: 'header' | 'content' }>;

type Props = {
  alignment: Placement;
  anchor: ReferenceType;
  children: Slot | Slot[];
};

export const Tooltip = (props: Props) => {
  const { alignment, anchor, children } = props;

  const arrowRef = useRef<SVGSVGElement>(null);
  const { context, floatingStyles, refs } = useFloating({
    middleware: [
      offset({
        crossAxis: 15,
        mainAxis: 15,
      }),
      shift({
        padding: 15,
      }),
      arrow({
        element: arrowRef,
      }),
    ],
    placement: alignment,
    whileElementsMounted: autoUpdate,
  });

  const content: Slot[] = [];
  const header: [Slot?] = [];

  useEffect(() => {
    refs.setReference(anchor);
  }, [anchor, refs]);

  if (!anchor) {
    return null;
  }

  [].concat(children).forEach((child: Slot) => {
    if (!React.isValidElement(child)) {
      return;
    }
    if (child.type !== 'slot') {
      return;
    }

    switch (child.props.name) {
      case 'header':
        header.push(child);
        break;
      case 'content':
        content.push(child);
        break;
      default:
        break;
    }
  });

  return (
    <>
      <div className="tooltip" data-test="tooltip" ref={refs.setFloating} style={floatingStyles}>
        {header.length === 1 ? (
          <p className="tooltip-header" data-test="tooltip-header">
            {header.at(0)}
          </p>
        ) : null}
        {content.map((contentSlot, index) => {
          return (
            <section className="tooltip-content" data-test="tooltip-content" key={index}>
              {contentSlot}
            </section>
          );
        })}
        <FloatingArrow className="tooltip-arrow" context={context} data-test="tooltip-arrow" ref={arrowRef} />
      </div>
    </>
  );
};
