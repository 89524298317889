import * as React from "react";
const calendarOutline: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 2.5c.32 0 .585.241.62.552l.005.073v.625h1.25c.999 0 1.815.78 1.872 1.765l.003.11v10c0 .999-.78 1.815-1.765 1.872l-.11.003H3.125c-.999 0-1.815-.78-1.872-1.765l-.003-.11v-10c0-.999.78-1.815 1.765-1.872l.11-.003h1.25v-.625a.625.625 0 0 1 1.246-.073l.004.073v.625h8.75v-.625c0-.345.28-.625.625-.625zm2.5 6.25h-15v6.875c0 .32.241.585.552.62l.073.005h13.75c.32 0 .585-.241.62-.552l.005-.073V8.75zM5.312 13.125a.937.937 0 1 1 0 1.875.937.937 0 0 1 0-1.875zm3.125 0a.937.937 0 1 1 0 1.875.937.937 0 0 1 0-1.875zm3.126 0a.937.937 0 1 1 0 1.875.937.937 0 0 1 0-1.875zM5.313 10a.937.937 0 1 1 0 1.875.937.937 0 0 1 0-1.875zm3.125 0a.937.937 0 1 1 0 1.875.937.937 0 0 1 0-1.875zm3.124 0a.937.937 0 1 1 0 1.875.937.937 0 0 1 0-1.875zm3.126 0a.937.937 0 1 1 0 1.875.937.937 0 0 1 0-1.875zM4.374 5h-1.25a.625.625 0 0 0-.62.552l-.005.073V7.5h15V5.625a.625.625 0 0 0-.552-.62L16.875 5h-1.25v.625a.625.625 0 0 1-1.246.073l-.004-.073V5h-8.75v.625a.625.625 0 0 1-1.246.073l-.004-.073V5z"
      fillRule="evenodd"
    />
  </svg>
);
export default calendarOutline;
