import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { Spinner } from '@aviobook/_shared';
import { AddButton, EmptyState, TableLayout } from '@aviobook/cms/_shared';
import { ConfirmationModal } from '@aviobook/cms/_shared/confirmationModal/ConfirmationModal';
import { customModalStyles } from '@aviobook/cms/_shared/modal/ModalUtils';
import { PermissionsUtil, useAuthenticationContext } from 'shared';
import { QuickRepliesPermissions } from 'types';

import { AddRoleQuickRepliesModal } from './_components';
import { useRoleQuickReplyContentTable } from './_hooks/useRoleQuickReplyContentTable';

import './roleQuickReplies.scss';

export const RoleQuickRepliesComponent: FC = () => {
  const { t } = useTranslation();

  const [isOpenAddModal, setOpenAddModal] = useState(false);

  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);

  const { user } = useAuthenticationContext();

  const permissions = new PermissionsUtil(user);

  const { create, isLoading, remove, selectedItems, table } = useRoleQuickReplyContentTable({
    renderCheckbox: permissions.hasPermission(QuickRepliesPermissions.DELETE),
  });

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <Modal
        isOpen={isOpenAddModal || isOpenDeleteModal}
        onRequestClose={() => (isOpenAddModal ? setOpenAddModal(false) : setOpenDeleteModal(false))}
        style={customModalStyles}
      >
        {isOpenAddModal ? (
          <AddRoleQuickRepliesModal
            closeModal={() => setOpenAddModal(false)}
            isLoading={isLoading}
            onAdd={async values => {
              await create(values);
              setOpenAddModal(false);
            }}
          />
        ) : null}
        {isOpenDeleteModal ? (
          <ConfirmationModal
            cancelButtonAction={() => setOpenDeleteModal(false)}
            cancelButtonLabel={'CANCEL'}
            confirmButtonAction={async () => {
              await remove();
              setOpenDeleteModal(false);
            }}
            confirmButtonLabel={'Delete'}
            loading={isLoading}
            title={`Delete ${selectedItems.length > 1 ? t('QUICK_REPLIES.MULTI') : t('QUICK_REPLIES.SINGLE')}?`}
          />
        ) : null}
      </Modal>
      <TableLayout
        addButtonAction={() => setOpenAddModal(true)}
        addButtonLabel={t('QUICK_REPLIES.ACTIONS.ADD')}
        deleteButtonAction={() => setOpenDeleteModal(true)}
        deleteButtonDisabled={selectedItems.length <= 0}
        emptyState={() => (
          <EmptyState
            button={() =>
              permissions.hasPermission(QuickRepliesPermissions.ADD) ? (
                <AddButton label={t('QUICK_REPLIES.ACTIONS.ADD')} onClick={() => setOpenAddModal(true)} />
              ) : null
            }
            description={t('QUICK_REPLIES.EMPTYSTATE.DESCRIPTION')}
            title={t('QUICK_REPLIES.EMPTYSTATE.TITLE')}
          />
        )}
        isAddButtonPresent={permissions.hasPermission(QuickRepliesPermissions.ADD)}
        isDeleteButtonPresent={permissions.hasPermission(QuickRepliesPermissions.DELETE)}
        table={table}
      />
    </>
  );
};
