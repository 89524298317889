import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { MessagePayload } from 'types';

type StoredMessage = MessagePayload & { isPending?: boolean };

type OfflineMessagesContext = {
  deleteStoredMessage: (messageId: string) => void;
  getStoredMessagesByRoomId: (roomId: string) => StoredMessage[];
  storeMessage: (message: StoredMessage) => void;
};

const OfflineMessagesContext = createContext<OfflineMessagesContext>({
  deleteStoredMessage: () => {},
  getStoredMessagesByRoomId: () => [],
  storeMessage: () => {},
});

export const useOfflineMessagesContext = () => useContext(OfflineMessagesContext);

type Props = {
  children: ReactNode;
};

const getStoredMessages = (): StoredMessage[] => (JSON.parse(localStorage.getItem('storedMessages')) as StoredMessage[]) || [];

export const OfflineMessagesContextProvider: FC<Props> = ({ children }) => {
  const [storedMessages, setStoredMessages] = useState<StoredMessage[]>(getStoredMessages);

  useEffect(() => {
    localStorage.setItem('storedMessages', JSON.stringify(storedMessages));
  }, [storedMessages]);

  const getStoredMessagesByRoomId = useCallback(
    (roomId: string) => storedMessages.filter(message => message.roomId === roomId),
    [storedMessages],
  );

  const storeMessage = useCallback(
    (message: StoredMessage) => setStoredMessages(prevState => [...prevState, { ...message, isPending: true }]),
    [setStoredMessages],
  );

  const deleteStoredMessage = useCallback(
    (messageId: string) => setStoredMessages(prevState => prevState.filter(message => message.messageId !== messageId)),
    [setStoredMessages],
  );

  return (
    <OfflineMessagesContext.Provider
      value={{
        deleteStoredMessage,
        getStoredMessagesByRoomId,
        storeMessage,
      }}
    >
      {children}
    </OfflineMessagesContext.Provider>
  );
};
