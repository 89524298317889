import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@aviobook/_shared';

import { Button } from '../index';

type Props = {
  deleteButtonDisabled?: boolean;
  onClick: () => void;
  testId?: string;
};

export const DeleteButton: FC<Props> = ({ deleteButtonDisabled = false, onClick, testId = 'delete-button' }) => {
  const { t } = useTranslation();

  return (
    <Button
      {...(testId && { testId })}
      disabled={deleteButtonDisabled}
      iconName="trash"
      isOutline
      onClick={onClick}
      theme="transparent"
    >
      <Text as="span" weight="bold">
        {t('SHARED.BUTTONS.DELETE')}
      </Text>
    </Button>
  );
};
