import { FC } from 'react';
import classnames from 'classnames';

import { useInputError } from '../../../_hooks';
import { Icon } from '../../icon/Icon';
import InputWrapper, { InputWrapperProps } from '../InputWrapper';

import './checkbox.scss';

export type InputCheckboxProps = InputWrapperProps & {
  checked?: boolean;
  className?: string;
  label?: string;
  labelClassName?: string;
  onChange: (checked: boolean, name: string) => void;
  testId?: string;
  type?: 'checkbox' | 'radio' | 'toggle';
};

export const Checkbox: FC<InputCheckboxProps> = ({
  checked,
  className,
  label,
  labelClassName,
  onChange,
  testId,
  type = 'checkbox',
  ...wrapperProps
}) => {
  const { disabled, name, validation } = wrapperProps;
  const { setDirty, showError } = useInputError(validation);

  const renderCheckbox = () => <Icon label={name} name={checked ? 'checkboxChecked' : 'checkbox'} size={2.4} testId={testId} />;

  const renderRadioButton = () => <Icon label={name} name={checked ? 'activeRadio' : 'inactiveRadio'} testId={testId} />;

  const renderToggle = () => (
    <div className="switch">
      <input checked={checked} className="toggle" data-test={testId} type="checkbox" />
      <span className="slider" />
    </div>
  );

  const renderType = () => {
    switch (type) {
      case 'radio':
        return renderRadioButton();
      case 'toggle':
        return renderToggle();
      default:
        return renderCheckbox();
    }
  };

  return (
    <InputWrapper
      className={classnames('checkbox-wrapper', className, { 'is-disabled': disabled })}
      {...wrapperProps}
      showError={showError}
    >
      <div
        className={classnames({ 'is-checked': checked })}
        onClick={() => {
          onChange(!checked, name);
          setDirty();
        }}
      >
        {renderType()}
        <label className={classnames(labelClassName, { 'is-error': showError })}>{label}</label>
      </div>
    </InputWrapper>
  );
};
