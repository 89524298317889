import * as React from "react";
const triangleCoreAttention: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path
        d="M10.986 1.369a1.138 1.138 0 0 0-1.971 0L.155 16.715c-.439.76.109 1.707.985 1.707h17.72c.877 0 1.424-.948.986-1.707L10.986 1.37z"
        fill="#FFF"
      />
      <path
        d="M9.36 7.356c0-.077.039-.156.156-.156h.899c.078 0 .156.039.156.156v5.274c0 .077-.04.155-.156.155h-.899c-.078 0-.156-.039-.156-.155V7.356zm1.25 8.047c0 .078-.039.156-.156.156h-.938c-.077 0-.156-.04-.156-.156v-1.015c0-.079.04-.156.156-.156h.938c.079 0 .156.039.156.156v1.015z"
        fill="#62591C"
        fillRule="nonzero"
      />
    </g>
  </svg>
);
export default triangleCoreAttention;
