import { NotificationV1 } from 'types';

import { sortNotificationsByCreatedAt } from '../../../utils';
import { NotificationAction, NotificationsActionType } from './notifications.actions';

type State = {
  notifications: Map<NotificationV1['id'], NotificationV1>;
  unreadNotificationsCount: number;
};

export const notificationReducer = (state: State, { payload, type }: NotificationAction): State => {
  const addedNotificationState = (notification: NotificationV1) => {
    let unreadNotificationsCount = state.unreadNotificationsCount;

    if (!notification.isVisible) {
      return state;
    }

    if (!notification.isRead) {
      unreadNotificationsCount++;
    }

    // add new notification as first element in the map
    const notifications = new Map([[notification.id, notification], ...state.notifications]);

    return { ...state, notifications, unreadNotificationsCount };
  };

  const updatedNotificationState = (notification: NotificationV1): State => {
    const notifications = new Map(state.notifications);
    let unreadNotificationsCount = state.unreadNotificationsCount;

    if (!notification.isVisible) {
      if (!notification.isRead) {
        unreadNotificationsCount--;
      }

      notifications.delete(notification.id);
    } else {
      const existingNotification = state.notifications.get(notification.id);

      const isUnreadExistingMessageRead = !existingNotification?.isRead && notification.isRead;
      if (isUnreadExistingMessageRead) {
        unreadNotificationsCount--;
      }

      notifications.set(notification.id, notification);
    }

    return { ...state, notifications, unreadNotificationsCount };
  };

  const addedNotificationsState = (newNotifications: NotificationV1[], unreadCount: number): State => {
    const notifications = new Map(state.notifications);
    newNotifications.forEach(room => notifications.set(room.id, room));
    const sortedNotifications = new Map(Array.from(notifications).sort((a, b) => sortNotificationsByCreatedAt(a[1], b[1])));

    return { ...state, notifications: sortedNotifications, unreadNotificationsCount: unreadCount };
  };

  switch (type) {
    case NotificationsActionType.ADD_NOTIFICATION:
      return addedNotificationState(payload);
    case NotificationsActionType.UPDATE_NOTIFICATION:
      return updatedNotificationState(payload);
    case NotificationsActionType.ADD_NOTIFICATIONS:
      return addedNotificationsState(payload.notifications, payload.unreadCount);
    default:
      return state;
  }
};
