import { io, Socket } from 'socket.io-client';
import { OutgoingSocketEvents, RoomOpenV1 } from 'types';

let socket: Socket | null = null;
let namespace: string | null = null;
let socketHostUrl: string = '';

export const getSocket = (hostUrl: string, account: string) => {
  if (!socket) {
    namespace = formatAccountToSocketNamespace(account);
    socketHostUrl = hostUrl;
    socket = io(socketHostUrl + namespace, {
      autoConnect: false,
      path: '/api/v2/connect',
      transports: ['websocket'],
      withCredentials: true,
    });
  }
  return socket;
};

export const sendOverSocket = async <T>(event: keyof OutgoingSocketEvents, data?: T) => {
  if (!namespace || !socketHostUrl) {
    return;
  }

  return getSocket(socketHostUrl, namespace)
    .emitWithAck(event, data)
    .then(() => data);
};

export const sendRoomCloseEvent = () => {
  void sendOverSocket('room.close');
};
export const sendRoomOpenEvent = (flightroomId: string) => {
  const interaction: RoomOpenV1 = {
    room: flightroomId,
  };
  void sendOverSocket('room.open', interaction);
};

const formatAccountToSocketNamespace = (account: string): string => {
  return account.startsWith('/') ? account : `/${account}`;
};
