import { createContext, PropsWithChildren, useCallback, useContext, useMemo, useReducer } from 'react';
import { UserV1 } from 'types';

import { useSocketEvent } from '../../hooks';
import { useSocket } from '../SocketContext';
import { UsersContextType } from './userContext.type';
import { UserActionType } from './Users.actions';
import { userReducer } from './Users.reducer';

const UsersContext = createContext<UsersContextType>({
  addUser: () => {},
  existingUser: () => false,
  getUserById: () => undefined,
  updateUser: () => {},
  updateUsers: () => {},
  users: [],
});

export const useUsersContext = () => useContext(UsersContext);

export const UsersContextProvider = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = useReducer(userReducer, {
    users: new Map(),
  });
  const { socket } = useSocket();

  // Overwrite the user state with a new user state
  const updateUsers = useCallback((users: UserV1[]) => {
    dispatch({ payload: users, type: UserActionType.UPDATE_USERS });
  }, []);

  // add a user to the user state
  const addUser = useCallback((user: UserV1) => {
    dispatch({ payload: user, type: UserActionType.ADD_USER });
  }, []);

  // Update a user that is already in the user state
  const updateUser = useCallback((user: UserV1) => {
    dispatch({ payload: user, type: UserActionType.UPDATE_USER });
  }, []);

  // get a specific user by id
  const getUserById = useCallback((id: string) => state.users.get(id), [state.users]);

  // get a specific user by id
  const getExists = useCallback((user: UserV1) => !!getUserById(user.id), [getUserById]);

  const users = useMemo(() => Array.from(state.users, ([, value]) => ({ ...value })), [state.users]);

  const processUserUpdate = useCallback(
    (user: UserV1) => {
      if (getExists(user)) {
        updateUser(user);
      } else {
        addUser(user);
      }
    },
    [addUser, getExists, updateUser],
  );

  useSocketEvent(socket, 'user.update', processUserUpdate);

  return (
    <UsersContext.Provider
      value={{
        addUser,
        existingUser: getExists,
        getUserById,
        updateUser,
        updateUsers,
        users,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};
