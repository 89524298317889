export const montblanc = {
  $01: '#cefdff',
  $02: '#c0fcff',
  $03: '#abfbff',
  $04: '#9dfbff',
  $05: '#88faff',
  $06: '#73f9ff',
  $07: '#67e0e5',
  $08: '#62d4d9',
  $09: '#56bbbf',
  $10: '#459599',
  $11: '#2e6466',
} as const;
