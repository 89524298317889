import React, { ChangeEvent, forwardRef, ReactElement, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Mention, MentionsInput, SuggestionDataItem } from 'react-mentions';
import { InputToolbarProps } from 'react-native-gifted-chat';
import { useWindowDimensions } from '@aviobook/_hooks';
import { useMentions } from 'shared';
import { COLORS } from 'styles';
import { FlightRoom, GiftedMessage } from 'types';

import './mentionInput.scss';

type Props = InputToolbarProps<GiftedMessage> & {
  flightroom: FlightRoom;
  icon: ReactElement;
  message: string;
  sendDisabled: boolean;
  sendMessage: () => void;
  setMessage: (message: string) => void;
};

type EventMentionType = React.KeyboardEvent<HTMLTextAreaElement> | React.KeyboardEvent<HTMLInputElement>;
export interface MentionInputHandle {
  focus: () => void;
}

export const MentionInput = forwardRef<MentionInputHandle, Props>(
  ({ icon, message, sendDisabled, sendMessage, setMessage }, ref) => {
    const { t } = useTranslation();

    const { windowHeight } = useWindowDimensions();

    const { mentionOptions, mentionsRegex } = useMentions();

    const inputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => ({
      focus: () => {
        // Create a new 'focus' event
        inputRef.current?.focus();
      },
    }));

    return (
      <div className="input-field">
        {icon}
        <MentionsInput
          className="mentions"
          data-test="mention-input"
          forceSuggestionsAboveCursor
          inputRef={inputRef}
          onChange={(event: ChangeEvent<HTMLInputElement>) => setMessage(event.target.value)}
          onKeyDown={(event: EventMentionType) => {
            const canSendMessage = !sendDisabled && (event.code === 'Enter' || event.code === 'NumpadEnter');
            if (canSendMessage) {
              sendMessage();
            }
          }}
          onPaste={(event: React.ClipboardEvent<HTMLTextAreaElement>) => {
            // Wait for the next event loop for the paste to complete
            setTimeout(() => {
              const input = event.target as HTMLTextAreaElement;
              input.setSelectionRange(input.value.length, input.value.length);
              input.scrollLeft = input.scrollWidth;
            }, 0);
          }}
          placeholder={t('CHAT.INPUT.PLACEHOLDER')}
          singleLine
          style={{ '--max-height': `${windowHeight - 116}px` }}
          value={message}
        >
          <Mention
            appendSpaceOnAdd
            data={[
              ...mentionOptions.map(mention => ({
                display: mention.label,
                id: mention.label,
              })),
            ]}
            displayTransform={(id: string) => '@' + id}
            markup="@__id__"
            // only highlight tags included our tags and keep the suggestions
            regex={mentionsRegex ? new RegExp(mentionsRegex) : null}
            renderSuggestion={(tag: SuggestionDataItem) => `@${tag.display}`}
            style={{
              backgroundColor: COLORS.victor.$03,
              color: COLORS.bogota.$12,
              fontWeight: 'bold',
              // We need to offset the spacing added to tags by boldness
              letterSpacing: '-0.04rem',
            }}
            trigger="@"
          />
        </MentionsInput>
      </div>
    );
  },
);
