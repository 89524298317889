import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@aviobook/_shared';
import { COLORS } from 'styles';

import './unreadIndicator.scss';

type Props = Record<string, never>;

export const UnreadIndicator: FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <div className="unread-indicator">
      <Text color={COLORS.zulu.$08} size="SM">
        {t('CHAT.UNREAD.MESSAGES')}
      </Text>
    </div>
  );
};
