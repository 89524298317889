import { FC, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PermissionsUtil, useAuthenticationContext } from 'shared';
import { FontSize } from 'styles';
import { CombiningTranslationDict, ConnectPermission } from 'types';

import { TabBar } from '../../_routing/tabbar/TabBar';
import { TabContent } from './TabContent';

export type Tab = {
  label: string;
  name: string;
  order: number;
  permissions: ConnectPermission[];
  screen: () => JSX.Element;
};

type Props = {
  navBarClassName?: 'top-nav-bar' | 'nav-bar';
  tabs: Tab[];
  textSize?: FontSize;
};

export const PageContent: FC<Props> = ({ navBarClassName = 'nav-bar', tabs, textSize = 'MD' }) => {
  const [searchParams] = useSearchParams();

  const tab = searchParams.get('tab');

  const { user } = useAuthenticationContext();

  const permissions = useMemo(() => {
    return new PermissionsUtil(user);
  }, [user]);

  const routes = useMemo(() => {
    return tabs
      .filter(tab => permissions.hasPermissions(tab.permissions, CombiningTranslationDict.OR))
      .sort((a, b) => a.order - b.order);
  }, [permissions, tabs]);

  const [selectedTab, setSelectedTab] = useState(
    routes?.findIndex(({ name }) => name === tab) !== -1 ? routes?.findIndex(({ name }) => name === tab) : 0,
  );

  return (
    <>
      <TabBar
        currentPage={selectedTab}
        navBarClassName={navBarClassName}
        setTab={setSelectedTab}
        tabRoutes={routes}
        textSize={textSize}
      />
      <TabContent selectedTab={selectedTab} tabs={routes} />
    </>
  );
};
