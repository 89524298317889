import { Children, FC, ReactNode } from 'react';
import classnames from 'classnames';

import InputWrapper, { InputWrapperProps } from '../InputWrapper';

import './checkboxGroup.scss';

export type CheckboxGroupProps = InputWrapperProps & {
  children: ReactNode;
  horizontal?: boolean;
};

const CheckboxGroup: FC<CheckboxGroupProps> = ({ children, horizontal = false, ...wrapperProps }) => {
  const { validation } = wrapperProps;

  return (
    <InputWrapper {...wrapperProps} showError={!!validation?.message}>
      <div className={classnames('checkbox-group', { horizontal })}>
        {Children.map(children, child => (
          <div className="checkbox-item">{child}</div>
        ))}
      </div>
    </InputWrapper>
  );
};

export default CheckboxGroup;
