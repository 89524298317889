import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceType, OsType } from '@aviobook/_types/DeviceType';
import { detectDevice } from '@aviobook/_utils/detect-device';
import { COLORS } from 'styles';

import { Icon, Text } from '../index';

import './smartbanner.scss';

export const SmartBanner: FC = () => {
  const { t } = useTranslation();
  const { deviceOS, deviceType } = detectDevice();
  const [hide, setHide] = useState(deviceType == DeviceType.DESKTOP);

  const downloadApp = () => {
    let downloadUrl = 'https://apps.apple.com/us/app/aviobook-connect/id1198631993';

    if (deviceOS == OsType.ANDROID) {
      downloadUrl = 'https://play.google.com/store/apps/details?id=aero.aviobook.connect.live';
    }

    window.location.href = downloadUrl;
  };

  if (hide) {
    return null;
  }

  return (
    <div className="banner-container">
      <div className="banner-content">
        <Icon name="closeCross" onClick={() => setHide(true)} />
        <div className="banner-content right" onClick={downloadApp}>
          <Text as="p" className="banner-component" color={COLORS.zulu.$08} size="SM" weight="bold">
            {t('SMARTBANNER_TITLE')}
          </Text>
          <Icon name="download" />
        </div>
      </div>
    </div>
  );
};
