import * as React from "react";
const download: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M21.094 14.844c.4 0 .73.301.776.69l.005.091v3.906a2.344 2.344 0 0 1-2.206 2.34l-.138.004H5.47a2.344 2.344 0 0 1-2.34-2.206l-.004-.138v-3.906a.781.781 0 0 1 1.557-.091l.005.091v3.906c0 .4.302.731.69.776l.092.006H19.53c.4 0 .731-.302.776-.69l.006-.092v-3.906c0-.431.35-.781.78-.781zM12.5 3.125c.4 0 .73.302.776.69l.005.091V14.52l2.182-2.182a.781.781 0 0 1 1.031-.065l.074.065a.781.781 0 0 1 .065 1.031l-.065.074-3.516 3.516a.781.781 0 0 1-1.03.065l-.074-.065-3.516-3.516a.781.781 0 0 1 1.031-1.17l.074.065 2.182 2.182V3.906c0-.431.35-.781.781-.781z"
      fill="#0F384D"
      fillRule="nonzero"
    />
  </svg>
);
export default download;
