import * as React from "react";
const circularCrossFullOutline: React.FC<
  React.SVGProps<SVGSVGElement>
> = () => (
  <svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5 0C19.404 0 25 5.596 25 12.5S19.404 25 12.5 25 0 19.404 0 12.5 5.596 0 12.5 0zm0 1.563C6.46 1.563 1.562 6.458 1.562 12.5c0 6.04 4.897 10.938 10.938 10.938 6.04 0 10.938-4.897 10.938-10.938 0-6.04-4.897-10.938-10.938-10.938zM8.365 7.26l4.135 4.135 4.135-4.135a.781.781 0 0 1 1.105 1.105L13.605 12.5l4.135 4.135a.781.781 0 0 1-1.105 1.105L12.5 13.605 8.365 17.74a.781.781 0 1 1-1.105-1.105l4.135-4.135L7.26 8.365A.781.781 0 1 1 8.365 7.26z"
      fill="#144761"
      fillRule="evenodd"
    />
  </svg>
);
export default circularCrossFullOutline;
