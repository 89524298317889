import { useTranslation } from 'react-i18next';
import { PageContent, Tab } from '@aviobook/cms/_shared/pageContent/PageContent';
import { QuickRepliesPermissions, RolesPermissions, SmartActionPermissions } from 'types';

import { General, Roles } from './_screens';

export const Connect = () => {
  const { t } = useTranslation();

  const tabs: Tab[] = [
    {
      label: t('TAB.GENERAL.TITLE'),
      name: 'General',
      order: 0,
      permissions: [
        SmartActionPermissions.VIEW,
        SmartActionPermissions.ADD,
        SmartActionPermissions.DELETE,
        SmartActionPermissions.UPDATE,
        // GeneralPermissions.UPDATE_ACCOUNT_SETTINGS,
        QuickRepliesPermissions.ADD,
        QuickRepliesPermissions.VIEW,
        // QuickRepliesPermissions.UPDATE,
      ],
      screen: () => <General />,
    },
    {
      label: t('TAB.ROLES.TITLE'),
      name: 'Roles',
      order: 1,
      permissions: [RolesPermissions.VIEW],
      screen: () => <Roles />,
    },
  ];

  return <PageContent navBarClassName="top-nav-bar" tabs={tabs} textSize="XL" />;
};
