import { jwtDecode } from 'jwt-decode';

export const calculateTimeUntilRefresh = (accessToken: string): number | undefined => {
  const expiresIn = jwtDecode(accessToken).exp; // expiry date in seconds

  if (!expiresIn) {
    return undefined;
  }

  const currentTime = Math.floor(Date.now() / 1000); // current time in seconds
  return expiresIn - 30 - currentTime; // refresh 30 seconds before expiration
};

export const hasExpired = (accessToken: string): boolean => {
  const expiresIn = jwtDecode(accessToken).exp; // expiry date in seconds

  if (!expiresIn) {
    return true;
  }

  const currentTime = Math.floor(Date.now() / 1000); // current time in seconds
  return expiresIn < currentTime;
};
