import * as React from "react";
const circularPlusFilledRegular: React.FC<
  React.SVGProps<SVGSVGElement>
> = () => (
  <svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5 0C19.404 0 25 5.596 25 12.5S19.404 25 12.5 25 0 19.404 0 12.5 5.596 0 12.5 0zm0 5.469a.781.781 0 0 0-.781.781v5.469H6.25a.781.781 0 1 0 0 1.562h5.469v5.469a.781.781 0 1 0 1.562 0v-5.469h5.469a.781.781 0 1 0 0-1.562h-5.469V6.25a.781.781 0 0 0-.781-.781z"
      fill="#47AACF"
      fillRule="evenodd"
    />
  </svg>
);
export default circularPlusFilledRegular;
