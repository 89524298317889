import { uuidRegex } from '../../../regexes';

export const stringified = (input: unknown): string => {
  const stringified = String(input);

  if (
    // number
    /^\d+$/.test(stringified) ||
    // uuid
    !uuidRegex.test(stringified)
  ) {
    return `"${stringified}"`;
  }

  return stringified;
};
