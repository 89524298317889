import { Action } from './action.type';
import { ActionTypes } from './actionTypes.type';
import { State } from './state.type';

export const reducer = (state: State, { payload, type }: Action) => {
  const newState = new Set(state);

  switch (type) {
    case ActionTypes.ADD:
      payload.forEach(permission => newState.add(permission));
      break;
    case ActionTypes.REMOVE:
      payload.forEach(permission => newState.delete(permission));
      break;
    default:
      return state;
  }

  return newState;
};
