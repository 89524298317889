export const etna = {
  $01: '#fbe9eb',
  $02: '#f6d3d7',
  $03: '#f2bdc3',
  $04: '#e9919b',
  $05: '#e06572',
  $06: '#d32336',
  $07: '#bd1f30',
  $08: '#b41e2e',
  $09: '#9e1a28',
  $10: '#7f1520',
  $11: '#540e16',
} as const;
