import * as React from "react";
const uiCheckmark: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.31 5.873a.8.8 0 0 1 1.203 1.054l-6.3 7.2a.8.8 0 0 1-1.167.039l-3.812-3.812a.8.8 0 0 1 1.132-1.131l3.206 3.206 5.737-6.556z"
      fill="#FFF"
      fillRule="evenodd"
    />
  </svg>
);
export default uiCheckmark;
