import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '@aviobook/_shared';
import { AddButton, TableLayout } from '@aviobook/cms/_shared';
import { ConfirmationModal } from '@aviobook/cms/_shared/confirmationModal/ConfirmationModal';
import { EmptyState } from '@aviobook/cms/_shared/emptyState/EmptyState';
import { customModalStyles } from '@aviobook/cms/_shared/modal/ModalUtils';
import { PermissionsUtil, useAuthenticationContext } from 'shared';
import { SmartActionPermissions } from 'types';

import { useSmartActionContentTable } from './_hooks/useSmartActionContentTable';

import './smartActions.scss';

export const SmartActions: FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [isModalOpen, setModalOpen] = useState(false);

  const { user } = useAuthenticationContext();

  const permissions = new PermissionsUtil(user);

  const { deleteButtonDisabled, deleteSmartActions, isDeleteSmartActionLoading, isLoading, table } = useSmartActionContentTable({
    renderCheckbox: permissions.hasPermission(SmartActionPermissions.DELETE),
  });

  if (isLoading) {
    return <Spinner>{t('SHARED.LOADING_APPLICATION')}</Spinner>;
  }

  return (
    <div className="smart-actions-container" data-test="smart-actions-screen">
      <Modal isOpen={isModalOpen} onRequestClose={() => setModalOpen(false)} style={customModalStyles}>
        <ConfirmationModal
          cancelButtonAction={() => {
            setModalOpen(false);
          }}
          cancelButtonLabel={t('SMART_ACTIONS_CONFIRMATION_MODAL_CANCEL_BUTTON_LABEL')}
          confirmButtonAction={async () => {
            await deleteSmartActions();
            setModalOpen(false);
          }}
          confirmButtonLabel={t('SMART_ACTIONS_CONFIRMATION_MODAL_CONFIRM_BUTTON_LABEL')}
          loading={isDeleteSmartActionLoading}
          title={t('SMART_ACTIONS_CONFIRMATION_MODAL_TITLE')}
        />
      </Modal>

      <TableLayout
        addButtonAction={() => navigate('/admin/connect/general/smart-actions')}
        addButtonLabel={t('SMART_ACTIONS_ADD_BUTTON')}
        deleteButtonAction={() => setModalOpen(true)}
        deleteButtonDisabled={deleteButtonDisabled}
        emptyState={() => (
          <EmptyState
            button={() =>
              permissions.hasPermission(SmartActionPermissions.ADD) ? (
                <AddButton
                  label={t('SMART_ACTIONS_ADD_BUTTON')}
                  onClick={() => navigate('/admin/connect/general/smart-actions')}
                />
              ) : null
            }
            description={t('SMART_ACTIONS_EMPTY_STATE_DESCRIPTION')}
            title={t('SMART_ACTIONS_EMPTY_STATE_TITLE')}
          />
        )}
        isAddButtonPresent={permissions.hasPermission(SmartActionPermissions.ADD)}
        isDeleteButtonPresent={permissions.hasPermission(SmartActionPermissions.DELETE)}
        table={table}
      />
    </div>
  );
};
