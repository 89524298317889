import { FC, Key, useMemo } from 'react';
import Linkify from 'react-linkify';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { useMentions } from 'shared';
import { COLORS } from 'styles';

import { Text } from '../../../../_shared/text/Text';
import { matchDecorator } from '../../../_utils';
import { getArrayOfTextSplitByMention } from './_utils/MentionUtils';

import './messageBubbleText.scss';

type Props = {
  isMe: boolean;
  setActiveMention: (mention: string) => void;
  text: string;
  withStatus: boolean;
};

export const MessageBubbleText: FC<Props> = ({ isMe, setActiveMention, text, withStatus }) => {
  const textColor = isMe ? COLORS.zulu.$08 : COLORS.zulu.$10;
  const { getUsedMentions } = useMentions();

  const linkifyComponentDecorator = (decoratedHref: string, decoratedText: string, key: Key) => {
    return (
      <Link className="message-bubble-hyperlink-text" key={key} rel="noopener noreferrer" target="_blank" to={decoratedHref}>
        {decoratedText}
      </Link>
    );
  };

  const mentions = getUsedMentions(text);
  const mentionLabels = useMemo(() => mentions?.map(mentions => mentions.label), [mentions]);

  return (
    <Linkify componentDecorator={linkifyComponentDecorator} matchDecorator={matchDecorator}>
      <Text
        className={classnames({
          'slide-left': withStatus,
        })}
        color={textColor}
        size="SM"
      >
        {getArrayOfTextSplitByMention(text, mentionLabels)?.map((part, index) => {
          const isMentioned = mentions.find(mention => `@${mention.label}` === part);

          if (isMentioned) {
            return (
              <span key={index} onClick={() => setActiveMention(part)}>
                <Text as="span" className="message-bubble-mention-role" weight="bold">
                  {`${part} `}
                </Text>
              </span>
            );
          } else {
            return <span key={index}>{part}</span>;
          }
        })}
      </Text>
    </Linkify>
  );
};
