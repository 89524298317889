import React, { FC, useCallback, useMemo } from 'react';
import { Empty } from '@aviobook/_routing/empty/Empty';
import { Spinner } from '@aviobook/_shared';
import { Chat } from '@aviobook/chat/Chat';
import { ChatMessagesContextProvider, useFlightroomsContext } from 'shared';

type Props = {
  isLoading: boolean;
};

export const ChatWrapper: FC<Props> = ({ isLoading }) => {
  const { selectedFlightroom } = useFlightroomsContext();

  const createGetter = useCallback(
    async (key: string) =>
      await new Promise<string>(resolve => {
        const item = localStorage.getItem(key);
        resolve(item ? item : '');
      }),
    [],
  );

  const storageSolution = useMemo(
    () => ({
      clear: () => localStorage.clear(),
      get: createGetter,
      has: (key: string) => !!localStorage.getItem(key),
      set: (key: string, value: string) => localStorage.setItem(key, value),
    }),
    [createGetter],
  );

  if (isLoading) {
    return <Spinner size="large" />;
  }

  if (!selectedFlightroom) {
    return <Empty />;
  }

  return (
    <ChatMessagesContextProvider
      flightroomId={selectedFlightroom?.externalId}
      key={selectedFlightroom?.externalId}
      storageSolution={storageSolution}
    >
      <Chat />
    </ChatMessagesContextProvider>
  );
};
