export const rocky = {
  $01: '#efe0fd',
  $02: '#ebd7fc',
  $03: '#e4cafb',
  $04: '#e0c1fa',
  $05: '#d9b3f9',
  $06: '#d2a6f8',
  $07: '#bd95df',
  $08: '#b38dd3',
  $09: '#9d7cba',
  $10: '#7e6495',
  $11: '#544263',
} as const;
