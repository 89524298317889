import * as React from "react";
const add: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.5 1.313a9.188 9.188 0 1 1 0 18.375 9.188 9.188 0 0 1 0-18.375zm0 1.312a7.875 7.875 0 1 0 0 15.75 7.875 7.875 0 0 0 0-15.75zm0 2.625c.337 0 .614.253.652.58l.004.076v3.938h3.938a.656.656 0 0 1 .076 1.308l-.076.004h-3.938v3.938a.656.656 0 0 1-1.308.076l-.004-.076v-3.938H5.906a.656.656 0 0 1-.076-1.308l.076-.004h3.938V5.906c0-.362.294-.656.656-.656z"
      fill="#0F384D"
      fillRule="evenodd"
    />
  </svg>
);
export default add;
