import * as React from "react";
const upload: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg viewBox="0 0 56 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.0125 0C36.5562 0 43.85 5.36875 46.7125 12.9188C52.0562 14.3313 56 19.2063 56 25C56 31.9063 50.4062 37.5 43.5063 37.5H41C40.725 37.5 40.5 37.275 40.5 37V33.25C40.5 32.975 40.725 32.75 41 32.75H43.5063C44.5235 32.7527 45.5312 32.5535 46.471 32.1641C47.4108 31.7747 48.264 31.2027 48.9812 30.4813C50.4438 29.0188 51.25 27.0688 51.25 25C51.25 21.4875 48.8813 18.4063 45.4937 17.4938L43.1313 16.8688L42.2688 14.5875C41.7312 13.1688 40.9812 11.8313 40.0375 10.625C39.1062 9.43126 38.0063 8.38126 36.7625 7.50625C34.1938 5.7 31.1687 4.74375 28.0125 4.74375C24.8562 4.74375 21.8313 5.7 19.2625 7.50625C18.0228 8.37844 16.9192 9.42943 15.9875 10.625C15.0437 11.8313 14.2937 13.1625 13.7562 14.5875L12.8875 16.875L10.5188 17.4938C8.89375 17.925 7.43125 18.8938 6.3875 20.225C5.36875 21.5313 4.80625 23.0813 4.75 24.7313C4.68125 26.8438 5.4625 28.8813 6.93125 30.3938C8.40625 31.9125 10.3875 32.75 12.4938 32.75H15C15.275 32.75 15.5 32.975 15.5 33.25V37C15.5 37.275 15.275 37.5 15 37.5H12.4938C5.59375 37.5 0 31.9063 0 25C0 19.2 3.95625 14.3188 9.3125 12.9125C12.175 5.3625 19.4688 0 28.0125 0ZM28.2189 18.5462C28.2872 18.5794 28.3469 18.6277 28.3937 18.6875L35.3937 27.55C35.6499 27.875 35.4187 28.3563 34.9999 28.3563H30.3687V43.5C30.3687 43.775 30.1437 44 29.8687 44H26.1187C25.8437 44 25.6187 43.775 25.6187 43.5V28.35H20.9999C20.9063 28.3502 20.8146 28.3241 20.7351 28.2746C20.6557 28.2251 20.5918 28.1542 20.5507 28.0701C20.5096 27.986 20.4931 27.8921 20.5029 27.799C20.5127 27.7059 20.5485 27.6175 20.6062 27.5438L27.6062 18.6875C27.653 18.6277 27.7127 18.5794 27.7809 18.5462C27.8492 18.5129 27.9241 18.4957 27.9999 18.4957C28.0758 18.4957 28.1507 18.5129 28.2189 18.5462Z"
      fill="#72206F"
    />
  </svg>
);
export default upload;
