import { InfiniteData, useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { HttpClient } from 'shared';
import { API_FACTORY } from 'shared/src/utils/factories/api.factory';
import { ApiError, FlightRoom, PaginationV1, QueryKeys } from 'types';

const leaveFlightroom = (roomId: string): Promise<boolean> => HttpClient.post(API_FACTORY.v2(`rooms/${roomId}/leave`));

const removeRoomFromPages = (roomId: string, pages: PaginationV1<FlightRoom>[]) => {
  return pages.map(page => ({
    ...page,
    items: page?.items?.filter(room => room.externalId !== roomId),
  }));
};

export const useLeaveFlightroom = (options?: UseMutationOptions<boolean, ApiError, string>) => {
  const queryClient = useQueryClient();

  return useMutation<boolean, ApiError, string>(['leaveFlightroom'], leaveFlightroom, {
    onMutate: roomId => {
      queryClient.setQueriesData<InfiniteData<PaginationV1<FlightRoom>>>(
        [QueryKeys.FLIGHTROOMS],
        (cachedFlightRooms: InfiniteData<PaginationV1<FlightRoom>> | undefined) => {
          if (!cachedFlightRooms) {
            return;
          }

          const filteredPages = removeRoomFromPages(roomId, cachedFlightRooms.pages);
          return {
            ...cachedFlightRooms,
            pages: filteredPages,
          };
        },
      );
    },
    ...options,
  });
};
