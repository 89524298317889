import { AuthenticatedUser } from 'types';

import { AuthenticationActionTypes } from './authenticationAction.enum';
import { AuthenticationActions } from './authenticationAction.type';

type State = {
  accessToken: string | null;
  enableAuthentication: boolean;
  enableAuthenticationCms: boolean;
  isLoading: boolean;
  user: AuthenticatedUser | null;
};

export const authenticationReducer = (state: State, { payload, type }: AuthenticationActions): State => {
  switch (type) {
    case AuthenticationActionTypes.ENABLE_AUTHENTICATION:
      return {
        ...state,
        enableAuthentication: payload,
      };
    case AuthenticationActionTypes.ENABLE_AUTHENTICATION_CMS:
      return {
        ...state,
        enableAuthenticationCms: payload,
      };
    case AuthenticationActionTypes.SET_PROFILE:
      if (!payload) {
        return {
          ...state,
          user: null,
        };
      }
      return {
        ...state,
        user: payload,
      };
    case AuthenticationActionTypes.SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: payload,
      };
    case AuthenticationActionTypes.RESET:
      return {
        accessToken: null,
        enableAuthentication: true,
        enableAuthenticationCms: true,
        isLoading: false,
        user: null,
      };
    case AuthenticationActionTypes.SET_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    default:
      return state;
  }
};
