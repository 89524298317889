import { FlightRoom, FlightRoomUser } from 'types';

import { API_FACTORY, HttpClient, stringifyObjectProperties } from '../utils';

type Payload = {
  externalId: FlightRoomUser['externalId'];
  hasBeenGrantedAccess: boolean;
  roomId: FlightRoom['externalId'];
};

export const grantAccess = ({ roomId, ...rest }: Payload) =>
  HttpClient.post<void>(API_FACTORY.v2(`/rooms/${encodeURIComponent(roomId)}/grant-access`), stringifyObjectProperties(rest));
