import { isEmptyString } from '../strings';

/**
 * Deep copy function for TypeScript.
 * @param T Generic type of target/copied value.
 * @param target Target value to be copied.
 * @see Source project, ts-deeply https://github.com/ykdr2017/ts-deepcopy
 * @see Code pen https://codepen.io/erikvullings/pen/ejyBYg
 */
export const deepCopy = <T>(target: T): T => {
  if (typeof target !== 'object' || target === null) {
    return target;
  }

  if (target instanceof Date) {
    return new Date(target) as T;
  }

  if (target instanceof File) {
    return target as T;
  }

  if (typeof target === 'object') {
    if (Array.isArray(target)) {
      const cp = [] as unknown[];

      if ((target as unknown as unknown[]).length > 0) {
        for (const arrayMember of target as unknown as unknown[]) {
          cp.push(deepCopy(arrayMember));
        }
      }

      return cp as unknown as T;
    } else {
      const targetKeys: (keyof T)[] = Object.keys(target) as (keyof T)[];

      const cp: { [Key in keyof T]?: unknown } = {};

      if (targetKeys.length > 0) {
        for (const key of targetKeys) {
          cp[key] = deepCopy(target[key]);
        }
      }

      return cp as T;
    }
  }

  return target;
};

export const isEmptyObject = (object: unknown): boolean => {
  if (!object) {
    return true;
  }
  if (Array.isArray(object)) {
    return object.every(isEmptyObject);
  }
  if (typeof object === 'string') {
    return isEmptyString(object);
  }
  if (typeof object === 'object') {
    return Object.keys(object).every(key => isEmptyObject(object[key as keyof object]));
  }
  return false;
};

type StringifiedObject<T> = { [Key in keyof T]: T[Key] extends number ? string : T[Key] };

export const stringifyObjectProperties = <T extends object>(input: T, deep = false): StringifiedObject<T> =>
  Object.entries(input)
    .map(
      ([key, value]) =>
        [key, /^\d+$/.test(value as string) ? String(value) : value] as [
          keyof T,
          typeof value extends number ? string : typeof value,
        ],
    )
    .reduce(
      (prev, [key, value]) => {
        if (deep && !Array.isArray(value) && value instanceof Object) {
          value = stringifyObjectProperties(value);
        }

        prev[key] = value;

        return prev;
      },
      <StringifiedObject<T>>{},
    );
