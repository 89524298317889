import React from 'react';
import useCountdown from 'shared/src/hooks/countdown/useCountdown';
import { COLORS, zulu } from 'styles';
import { FlightRoom } from 'types';

import { Icon, Text } from '../../../_shared';

import './CountdownTimer.scss';

type CountdownTimerProps = {
  flightroom: FlightRoom;
};

export const CountdownTimer: React.FC<CountdownTimerProps> = ({ flightroom }) => {
  const { countdownLabel, countdownString, countdownTextColor, isCompleted } = useCountdown({
    ata: flightroom.schedule.ata,
    atd: flightroom.schedule.atd,
    eta: flightroom.schedule.eta,
    etd: flightroom.schedule.etd,
    sta: flightroom.schedule.sta,
    std: flightroom.schedule.std,
  });

  if (isCompleted) {
    return null;
  }

  return (
    <div className="countdown-container" data-test="CountdownTimer.container">
      <Icon colorName={zulu.$08} name="aircraftPlaneTakeOff" size={2} />
      <Text color={COLORS.zulu.$08} size="SM" weight="bold">
        {countdownLabel}
      </Text>
      <Text color={countdownTextColor} data-test="CountdownTimer.value" size="SM" weight="bold">
        {countdownString}
      </Text>
    </div>
  );
};
