import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { useAccessTokenRefresh } from '@aviobook/_hooks/useAccessTokenRefresh';

import './dashboardLayout.scss';

export const DashboardLayout: FC = () => {
  useAccessTokenRefresh();

  return (
    <main className="dashboard-layout">
      <Outlet />
    </main>
  );
};
