import * as React from "react";
const roleDispatcherHeadsetOutline: React.FC<
  React.SVGProps<SVGSVGElement>
> = () => (
  <svg viewBox="0 0 25 25" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Icons/Finals/Aviation/Roles/role-dispatcher-headset-outline</title>
    <g
      id="Icons/Finals/Aviation/Roles/role-dispatcher-headset-outline"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M12.5,2.34375 C15.6657892,2.34375 18.3546944,4.32303981 19.2305791,7.0556712 C21.5934758,7.29604532 23.4375,9.2920502 23.4375,11.71875 C23.4375,14.1459823 21.5926665,16.1423306 19.2286862,16.3821082 C18.7573291,17.8544484 17.7535235,19.132253 16.3834697,19.9901438 C16.398705,20.0947411 16.40625,20.2027395 16.40625,20.3125 C16.40625,21.6069174 15.3569174,22.65625 14.0625,22.65625 L10.9375,22.65625 C9.64308262,22.65625 8.59375,21.6069174 8.59375,20.3125 C8.59375,19.0180826 9.64308262,17.96875 10.9375,17.96875 L14.0625,17.96875 C14.6796597,17.96875 15.2411056,18.2072891 15.6596692,18.5971989 C17.0941514,17.6544434 17.96875,16.1213397 17.96875,14.453125 L17.96875,8.984375 C17.96875,6.19147687 15.5311747,3.90625 12.5,3.90625 C9.46882533,3.90625 7.03125,6.19147687 7.03125,8.984375 L7.03125,15.625 C7.03125,16.025653 6.7296561,16.355865 6.34111025,16.400994 L6.25,16.40625 C3.66116524,16.40625 1.5625,14.3075848 1.5625,11.71875 C1.5625,9.2920502 3.40652419,7.29604532 5.76975807,7.05554988 C6.64530557,4.32303981 9.33421076,2.34375 12.5,2.34375 Z M14.0625,19.53125 L10.9375,19.53125 C10.5060275,19.53125 10.15625,19.8810275 10.15625,20.3125 C10.15625,20.7439725 10.5060275,21.09375 10.9375,21.09375 L14.0625,21.09375 C14.4939725,21.09375 14.84375,20.7439725 14.84375,20.3125 C14.84375,19.8810275 14.4939725,19.53125 14.0625,19.53125 Z M19.5239911,8.6903422 L19.5282421,8.787975 L19.53125,8.984375 L19.53125,14.453125 C19.53125,14.5516044 19.5289637,14.6496998 19.5244301,14.7473583 C20.8755065,14.4027805 21.875,13.1774874 21.875,11.71875 C21.875,10.2600126 20.8755065,9.03471949 19.5239911,8.6903422 Z M5.47548422,8.6907054 L5.3474587,8.72605946 C4.0615944,9.11331996 3.125,10.3066583 3.125,11.71875 C3.125,13.1749431 4.12100994,14.3985055 5.46894021,14.7453478 L5.46875,8.984375 C5.46875,8.88595723 5.47101034,8.78805305 5.47548422,8.6907054 Z"
        id="Shape"
        fill="#0F384D"
      ></path>
    </g>
  </svg>
);
export default roleDispatcherHeadsetOutline;
