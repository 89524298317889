import * as React from "react";
const briefcase: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg viewBox="0 0 25 26" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.844 2.844c1.248 0 2.268.975 2.34 2.206l.003.138v.78h3.907c1.248 0 2.268.976 2.34 2.207l.003.137v11.72a2.344 2.344 0 0 1-2.206 2.339l-.137.004H3.906a2.344 2.344 0 0 1-2.34-2.206l-.004-.138V8.313c0-1.249.976-2.269 2.207-2.34l.137-.004h3.906v-.782c0-1.248.976-2.268 2.207-2.34l.137-.003h4.688zm7.031 9.849-8.34 4.109a2.344 2.344 0 0 1-1.918.069l-.153-.069-8.339-4.109v7.338c0 .4.302.731.69.776l.091.006h17.188c.4 0 .73-.302.776-.69l.005-.092v-7.338zm-.781-5.162H3.906c-.4 0-.73.302-.776.69l-.005.091v2.639l9.03 4.45c.186.091.4.104.595.039l.095-.04 9.03-4.45V8.314c0-.401-.302-.731-.69-.776l-.091-.006zM12.5 12.22a.781.781 0 1 1 0 1.562.781.781 0 0 1 0-1.562zm2.344-7.813h-4.688c-.4 0-.73.302-.776.69l-.005.091v.782h6.25v-.782c0-.4-.302-.73-.69-.775l-.091-.006z"
      fill="#47AACF"
      fillRule="nonzero"
    />
  </svg>
);
export default briefcase;
