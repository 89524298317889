import { Iso8601DateString } from './datetime';
import { HttpMetadataQuery } from './httpMetadata';
import { FlightRoomUser } from './room';

export type FlightRoom = {
  aircraft: string;
  archived?: boolean;
  atcCallSign?: string;
  departure: LegLocation;
  desk?: string;
  destination: LegLocation;
  externalId: string;
  flightNumber: string;
  hasUnreadMentions?: boolean;
  id: string;
  locked?: boolean;
  nextLeg?: string;
  previousLeg?: string;
  schedule: {
    ata: Iso8601DateString;
    atd: Iso8601DateString;
    eta: Iso8601DateString;
    etd: Iso8601DateString;
    sta: Iso8601DateString;
    std: Iso8601DateString;
  };
  unreadMessagesCount?: number;
  users?: FlightRoomUser[];
};

type LegLocation = {
  airport: Airport;
  gate?: string;
  parking?: string;
  runway?: string;
  terminal?: string;
};

export type Airport =
  | {
      iata: string;
      icao: string;
    }
  | {
      iata: string;
      icao: null | undefined;
    }
  | {
      iata: null | undefined;
      icao: string;
    };

export enum FlightArrivalStatus {
  ACTIVE = 'ACTIVE',
  ALL_WITHIN_RANGE = 'ALL_WITHIN_RANGE',
  COMPLETED = 'COMPLETED',
}

export type FlightroomMetadataQuery = HttpMetadataQuery & {
  canJoinFlightRooms?: boolean;
  canRequestAccess?: boolean;
  roomArrivalStatus?: FlightArrivalStatus;
};

export type FlightroomData = {
  data: FlightRoom[];
  meta: FlightroomMetadataQuery;
};

export type SelectedFlightroomTab = 'active' | 'completed';
