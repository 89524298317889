import * as React from "react";
const pencil: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.39329 11.9923H3.33329C3.24555 11.9928 3.15857 11.976 3.07735 11.9427C2.99612 11.9094 2.92225 11.8604 2.85995 11.7984C2.79049 11.729 2.73715 11.6451 2.70373 11.5526C2.67031 11.4602 2.65764 11.3615 2.66662 11.2635L2.95329 8.47549C2.98362 8.17008 3.1178 7.88443 3.33329 7.66649L9.33329 1.64911C9.58314 1.43741 9.90187 1.32521 10.2288 1.33384C10.5558 1.34247 10.8682 1.47133 11.1066 1.69591L12.9333 3.52787C13.1786 3.76222 13.3212 4.08452 13.3299 4.42415C13.3387 4.76378 13.2128 5.09304 12.98 5.33977L6.97995 11.3571C6.76264 11.5733 6.47782 11.7078 6.17329 11.7382L3.39329 11.9923ZM12 4.45722L10.18 2.63195L8.87995 3.96915L10.6666 5.76099L12 4.45722ZM7.99995 4.8517L4.24662 8.58916L4.06662 10.5883L6.06662 10.4011L9.79995 6.65691L7.99995 4.8517ZM3.33329 13.3295H12.6666C12.8434 13.3295 13.013 13.4 13.138 13.5253C13.2631 13.6507 13.3333 13.8208 13.3333 13.9981C13.3333 14.1754 13.2631 14.3455 13.138 14.4709C13.013 14.5963 12.8434 14.6667 12.6666 14.6667H3.33329C3.15648 14.6667 2.98691 14.5963 2.86188 14.4709C2.73686 14.3455 2.66662 14.1754 2.66662 13.9981C2.66662 13.8208 2.73686 13.6507 2.86188 13.5253C2.98691 13.4 3.15648 13.3295 3.33329 13.3295Z"
      fill="#4986A0"
    />
  </svg>
);
export default pencil;
