import { DateTimePartsEnum } from './avio-date-time.model';
import { valueOf } from './format.util';

interface DiffSettings {
  showDecimals?: boolean;
}

export const isSame = (date1: Date, date2: Date = new Date()) => date1.getTime() === date2.getTime();

export const isBefore = (date1: Date, date2: Date = new Date()) => date1 < date2;

export const isSameOrBefore = (date1: Date, date2: Date = new Date()) => date1 <= date2;

export const isAfter = (date1: Date, date2: Date = new Date()) => date1 > date2;

export const isSameOrAfter = (date1: Date, date2: Date = new Date()) => date1 >= date2;

export const isBetween = (date: Date, startDate: Date, endDate: Date) => date > startDate && date < endDate;

export const diff = (date1: Date, date2: Date, parts: DateTimePartsEnum, settings: DiffSettings = {}) => {
  const diffSettings = {
    showDecimals: false,
    ...settings,
  };

  const diffMilliseconds = Math.abs(valueOf(date1) - valueOf(date2));
  let diffValue = 0;

  switch (parts) {
    case DateTimePartsEnum.YEAR:
      diffValue = diffMilliseconds / 1000 / 60 / 60 / 24 / 30 / 12;
      break;
    case DateTimePartsEnum.MONTH:
      diffValue = diffMilliseconds / 1000 / 60 / 60 / 24 / 30;
      break;
    case DateTimePartsEnum.DAY:
      diffValue = diffMilliseconds / 1000 / 60 / 60 / 24;
      break;
    case DateTimePartsEnum.HOUR:
      diffValue = diffMilliseconds / 1000 / 60 / 60;
      break;
    case DateTimePartsEnum.MINUTE:
      diffValue = diffMilliseconds / 1000 / 60;
      break;
    case DateTimePartsEnum.SECOND:
      diffValue = diffMilliseconds / 1000;
      break;
    case DateTimePartsEnum.MILLISECOND:
      diffValue = diffMilliseconds;
      break;
    default:
      diffValue = diffMilliseconds;
      break;
  }

  if (isBefore(date2, date1)) {
    diffValue = -diffValue;
  }

  if (!diffSettings.showDecimals) {
    diffValue = Math.floor(diffValue);
  }

  return diffValue;
};

export const timezoneOffset = (date: Date) => date.getTimezoneOffset();
