import { FC, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Text } from '@aviobook/_shared';
import { COLORS } from 'styles';

import './quickReplies.scss';

type Props = {
  onPressQuickReply: (quickReply: string) => void;
  quickReplies: string[];
};

const SHOW_MORE_BUTTON_WIDTH = 50;

export const QuickReplies: FC<Props> = ({ onPressQuickReply, quickReplies }) => {
  const [visibleCount, setVisibleCount] = useState(quickReplies.length);
  const [isExpanded, setIsExpanded] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const calculateButtonWidth = (button: HTMLButtonElement) => {
    const styles = window.getComputedStyle(button);
    const margin = parseInt(styles.marginLeft || '0') + parseInt(styles.marginRight || '0');
    return button.offsetWidth + margin;
  };

  const getMentionButtons = (container: HTMLDivElement) => {
    return Array.from(container.querySelectorAll<HTMLButtonElement>('button:not(.show-more-button)'));
  };

  const updateVisibleCount = useCallback(() => {
    if (!containerRef.current) {
      return;
    }

    const container = containerRef.current;
    const containerWidth = container.offsetWidth;
    const buttons = getMentionButtons(container);

    buttons.forEach(button => {
      button.classList.remove('hidden');
      button.classList.add('visible');
    });

    if (isExpanded) {
      setVisibleCount(quickReplies.length);
      return;
    }

    let totalWidth = 0;
    let visibleButtonCount = 0;

    for (let i = 0; i < buttons.length; i++) {
      const button = buttons[i];
      const buttonWidth = calculateButtonWidth(button);

      const availableWidth = containerWidth - (buttons.length > i + 1 ? SHOW_MORE_BUTTON_WIDTH : 0);

      if (totalWidth + buttonWidth > availableWidth) {
        break;
      }

      totalWidth += buttonWidth;
      visibleButtonCount++;
    }

    buttons.forEach((button, index) => {
      if (index < visibleButtonCount) {
        button.classList.remove('hidden');
        button.classList.add('visible');
      } else {
        button.classList.remove('visible');
        button.classList.add('hidden');
      }
    });

    setVisibleCount(visibleButtonCount);
  }, [isExpanded, quickReplies.length]);

  useLayoutEffect(() => {
    updateVisibleCount();

    const observer = new ResizeObserver(() => {
      requestAnimationFrame(updateVisibleCount);
    });

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => observer.disconnect();
  }, [updateVisibleCount]);

  useEffect(() => {
    updateVisibleCount();
  }, [quickReplies, updateVisibleCount]);

  const renderQuickReplyButton = (quickReply: string, index: number) => (
    <button
      className={`quick-reply-button ${isExpanded ? 'visible' : index < visibleCount ? 'visible' : 'hidden'}`}
      key={`${quickReply}-${index}`}
      onClick={() => onPressQuickReply(quickReply)}
    >
      <Text as="span" color={COLORS.yuma.$01} size="SM" weight="bold">
        {quickReply}
      </Text>
    </button>
  );

  if (quickReplies.length === 0) {
    return null;
  }

  return (
    <div className={`quick-reply-container ${isExpanded ? 'expanded' : ''}`} ref={containerRef}>
      <div className="quick-reply-inner">
        {quickReplies.map(renderQuickReplyButton)}
        {!isExpanded && visibleCount < quickReplies.length && (
          <button
            aria-expanded={isExpanded}
            className="quick-reply-button show-more-button"
            key="showmore"
            onClick={() => setIsExpanded(true)}
          >
            <Text as="span" color={COLORS.zulu.$08} size="SM" weight="bold">
              {`+${quickReplies.length - visibleCount}`}
            </Text>
          </button>
        )}
      </div>
    </div>
  );
};
