import * as React from "react";
const exclamationDiamondOutline: React.FC<
  React.SVGProps<SVGSVGElement>
> = () => (
  <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m13.173 1.78 7.047 7.047c1.2 1.2 1.2 3.146 0 4.346l-7.047 7.047a3.073 3.073 0 0 1-4.346 0L1.78 13.173a3.073 3.073 0 0 1 0-4.346L8.827 1.78c1.2-1.2 3.146-1.2 4.346 0zm-3.26 1.087L2.867 9.913c-.6.6-.6 1.573 0 2.174l7.046 7.046c.6.6 1.573.6 2.174 0l7.046-7.046c.6-.6.6-1.573 0-2.174l-7.046-7.046c-.6-.6-1.573-.6-2.174 0zM11 15.226a.768.768 0 1 1 0 1.536.768.768 0 0 1 0-1.536zm0-9.988c.424 0 .768.344.768.768v7.683a.768.768 0 0 1-1.536 0V6.006c0-.424.344-.768.768-.768z"
      fill="#031D2B"
      fillRule="evenodd"
    />
  </svg>
);
export default exclamationDiamondOutline;
