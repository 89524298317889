import React, { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { hasReachedScrollEnd } from '@aviobook/_utils/react/react.utils';
import { FlightroomItem } from '@aviobook/flightrooms/_components/flightroomitem/FlightroomItem';
import { ModalOpener } from '@aviobook/modal/ModalOpener';
import { RequestFlightroomAccessModal } from '@aviobook/modal/requestFlightroomAccessModal/RequestFlightroomAccessModal';
import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { useAuthenticatedUser, useFlightroomsContext, useJoinFlightroom, useSearchFlightRooms } from 'shared';
import { COLORS } from 'styles';
import { FlightRoom, QueryKeys } from 'types';

import { Spinner, Text } from '../../_shared';

type Props = {
  resetSearchQuery: () => void;
  searchQuery: string;
};

export const SearchedFlightRoomList: FC<Props> = ({ resetSearchQuery, searchQuery }) => {
  const [, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { setSelectedFlightroom } = useFlightroomsContext();

  const blockUI = useRef(false);

  const { mutateAsync: joinFlightroom } = useJoinFlightroom({
    onSettled: () => {
      blockUI.current = false;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.FLIGHTROOMS]);
    },
  });

  const {
    canJoinFlightRooms,
    canRequestAccess,
    canSearchFlightRooms,
    canViewFlightRooms,
    hasPendingAccessRequest,
    isActiveMemberOfRoom,
  } = useAuthenticatedUser();

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading: isLoadingSearch } = useSearchFlightRooms(searchQuery);

  useEffect(() => {
    if (!canSearchFlightRooms || !canViewFlightRooms) {
      resetSearchQuery();
    }
  }, [canSearchFlightRooms, canViewFlightRooms, resetSearchQuery]);

  const onClickSearchableFlightroom = async (room: FlightRoom) => {
    if (blockUI.current === true) {
      return;
    }

    if (isActiveMemberOfRoom(room)) {
      resetSearchQuery();
      setSearchParams({ room: room.externalId });
      return;
    }

    if (canJoinFlightRooms) {
      blockUI.current = true;

      await joinFlightroom(room.externalId);
      resetSearchQuery();
      setSearchParams({ room: room.externalId });
      return;
    }

    if (hasPendingAccessRequest(room)) {
      return;
    }

    if (canRequestAccess) {
      ModalOpener.instance.open({
        render: () => (
          <RequestFlightroomAccessModal
            close={() => {
              ModalOpener.instance.close();
              resetSearchQuery();
              setSelectedFlightroom(null);
            }}
            content={t('FLIGHTROOMS.REQUEST_ACCESS.MESSAGE', { flightroom: room.flightNumber })}
            roomId={room.externalId}
            searchQuery={searchQuery}
            title={t('FLIGHTROOMS.REQUEST_ACCESS.TITLE')}
          />
        ),
      });

      return;
    }
  };

  if (isLoadingSearch) {
    return (
      <section className={classNames('flightrooms-content-container', 'is-loading')}>
        <Spinner size="large" />
      </section>
    );
  }

  if (data?.pages?.length === 0) {
    return (
      <section className={classNames('flightrooms-content-container', 'is-empty-search')}>
        <Text className="flightrooms-empty-search-text" color={COLORS.zulu.$10} size="MD" weight="bold">
          {`${t('FLIGHTROOMS.EMPTY_SEARCH.TITLE')}, ${t('FLIGHTROOMS.EMPTY_SEARCH.DESCRIPTION')}`}
        </Text>
      </section>
    );
  }

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (hasReachedScrollEnd(e) && !isFetchingNextPage && hasNextPage) {
      fetchNextPage();
    }
  };

  return (
    <section className={classNames('flightrooms-content-container')} onScroll={handleScroll}>
      {data?.pages.map(page =>
        page.items.map(room => (
          <FlightroomItem
            displayAccessState={true}
            displayJoinIcon={true}
            flightroom={room}
            key={`searchable-${room.externalId}`}
            onClick={onClickSearchableFlightroom}
          />
        )),
      )}
      {isFetchingNextPage ? (
        <span className="flightrooms-content-fetching-spinner">
          <Spinner size="normal" />
        </span>
      ) : null}
    </section>
  );
};
