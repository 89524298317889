import { SortOptionsV1 } from 'types';

import { MessageUnion } from '../types';

export const sortMessagesByDate = (a: MessageUnion, b: MessageUnion, sort = SortOptionsV1.ASC): number => {
  const aTime = new Date(a.createdAt).getTime();
  const bTime = new Date(b.createdAt).getTime();

  if (sort === SortOptionsV1.DESC) {
    return aTime - bTime;
  } else {
    return bTime - aTime;
  }
};
