enum FiqlOperators {
  EQUALS = '==',
  GREATER_THEN = '=gt=',
  GREATER_THEN_OR_EQUAL_TO = '=ge=',
  IN = '=in=',
  LESS_THEN = '=lt=',
  LESS_THEN_OR_EQUAL_TO = '=le=',
  NOT_EQUALS = '!=',
  OUT = '=out=',
  REGEX = '=regex=',
}

export default FiqlOperators;
