import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PageContent, Tab } from '@aviobook/cms/_shared/pageContent/PageContent';
import { QuickRepliesPermissions, SmartActionPermissions } from 'types';

import { SmartActionQuickReplies, SmartActions } from './_tabs';

export const General: FC = () => {
  const { t } = useTranslation();
  const TABS: Tab[] = [
    {
      label: t('TAB.GENERAL.SMART_ACTIONS'),
      name: 'smartActions',
      order: 0,
      permissions: [SmartActionPermissions.VIEW],
      screen: () => <SmartActions />,
    },
    {
      label: t('QUICK_REPLIES.MULTI'),
      name: 'quickReplies',
      order: 1,
      permissions: [QuickRepliesPermissions.VIEW],
      screen: () => <SmartActionQuickReplies />,
    },
    // We comment the privacy settings section until we receive feedback from clients they actually need it
    // https://aviobook.atlassian.net/browse/AVIO-41057
    // {
    //   label: t('TAB.GENERAL.CONFIGURATION'),
    //   name: 'configuration',
    //   order: 2,
    //   permissions: [GeneralPermissions.UPDATE_ACCOUNT_SETTINGS],
    //   screen: () => <Configuration />,
    // },
  ];

  return <PageContent tabs={TABS} textSize="MD" />;
};
