import * as React from "react";
const exclamationHexagonOutline: React.FC<
  React.SVGProps<SVGSVGElement>
> = () => (
  <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.115 1.76c.825 0 1.584.452 1.977 1.178l3.757 6.94a2.249 2.249 0 0 1 0 2.141l-3.757 6.94a2.249 2.249 0 0 1-1.977 1.179h-7.35a2.249 2.249 0 0 1-1.977-1.178L2.03 12.02a2.249 2.249 0 0 1 0-2.141l3.757-6.94A2.249 2.249 0 0 1 7.765 1.76h7.35zm0 1.5h-7.35a.75.75 0 0 0-.659.392l-3.756 6.94a.75.75 0 0 0 0 .714l3.756 6.94a.75.75 0 0 0 .66.393h7.349a.75.75 0 0 0 .659-.393l3.756-6.94a.75.75 0 0 0 0-.714l-3.756-6.94a.75.75 0 0 0-.66-.393zM11.44 14.321a.75.75 0 1 1 0 1.5.75.75 0 0 1 0-1.5zm0-8.245a.75.75 0 0 1 .75.75v5.246a.75.75 0 1 1-1.5 0V6.826a.75.75 0 0 1 .75-.75z"
      fill="#FFF"
      fillRule="evenodd"
    />
  </svg>
);
export default exclamationHexagonOutline;
