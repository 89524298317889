import React, { FC } from 'react';
import { IconName } from 'assets';
import { COLORS } from 'styles';

import { Icon } from '../icon/Icon';

import './avatar.scss';

type AvatarSize = 'small' | 'medium' | 'large' | number;

type Props = {
  icon: IconName;
  isDisabled?: boolean;
  onPress?: () => void;
  size?: AvatarSize;
};

export const Avatar: FC<Props> = ({ icon, isDisabled = false, onPress, size = 'medium' }) => {
  const getAvatarSize = (): number => {
    if (typeof size === 'number') {
      return size;
    }

    switch (size) {
      case 'large':
        return 13.2;
      case 'medium':
        return 3.7;
      case 'small':
        return 2.8;
      default:
        return 5;
    }
  };

  const getAvatarIconSize = (): number => {
    if (typeof size === 'number') {
      return size * 0.55;
    }

    switch (size) {
      case 'large':
        return 6;
      case 'medium':
        return 2.3;
      case 'small':
        return 1.25;
      default:
        return 2.3;
    }
  };

  const style = {
    height: `${getAvatarSize()}rem`,
    width: `${getAvatarSize()}rem`,
  };

  return (
    <button className="avatar" data-test="avatar" disabled={isDisabled} onClick={onPress} style={style}>
      <Icon colorName={COLORS.yuma.$01} name={icon} size={getAvatarIconSize()} />
    </button>
  );
};
