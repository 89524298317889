import * as React from "react";
const exclamationTriangleOutline: React.FC<
  React.SVGProps<SVGSVGElement>
> = () => (
  <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m12.8 3.181 7.279 12.999a2.062 2.062 0 0 1-1.8 3.07H3.721a2.063 2.063 0 0 1-1.8-3.07L9.201 3.18a2.062 2.062 0 0 1 3.599 0zm-2.136.408a.688.688 0 0 0-.264.264L3.121 16.852a.687.687 0 0 0 .6 1.023h14.558a.687.687 0 0 0 .6-1.023l-7.28-12.999a.687.687 0 0 0-.935-.264zM11 15.125a.687.687 0 1 1 0 1.375.687.687 0 0 1 0-1.375zm0-7.563c.38 0 .688.308.688.688v4.813a.687.687 0 1 1-1.376 0V8.25c0-.38.308-.688.688-.688z"
      fill="#10394E"
      fillRule="evenodd"
    />
  </svg>
);
export default exclamationTriangleOutline;
