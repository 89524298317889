import * as React from "react";
const circularPlusOutline: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5 1.563c6.04 0 10.938 4.896 10.938 10.937 0 6.04-4.897 10.938-10.938 10.938-6.04 0-10.938-4.897-10.938-10.938C1.563 6.46 6.46 1.562 12.5 1.562zm0 1.562a9.375 9.375 0 1 0 0 18.75 9.375 9.375 0 0 0 0-18.75zm0 3.125c.4 0 .73.302.776.69l.005.091v4.688h4.688a.781.781 0 0 1 .09 1.557l-.09.005H13.28v4.688a.781.781 0 0 1-1.557.09l-.005-.09V13.28H7.03a.781.781 0 0 1-.09-1.557l.09-.005h4.688V7.03c0-.431.35-.781.781-.781z"
      fill="#0F384D"
      fillRule="evenodd"
    />
  </svg>
);
export default circularPlusOutline;
