import * as React from "react";
const checkbox: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg viewBox="0 0 23 23" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>
      AviobookBase/Components2.0/Checkboxes/checkbox-filled-default 2
    </title>
    <g
      id="Flight-Rooms"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="AvioBookConnect/Web/Flightrooms/FlightroomDetail-requests2b"
        transform="translate(-425, -341)"
        fill="#FFFFFF"
        stroke="#A1B5C0"
      >
        <g id="modal" transform="translate(405, 200)">
          <g id="user-copy" transform="translate(0, 131)">
            <g
              id="AviobookBase/Components2.0/Checkboxes/checkbox-filled-default"
              transform="translate(20, 10)"
            >
              <rect
                id="Rectangle"
                x="0.5"
                y="0.5"
                width="22"
                height="22"
                rx="4"
              ></rect>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default checkbox;
