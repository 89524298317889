import { andes } from './andes';
import { bogota } from './bogota';
import { etna } from './etna';
import { everest } from './everest';
import { fuji } from './fuji';
import { montblanc } from './montblanc';
import { rocky } from './rocky';
import { romeo } from './romeo';
import { tango } from './tango';
import { uniform } from './uniform';
import { victor } from './victor';
import { yuma } from './yuma';
import { zulu } from './zulu';

export { andes, bogota, etna, everest, fuji, montblanc, rocky, romeo, tango, uniform, victor, yuma, zulu };

export const COLORS = {
  andes,
  bogota,
  etna,
  everest,
  fuji,
  montblanc,
  rocky,
  romeo,
  tango,
  uniform,
  victor,
  yuma,
  zulu,
};
