import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useBreadcrumbs } from '@aviobook/_hooks';
import { toastError } from '@aviobook/Toastify';

import { useGetRole } from '../../_queries';

export const useRole = () => {
  const { t } = useTranslation();
  const { roleId } = useParams();
  const updateBreadCrumbs = useBreadcrumbs(state => state.findAndReplace);

  const { data, isError, isLoading, isSuccess } = useGetRole(roleId);

  useEffect(() => {
    if (isSuccess) {
      updateBreadCrumbs(roleId, data.airlineRole);
    }
  }, [isSuccess, data, roleId, updateBreadCrumbs]);

  if (isError) {
    toastError(t('TOAST_ERROR'));
  }

  return { data, isError, isLoading };
};
