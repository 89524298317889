import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { HttpClient } from 'shared';
import { ApiError, QueryKeys } from 'types';

import { API_FACTORY } from '../utils/factories/api.factory';

const getQuickReplies = (): Promise<string[]> =>
  HttpClient.get<{ quickReplies: string[] }>(API_FACTORY.v1('messages/quick-replies')).then(data => data.quickReplies);

export const useGetQuickReplies = (options?: UseQueryOptions<string[], ApiError>) => {
  const query = useQuery<string[], ApiError, string[]>([QueryKeys.QUICK_REPLIES], () => getQuickReplies(), options);

  return {
    ...query,
    isEmptyQuickReplies: query.data?.length === 0,
  };
};
