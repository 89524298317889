import * as React from "react";
const checkboxChecked: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_320_10)">
      <path
        d="M19 0H4C1.79086 0 0 1.79086 0 4V19C0 21.2091 1.79086 23 4 23H19C21.2091 23 23 21.2091 23 19V4C23 1.79086 21.2091 0 19 0Z"
        fill="#47AACF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.342 7.13523C18.5434 7.3241 18.5536 7.64052 18.3648 7.84197L10.8648 15.842C10.7722 15.9408 10.6434 15.9978 10.5081 15.9999C10.3727 16.0021 10.2422 15.9493 10.1464 15.8536L6.14645 11.8536C5.95118 11.6583 5.95118 11.3417 6.14645 11.1464C6.34171 10.9512 6.65829 10.9512 6.85355 11.1464L10.4884 14.7813L17.6352 7.15803C17.8241 6.95657 18.1405 6.94637 18.342 7.13523Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_320_10">
        <rect width="23" height="23" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default checkboxChecked;
