export class SetUtil {
  static areEqual<T>(s1: Set<T>, s2: Set<T>) {
    if (s1.size !== s2.size) {
      return false;
    }

    const v1 = Array.from(s1.values());
    const v2 = Array.from(s2.values());

    for (let i = 0; i < v1.length; i++) {
      if (v1[i] !== v2[i]) {
        return false;
      }
    }

    return true;
  }

  static containSameEntries<T>(s1: Set<T>, s2: Set<T>) {
    if (s1.size !== s2.size) {
      return false;
    }

    const v1 = Array.from(s1.values()).sort();
    const v2 = Array.from(s2.values()).sort();

    for (let i = 0; i < v1.length; i++) {
      if (v1[i] !== v2[i]) {
        return false;
      }
    }

    return true;
  }
}
