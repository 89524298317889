import { useCallback } from 'react';
import { Serializable } from 'types';
import { StorageSolution } from 'types/src/storageSolution';

export const useStorage = <T extends Serializable>(solution: StorageSolution) => {
  const set = useCallback(
    (key: string, input: T) => {
      solution.set(key, JSON.stringify(input));
    },
    [solution],
  );

  const get = useCallback(async (key: string): Promise<T> => JSON.parse(await solution.get(key)) as T, [solution]);

  const has = useCallback((key: string): boolean => solution.has(key), [solution]);

  const clear = useCallback((): void => {
    solution.clear();
  }, [solution]);

  return {
    clear,
    get,
    has,
    set,
  };
};
