import { NestedObjectsPathKeys, SortOptionsV1 } from 'types';

import { BaseSort } from './base-sort';
import { Sortable } from './sort';

export class SortAscending<T extends object = { [key: string]: unknown }>
  extends BaseSort<SortOptionsV1.ASC, T>
  implements Sortable<T>
{
  type: SortOptionsV1.ASC = SortOptionsV1.ASC;

  constructor(field: NestedObjectsPathKeys<Required<T>>) {
    super(field);
  }
}
