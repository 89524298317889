const stripLeadingSlash = (input: string): string => {
  if (input.startsWith('/')) {
    return input.substring(1);
  }

  return input;
};

const normalize = (input: string): string => input.trim();

const universal = (suffix: string) => {
  suffix = normalize(suffix);

  suffix = stripLeadingSlash(suffix);

  return `/api/${suffix}`;
};

const getPathForVersion = (version: string, suffix: string) => {
  suffix = normalize(suffix);

  suffix = stripLeadingSlash(suffix);

  return `/api/${version}/${suffix}`;
};

const v1 = (suffix: string) => {
  return getPathForVersion('v1', suffix);
};

const v2 = (suffix: string) => {
  return getPathForVersion('v2', suffix);
};

const v3 = (suffix: string) => {
  return getPathForVersion('v3', suffix);
};

export const API_FACTORY = {
  universal,
  v1,
  v2,
  v3,
};
