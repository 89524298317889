import { Platform } from 'react-native';
import { AxiosError, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse, ResponseType } from 'axios';
import { ApiError, HttpStatus, ValidationError } from 'types';

import { EnvConfig } from '../../context';
import { ErrorResponse } from './http.types';
import { axios, errorInterceptor, requestInterceptor } from './HttpsUtils';

type Params = Record<string, string | number | boolean | null | undefined>;
type Headers = AxiosRequestHeaders;

class HttpClient {
  static config: EnvConfig;

  static setConfig(config: EnvConfig) {
    HttpClient.config = config;
  }

  static requestInterceptorResult: number;
  static responseInterceptorResult: number;
  static setInstances(onError: (response: ErrorResponse) => void) {
    HttpClient.requestInterceptorResult = axios.interceptors.request.use(requestInterceptor);
    HttpClient.responseInterceptorResult = axios.interceptors.response.use(response => response, errorInterceptor(onError));
  }
  static resetInstances() {
    if (HttpClient.requestInterceptorResult) {
      axios.interceptors.request.eject(HttpClient.requestInterceptorResult);
    }
    if (HttpClient.responseInterceptorResult) {
      axios.interceptors.response.eject(HttpClient.responseInterceptorResult);
    }
  }

  static getUrl(route: string): string {
    if (route.indexOf('http://') === 0 || route.indexOf('https://') === 0 || route.indexOf('www.') === 0) {
      return route;
    }
    return `${HttpClient.config.connectServiceApiHost}${route}`;
  }

  static getUrlWithParams(route: string, params: Params): string {
    let url = HttpClient.getUrl(route);
    if (params) {
      for (const property in params) {
        if (params[property] !== null && params[property] !== undefined) {
          url = HttpClient.addQueryStringParameter(url, property, `${params[property]}`);
        }
      }
    }
    return url;
  }

  static addQueryStringParameter(uri: string, key: string, value: string): string {
    const regex = new RegExp(`([?&])${key}=.*?(&|$)`, 'i');
    const separator = uri.indexOf('?') !== -1 ? '&' : '?';
    if (uri.match(regex)) {
      return uri.replace(regex, `$1${key}=${value}$2`);
    }
    return `${uri + separator + key}=${value}`;
  }

  static parseRequestPayload<T extends { [key: string]: unknown }>(object: T): T {
    return Object.keys(object).reduce(
      (acc: T, key: string) => ({
        ...acc,
        [key]: object[key as keyof T] === '' ? null : (object[key as keyof T] as object),
      }),
      {} as T,
    );
  }

  static getBasicHeaders(): Headers {
    const headers = {
      'Ac-Api-Key': HttpClient.config.connectServiceApiKey,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      platform: Platform.OS,
    };
    return headers;
  }

  static createApiError(error: AxiosError): ApiError {
    if (error.response) {
      const data: {
        code?: string;
        error?: string;
        message?: string | Array<ValidationError & { property: string }>;
        statusCode: HttpStatus;
      } = error.response.data;
      return {
        error: data.error,
        message: typeof data.message === 'string' ? data.message : null || data.code,
        statusCode: data.statusCode,
        validationErrors: Array.isArray(data.message)
          ? data.message.reduce(
              (acc: Record<string, ValidationError>, { property, ...validationError }) => ({
                ...acc,
                [property]: validationError,
              }),
              {},
            )
          : {},
      };
    }
    return {
      message: error.message,
      statusCode: HttpStatus.INTERNAL_SERVER_ERROR,
    };
  }

  static async getRaw<T>(
    route: string,
    params: Params = {},
    headers: Headers = {},
    responseType: ResponseType = 'json',
  ): Promise<AxiosResponse<T>> {
    try {
      return await axios.get<T>(this.getUrlWithParams(route, params), {
        headers: { ...this.getBasicHeaders(), ...headers },
        responseType,
        withCredentials: true,
      });
    } catch (error) {
      throw this.createApiError(error as AxiosError);
    }
  }

  static async get<T>(
    route: string,
    params: Params = {},
    headers: Headers = {},
    responseType: ResponseType = 'json',
  ): Promise<T> {
    const result = await this.getRaw<T>(route, params, headers, responseType);
    return result.data;
  }

  static async put<T>(route: string, body: Record<string, unknown> = {}, headers: Headers = {}, params: Params = {}): Promise<T> {
    try {
      const result = await axios.put<T>(this.getUrlWithParams(route, params), body, {
        headers: { ...this.getBasicHeaders(), ...headers },
        withCredentials: true,
      });
      return result.data;
    } catch (error) {
      throw this.createApiError(error as AxiosError);
    }
  }

  static async patch<T>(route: string, body: Record<string, unknown> = {}, headers: Headers = {}): Promise<T> {
    try {
      const result = await axios.patch<T>(this.getUrl(route), body, {
        headers: { ...this.getBasicHeaders(), ...headers },
        withCredentials: true,
      });
      return result.data;
    } catch (error) {
      throw this.createApiError(error as AxiosError);
    }
  }

  static async post<T>(
    route: string,
    body: Record<string, unknown> | string | FormData = {},
    headers: Headers = {},
    withCredentials = true,
  ): Promise<T> {
    try {
      const finalHeaders = { ...this.getBasicHeaders(), ...headers };

      const result = await axios.post<T>(this.getUrl(route), body, {
        headers: finalHeaders,
        // Prevent axios from trying to transform FormData
        transformRequest: [
          data => {
            if (data instanceof FormData) {
              return data;
            }
            if (typeof data === 'object') {
              return JSON.stringify(data);
            }
            return data as string;
          },
        ],

        withCredentials,
      });
      return result.data;
    } catch (error) {
      throw this.createApiError(error as AxiosError);
    }
  }

  static async delete<T>(route: string, headers: Headers = {}): Promise<T> {
    try {
      const result = await axios.delete<T>(this.getUrl(route), {
        headers: { ...this.getBasicHeaders(), ...headers },
        withCredentials: true,
      });
      return result.data;
    } catch (error) {
      throw this.createApiError(error as AxiosError);
    }
  }

  static async retry(config: AxiosRequestConfig): Promise<AxiosResponse> {
    try {
      return axios(config);
    } catch (error) {
      throw this.createApiError(error as AxiosError);
    }
  }
}

export default HttpClient;
