import { FC } from 'react';
import { Avatar, Icon, MessageStatusIcon, StatusDot, Text } from '@aviobook/_shared';
import { getIconForOccupation } from '@aviobook/_utils';
import classNames from 'classnames';
import { replaceJobTitlePlaceholders, UserV1Util, useUsersContext } from 'shared';
import { formatDateToTimeDate } from 'shared/src/utils/date-time/lib/avio-date-time.util';
import { COLORS } from 'styles';
import { Iso8601DateString, MessageStatus } from 'types';
import { FlightRoomUser } from 'types/src/room';

import './flightroomInfoItem.scss';

type Props = {
  crewMember: FlightRoomUser;
  messageDeliveryStatus?: MessageStatus;
  onClick?: (user: FlightRoomUser) => void;
  receiptUpdatedAt?: Iso8601DateString;
};

export const FlightroomInfoItem: FC<Props> = ({ crewMember, messageDeliveryStatus, onClick, receiptUpdatedAt }) => {
  const { getUserById } = useUsersContext();

  const user = getUserById(crewMember.externalId);
  const isClickable = user && onClick;

  return (
    <div
      className={classNames('flightroom-info-item-container', isClickable && 'clickable')}
      data-test="flightroom-crewmember-item"
      onClick={() => {
        if (!isClickable) {
          return;
        }
        onClick?.(crewMember);
      }}
    >
      <div className="flightroom-info-left">
        <section className="flightroom-info-icon">
          <Avatar icon={getIconForOccupation(crewMember.scheduled ? crewMember.type.role : user?.jobTitle)} isDisabled />
        </section>
        <section className="flightroom-info-text">
          <div className="flex-row">
            {messageDeliveryStatus ? <MessageStatusIcon status={messageDeliveryStatus} /> : null}
            <Text color={COLORS.romeo.$07} size="SM" testID="flightroom-info-item--title" weight="bold">
              {crewMember.scheduled
                ? replaceJobTitlePlaceholders(crewMember.type.name)
                : UserV1Util.getGuestUserString(user?.jobTitle)}
            </Text>
          </div>
          <Text color={COLORS.zulu.$08} size="SM" testID="flightroom-info-item--name">
            {UserV1Util.getFullName(user)}
          </Text>
          {receiptUpdatedAt ? (
            <Text color={COLORS.zulu.$08} size="XS" testID="flightroom-info-item--name">
              {formatDateToTimeDate(receiptUpdatedAt)}
            </Text>
          ) : null}
        </section>
      </div>
      <section className="flightroom-info-right">
        <StatusDot size="small" status={user?.connectivityStatus} userId={user?.externalId} />
        {isClickable && <Icon colorName={COLORS.bogota.$04} name="chevronRight" />}
      </section>
    </div>
  );
};
