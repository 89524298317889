import * as React from "react";
const checkmark: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path
        d="M25 0C11.215 0 0 11.215 0 25s11.215 25 25 25 25-11.215 25-25S38.785 0 25 0z"
        fill="#25C164"
        fillRule="nonzero"
      />
      <path
        d="M14.868 25.932a1.68 1.68 0 0 0-2.376 2.376l11.2 11.2a1.68 1.68 0 0 0 2.663-.384l13.44-24.64a1.68 1.68 0 1 0-2.95-1.608L24.48 35.544l-9.612-9.612z"
        fill="#FFF"
      />
    </g>
  </svg>
);
export default checkmark;
