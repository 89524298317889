import * as React from "react";
const paperplaneOutline: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg viewBox="0 0 31 31" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m28.333 6.38.057.062a.951.951 0 0 1 .126.18c.135.25.166.557.034.832l-5.12 17.22a.937.937 0 0 1-1.357.55l-6.588-3.696-3.02 3.023c-.414.489-1.264.429-1.56-.207l-.044-.11-2.174-6.52-5.833-3.271c-.7-.393-.607-1.407.108-1.688l.107-.035 24.326-6.55a.886.886 0 0 1 .938.21zm-2.404 3.305-11.373 9.171 7.41 4.158 3.963-13.33zM12.568 18.05l6.651-5.364-8.675 4.67 1.116 3.346.592-2.168a.938.938 0 0 1 .229-.404l.087-.08zm7.56-7.984L5.776 13.932l3.515 1.971 10.837-5.836z"
      fill="#FFF"
      fillRule="evenodd"
    />
  </svg>
);
export default paperplaneOutline;
