import { Iso8601DateString } from 'types';

import { MessageSubTypes, MessageTypes } from './messageTypeV1';

export enum MessageStatus {
  DELIVERED = 'DELIVERED',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  READ = 'READ',
  SENT = 'SENT',
}

export type ReceiptV1 = {
  status: MessageStatus;
  updatedAt: Iso8601DateString;
  user: string;
};

export type Mention = {
  isExternal: boolean;
  label: string;
  type: MentionType;
  users: string[];
};

export enum MentionType {
  CREW_ROLE = 'CREW_ROLE',
  USER_NAME = 'USER_NAME',
  USER_ROLE = 'USER_ROLE',
}

export type MessageV1<T extends MessageTypes = MessageTypes.USER | MessageTypes.SYSTEM> = {
  attachment?: string;
  createdAt: Iso8601DateString;
  id: string;
  mentions?: Mention[];
  meta: {
    clientMessageId: string;
  };
  pendingImage?: File;
  receipts: ReceiptV1[];
  recipients?: string[];
  room: string;
  status?: MessageStatus;
  subType?: MessageSubTypes;
  text?: string;
  type: T;
  updatedAt: Iso8601DateString;
};
