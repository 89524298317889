import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../_shared';
import Modal from '../Modal';
import { ModalOpener } from '../ModalOpener';

type Props = {
  confirmText: string;
  content: string;
  isLoading?: boolean;
  onCancel?: () => void;
  onConfirm: () => void;
  title: string;
};

const ConfirmationModal: FC<Props> = ({ confirmText, content, isLoading, onCancel, onConfirm, title }) => {
  const { t } = useTranslation();

  const onClickCancel = () => {
    if (!isLoading) {
      onCancel?.();
      ModalOpener?.instance.close();
    }
  };

  return (
    <Modal onCancel={onClickCancel}>
      <Modal.Header onCancel={onClickCancel}>{title}</Modal.Header>
      <Modal.Content>
        <p>{content}</p>
      </Modal.Content>
      <Modal.Buttons>
        <Button disabled={isLoading} isOutline onClick={onClickCancel} theme="dark">
          {t('SHARED.BUTTONS.CANCEL')}
        </Button>
        <Button
          loading={isLoading}
          onClick={() => {
            onConfirm?.();
            ModalOpener?.instance.close();
          }}
          theme="negative"
        >
          {confirmText}
        </Button>
      </Modal.Buttons>
    </Modal>
  );
};

export default Object.assign(ConfirmationModal, {
  render: (props: Props) => {
    return <ConfirmationModal {...props} />;
  },
});
