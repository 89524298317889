import { useMemo } from 'react';
import { QueryFunctionContext, QueryKey, useInfiniteQuery } from '@tanstack/react-query';
import { ApiError, FlightRoom, PaginationV1, QueryKeys, RequestOptionsV1 } from 'types';

import { useAuthenticationContext } from '../context';
import { getMessages } from '../queries';
import { SortDescending } from '../utils';
import { MessageUnion } from './messages';

export const useGetMessages = (flightRoomId: FlightRoom['externalId'], onSuccessCallback: () => void) => {
  const { user } = useAuthenticationContext();
  const options: RequestOptionsV1<MessageUnion> = {
    limit: 25,
    sort: [new SortDescending<MessageUnion>('createdAt')],
  };

  const { data, error, fetchNextPage, hasNextPage, isFetching } = useInfiniteQuery<PaginationV1<MessageUnion>, ApiError>(
    [QueryKeys.MESSAGES, flightRoomId],
    (context: QueryFunctionContext<QueryKey, number>) =>
      getMessages(flightRoomId, user!.externalId, {
        ...options,
        offset: context.pageParam,
      }),
    {
      enabled: user !== null,
      getNextPageParam: ({ limit, offset, total }) => {
        if (offset + limit >= total) {
          return undefined;
        }

        return offset + limit;
      },
      onSuccess: onSuccessCallback,
      // we want this data to always be considered stale so it is refetched in de background
      staleTime: 0,
    },
  );

  const messages: MessageUnion[] = useMemo(() => data?.pages?.flatMap(page => page.items ?? []) || [], [data]);
  return {
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    messages,
  };
};
