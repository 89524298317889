import { BaseSyntheticEvent, FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { ColorName } from 'styles';

import { Spinner } from '../spinner/Spinner';

import './button.scss';

type Props = {
  asText?: boolean;
  children: ReactNode;
  className?: string;
  color?: ColorName;
  disabled?: boolean;
  href?: string;
  isOutline?: boolean;
  loading?: boolean;
  onClick?: (event?: BaseSyntheticEvent) => void;
  testId?: string;
  theme?: 'neutral' | 'primary' | 'negative' | 'dark';
  type?: 'button' | 'submit' | 'reset';
};

export const Button: FC<Props> = ({
  asText,
  children,
  className = '',
  disabled,
  href,
  isOutline = false,
  loading,
  onClick = () => {},
  testId,
  theme = 'neutral',
  type = 'button',
}) => {
  const cssClasses = classnames(asText ? 'as-text' : 'button', className, theme, { disabled, loading, outline: isOutline });

  const renderSpinner = () => {
    if (!loading) {
      return null;
    }
    return (
      <div className="spinner-wrapper" data-test={`${testId}--spinner`}>
        <Spinner theme={asText || isOutline ? 'normal' : 'inversed'} />
      </div>
    );
  };

  if (href) {
    return (
      <Link className={cssClasses} data-test={testId} to={href}>
        <>
          {children}
          {renderSpinner()}
        </>
      </Link>
    );
  }
  return (
    <button className={cssClasses} data-test={testId} disabled={disabled} onClick={onClick || (() => {})} type={type}>
      {children}
      {renderSpinner()}
    </button>
  );
};
