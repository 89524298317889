import { Dispatch, useEffect } from 'react';
import { MessageStatus } from 'types';
import { StorageSolution } from 'types/src/storageSolution';

import { MessageUnion, useStorage } from '../../../hooks';
import { ChatMessagesActionType } from '../chatMessagesAction.enum';
import { ChatMessagesActions } from '../chatMessagesAction.type';

export const useStorageEffects = (
  storageSolution: StorageSolution,
  flightroomId: string,
  chat: MessageUnion[],
  dispatch: Dispatch<ChatMessagesActions>,
) => {
  const { get: getFromStorage, has: hasInStorage, set: setInStorage } = useStorage<MessageUnion[]>(storageSolution);

  useEffect(() => {
    if (!flightroomId) {
      return;
    }

    setInStorage(
      flightroomId,
      chat.filter((message: MessageUnion) => message.status === MessageStatus.FAILED),
    );
  }, [chat, flightroomId, setInStorage]);

  useEffect(() => {
    const loadMessagesFromStorage = async () => {
      if (!flightroomId || !hasInStorage(flightroomId)) {
        return;
      }
      const cachedMessages = await getFromStorage(flightroomId);
      if (cachedMessages) {
        const receivedMessages = cachedMessages.map(msg => ({ ...msg, status: MessageStatus.SENT }));
        dispatch({ payload: receivedMessages, type: ChatMessagesActionType.ADD_MESSAGES });
      }
    };

    loadMessagesFromStorage();
  }, [flightroomId, getFromStorage, hasInStorage, dispatch]);
};
