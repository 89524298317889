import * as React from "react";
const profileOutline: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5 1.563c6.04 0 10.938 4.896 10.938 10.937 0 6.04-4.897 10.938-10.938 10.938-6.04 0-10.938-4.897-10.938-10.938C1.563 6.46 6.46 1.562 12.5 1.562zm.105 4.648c-1.852 0-2.696.725-2.98 2.045-.106.497-.123 1.014-.079 1.607l.072.774a.781.781 0 0 1-.276.634c-.09.075-.145.36-.073.692.029.13.073.245.12.326l.035.052.05.008a.782.782 0 0 1 .58.55l.02.086c.09.55.319 1.101.646 1.626.156.25.323.478.49.677l.103.12.099.105c.15.148.235.352.233.563a4.338 4.338 0 0 1-.08.777c-.124.633-.352 1.088-.91 1.227l-.115.023c-.827.13-2.538.878-3.863 1.745a9.337 9.337 0 0 0 5.823 2.027 9.336 9.336 0 0 0 5.916-2.102c-1.237-.792-2.797-1.474-3.62-1.647l-.125-.023c-.642-.102-.894-.574-1.026-1.25a4.337 4.337 0 0 1-.08-.777.781.781 0 0 1 .164-.485l.168-.184.103-.12c.167-.198.335-.425.49-.676.328-.525.557-1.075.647-1.626a.781.781 0 0 1 .6-.636l.048-.008.036-.052c.035-.06.07-.14.096-.231l.024-.095c.073-.332.017-.617-.072-.692a.781.781 0 0 1-.277-.634l.05-.515c.07-.703.065-1.298-.057-1.866-.284-1.32-1.128-2.045-2.98-2.045zM12.5 3.125A9.375 9.375 0 0 0 3.125 12.5c0 2.4.902 4.591 2.387 6.25 1.4-.975 3.264-1.835 4.44-2.12l.066-.016.025-.117.02-.143-.019-.02a7.245 7.245 0 0 1-.538-.72l-.113-.177a6.314 6.314 0 0 1-.75-1.688l-.012-.044-.053-.033c-.866-.574-1.173-2.142-.623-3.13l.073-.12-.015-.147c-.083-.85-.078-1.59.085-2.347.435-2.022 1.898-3.28 4.507-3.28 2.61 0 4.073 1.258 4.508 3.28.142.663.164 1.312.111 2.033l-.043.46c.648.982.352 2.654-.548 3.251l-.054.033-.01.044a6.16 6.16 0 0 1-.614 1.457l-.138.231a7.635 7.635 0 0 1-.55.775l-.12.142.03.197.014.063.053.012c1.132.274 2.95 1.099 4.325 2.033a9.34 9.34 0 0 0 2.306-6.159A9.375 9.375 0 0 0 12.5 3.125z"
      fill="#144761"
      fillRule="evenodd"
    />
  </svg>
);
export default profileOutline;
