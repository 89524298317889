import { useTranslation } from 'react-i18next';
import { Checkbox, Text } from '@aviobook/_shared';
import { FlexComponent } from '@aviobook/cms/_shared';
import { createColumnHelper, getCoreRowModel, Table, useReactTable } from '@tanstack/react-table';
import { COLORS } from 'styles';

import { SelectableRoleQuickReply } from '../selectableRoleQuickReply';

type Props = {
  data: SelectableRoleQuickReply[];
  onCheckedChange: (indexes: number[], checked: boolean) => void;
  renderCheckbox: boolean;
};

export const useQuickReplyTable = (params: Props): Table<SelectableRoleQuickReply> => {
  const { t } = useTranslation();

  const { data, onCheckedChange } = params;

  const columnHelper = createColumnHelper<SelectableRoleQuickReply>();

  return useReactTable({
    columns: [
      columnHelper.accessor('value', {
        cell: info => (
          <FlexComponent flexDirection="row">
            {params.renderCheckbox ? (
              <Checkbox
                checked={info.row.original.selected}
                data-test="select-checkbox"
                name="quickReply"
                onChange={checked => onCheckedChange([info.row.index], checked)}
              />
            ) : null}
            <Text as="span" color={COLORS.zulu.$06} size="MD" width="partial-width">
              {info.getValue()}
            </Text>
          </FlexComponent>
        ),
        header: () => (
          <FlexComponent backgroundColor="transparent" flexDirection="row">
            {params.renderCheckbox ? (
              <Checkbox
                checked={data.every(({ selected }) => selected)}
                data-test="selectAll-checkbox"
                name="quickReplies"
                onChange={checked => {
                  const allIndexes = data.map((_, index) => index);
                  onCheckedChange(allIndexes, checked);
                }}
              />
            ) : null}
            <Text as="span" color={COLORS.zulu.$08} size="XS" uppercase weight="bold" width="partial-width">
              {t(data.length > 1 ? 'QUICK_REPLIES.MULTI' : 'QUICK_REPLIES.SINGLE')}
            </Text>
          </FlexComponent>
        ),
        id: 'KEYWORDS',
        size: 10,
      }),
      columnHelper.accessor('description', {
        cell: info => (
          <Text as="span" color={COLORS.zulu.$06} size="MD" width="partial-width">
            {info.getValue()}
          </Text>
        ),
        id: 'Description',
      }),
    ],
    data,
    getCoreRowModel: getCoreRowModel(),
  });
};
