import * as React from "react";
const securityLockOutline: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5 1.563a4.687 4.687 0 0 1 4.688 4.687v3.905l.78.001c1.241 0 2.257.964 2.34 2.184l.005.16v8.594a2.344 2.344 0 0 1-2.344 2.343H7.03a2.344 2.344 0 0 1-2.343-2.343V12.5a2.344 2.344 0 0 1 2.343-2.344h.782V6.25A4.688 4.688 0 0 1 12.5 1.562zm5.469 10.156H7.03a.781.781 0 0 0-.781.781v8.594c0 .431.35.781.781.781H17.97c.431 0 .781-.35.781-.781V12.5a.781.781 0 0 0-.781-.781zM12.5 14.063a1.563 1.563 0 0 1 .755 2.93.712.712 0 0 1 .026.195v1.562a.781.781 0 1 1-1.562 0v-1.563c0-.067.008-.133.025-.195a1.563 1.563 0 0 1 .756-2.93zm0-10.938A3.125 3.125 0 0 0 9.375 6.25v3.905h6.25V6.25c0-1.726-1.4-3.125-3.125-3.125z"
      fill="#144761"
      fillRule="evenodd"
    />
  </svg>
);
export default securityLockOutline;
