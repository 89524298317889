import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom';
import { useAppInitializer } from '@aviobook/_hooks/useAppInitializer';
import { Flightrooms } from '@aviobook/flightrooms/Flightrooms';
import { LicenseAgreement } from '@aviobook/license-agreement/LicenseAgreement';
import { ModalOpener } from '@aviobook/modal/ModalOpener';
import OneButtonModal from '@aviobook/modal/oneButtonModal/OneButtonModal';
import { useQueryClient } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import {
  areArraysEqual,
  COOKIE_ACCESS_TOKEN_NAME,
  NotificationsContextProvider,
  useAuthenticate,
  useAuthenticationContext,
  useFlightroomsContext,
  useSocket,
  useSocketEvent,
} from 'shared';
import { AuthenticatedUser, FlightRoomPermissions, QueryKeys } from 'types';

import { DashboardLayout } from './_routing/dashboard-layout/DashboardLayout';
import { Spinner } from './_shared';
import { OpenWebNotification } from './open-web-notification';

import './index.scss';

export const AppWeb = () => {
  document.title = 'Connect Web';
  const { t } = useTranslation();

  const {
    isLoading: authenticationIsLoading,
    setAccessToken,
    setLoading: setAuthenticationIsLoading,
    setUser,
    user: authenticatedUser,
  } = useAuthenticationContext();
  const { initializeSocket } = useSocket();

  const { isLoading: isFetchingAuthentication } = useAuthenticate(false, {
    onSuccess: user => {
      const accessToken = Cookies.get(COOKIE_ACCESS_TOKEN_NAME);
      initializeSocket(user.account);
      setUser(user);
      setAccessToken(accessToken);
    },
  });

  useEffect(() => {
    setAuthenticationIsLoading(isFetchingAuthentication);
  }, [isFetchingAuthentication, setAuthenticationIsLoading]);

  if (authenticationIsLoading) {
    return <Spinner overlay>{t('SHARED.LOADING_APPLICATION')}</Spinner>;
  }

  if (authenticatedUser && !authenticatedUser.hasAcceptedLicenseAgreement) {
    return (
      <Routes>
        <Route element={<LicenseAgreement />} path="*" />
      </Routes>
    );
  }

  if (authenticatedUser) {
    return (
      <AuthenticatedRoutes>
        <Route element={<DashboardLayout />} path="dashboard/*">
          <Route element={<Flightrooms />} path="flightrooms" />
          <Route element={<Navigate to="flightrooms" />} path="*" />
        </Route>
        <Route element={<Navigate to="dashboard" />} path="*" />
        <Route element={<OpenWebNotification />} path="open-notification/:type/:payload" />
      </AuthenticatedRoutes>
    );
  }

  return <Routes></Routes>;
};

const AuthenticatedRoutes: FC<PropsWithChildren> = ({ children }) => {
  useAppInitializer();

  const { t } = useTranslation();
  const { setUser, user } = useAuthenticationContext();
  const { socket } = useSocket();
  const { resetFlightrooms } = useFlightroomsContext();
  const queryClient = useQueryClient();
  const [isPermissionAlertOpen, setIsPermissionAlertOpen] = useState(false);

  const [, setSearchParams] = useSearchParams();

  const handleAuthUpdate = (updatedUser: AuthenticatedUser) => {
    setUser(updatedUser);

    const permissionsDidChange = !areArraysEqual(user.permissions, updatedUser.permissions, true);
    if (!permissionsDidChange) {
      return;
    }

    const hasViewPermission = updatedUser.permissions.includes(FlightRoomPermissions.VIEW);
    if (!hasViewPermission) {
      setSearchParams();
      resetFlightrooms();
      queryClient.invalidateQueries([QueryKeys.FLIGHTROOMS]);
    }

    if (!isPermissionAlertOpen) {
      ModalOpener.instance.open({
        render: () => (
          <OneButtonModal
            confirmText={t('SHARED.BUTTONS.OK')}
            content={t('PERMISSIONS.PERMISSIONS_UPDATED.CONTENT')}
            onConfirm={() => {
              setIsPermissionAlertOpen(false);
              ModalOpener.instance.close();
            }}
            title={t('PERMISSIONS.PERMISSIONS_UPDATED.TITLE')}
          />
        ),
      });
      setIsPermissionAlertOpen(true);
    }
  };

  useSocketEvent(socket, 'auth.update', handleAuthUpdate);

  return (
    <NotificationsContextProvider>
      <Routes>{children}</Routes>
    </NotificationsContextProvider>
  );
};
