import * as React from "react";
const roleMaintenanceGogglesFilled: React.FC<
  React.SVGProps<SVGSVGElement>
> = () => (
  <svg viewBox="0 0 25 25" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Icons/Finals/Aviation/Roles/role-maintenance-goggles-filled</title>
    <g
      id="Icons/Finals/Aviation/Roles/role-maintenance-goggles-filled"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M12.5,1.734375 C13.6374596,1.734375 14.5856676,2.54465748 14.7988977,3.61949622 C18.0466577,4.67428093 20.3125,7.56334541 20.3125,10.8909679 L20.3125,12.671875 L21.09375,12.671875 C21.5252225,12.671875 21.875,13.0216525 21.875,13.453125 C21.875,13.8845975 21.5252225,14.234375 21.09375,14.234375 L3.90625,14.234375 C3.47477754,14.234375 3.125,13.8845975 3.125,13.453125 C3.125,13.0216525 3.47477754,12.671875 3.90625,12.671875 L4.6875,12.671875 L4.6875,10.8909679 C4.6875,7.54898984 6.96885012,4.6691718 10.2017261,3.61949188 C10.414794,2.54426314 11.3628172,1.734375 12.5,1.734375 Z M8.59375,7.984375 C8.16227754,7.984375 7.8125,8.33415254 7.8125,8.765625 L7.8125,11.890625 C7.8125,12.3220975 8.16227754,12.671875 8.59375,12.671875 C9.02522246,12.671875 9.375,12.3220975 9.375,11.890625 L9.375,8.765625 C9.375,8.33415254 9.02522246,7.984375 8.59375,7.984375 Z M16.40625,7.984375 C15.9747775,7.984375 15.625,8.33415254 15.625,8.765625 L15.625,11.890625 C15.625,12.3220975 15.9747775,12.671875 16.40625,12.671875 C16.8377225,12.671875 17.1875,12.3220975 17.1875,11.890625 L17.1875,8.765625 C17.1875,8.33415254 16.8377225,7.984375 16.40625,7.984375 Z M12.5,3.296875 C12.0685275,3.296875 11.71875,3.64665254 11.71875,4.078125 L11.71875,8.765625 C11.71875,9.19709746 12.0685275,9.546875 12.5,9.546875 C12.9314725,9.546875 13.28125,9.19709746 13.28125,8.765625 L13.28125,4.078125 C13.28125,3.64665254 12.9314725,3.296875 12.5,3.296875 Z M11.328125,17.078125 L11.328125,19.421875 C11.328125,20.7779672 10.6815763,21.765625 9.375,21.765625 L6.640625,21.765625 C5.34620762,21.765625 4.296875,20.7162924 4.296875,19.421875 L4.296875,17.859375 C4.296875,16.5649576 5.34620762,15.515625 6.640625,15.515625 L18.359375,15.515625 C19.6537924,15.515625 20.703125,16.5649576 20.703125,17.859375 L20.703125,19.421875 C20.703125,20.7162924 19.6537924,21.765625 18.359375,21.765625 L15.625,21.765625 C14.3184237,21.765625 13.671875,20.7779672 13.671875,19.421875 L13.671875,17.078125 L11.328125,17.078125 Z"
        id="Shape"
        fill="#0F384D"
      ></path>
    </g>
  </svg>
);
export default roleMaintenanceGogglesFilled;
