import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Response } from '@aviobook/_hooks/useForm';
import { InputField } from '@aviobook/_shared';
import { PageContentBlock, SaveButton } from '@aviobook/cms/_shared';
import { Role } from 'types';

import './roleForm.scss';

type Props = {
  form: Response<Role, Role>;
};

export const RoleForm: FC<Props> = ({ form }) => {
  const { t } = useTranslation();
  // const [tooltipAnchor, setTooltipAnchor] = useState<ReferenceType | null>(null);

  return (
    <>
      {/*<Tooltip alignment="top-start" anchor={tooltipAnchor}>*/}
      {/*  <slot name="content">*/}
      {/*    <>{t('USERS.TAG.TAGGABLE')}</>*/}
      {/*  </slot>*/}
      {/*</Tooltip>*/}
      <form onSubmit={form.submit}>
        <div className="role-form-top-container">
          <SaveButton isDisabled={!form.isDirty} />
        </div>
        <PageContentBlock title={t('ROLESFORM.TITLE.DETAILS')}>
          <div className="row">
            <div className="col-1">
              <InputField
                disabled
                label={t('ROLESFORM.LABEL.NAME')}
                name="airlineRole"
                onChange={form.setAttribute}
                value={form.values?.airlineRole || ''}
              />
            </div>
            {/* Commented for ticket https://aviobook.atlassian.net/browse/AVIO-39916 */}
            {/* TODO: uncomment when we want to implement this for v1.1 (ticket to be created) */}
            {/*<div className="col-2 role-form-checkbox-airline-role">*/}
            {/*  <Checkbox*/}
            {/*    checked={form.values?.isTaggable}*/}
            {/*    className="set-label-style"*/}
            {/*    label={t('ROLESFORM.CHECKBOX.TEXT')}*/}
            {/*    name="airlineRole"*/}
            {/*    onChange={value => form.setAttribute(value, 'isTaggable')}*/}
            {/*  />*/}
            {/*  <Icon*/}
            {/*    className="ml-1"*/}
            {/*    colorName={COLORS.zulu.$08}*/}
            {/*    name="circularInfoOutline"*/}
            {/*    onHover={anchor => {*/}
            {/*      setTooltipAnchor(anchor.currentTarget);*/}

            {/*      return () => setTooltipAnchor(null);*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</div>*/}
          </div>
        </PageContentBlock>
      </form>
    </>
  );
};
