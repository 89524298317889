import { DeviceType, OsType } from '../../_types/DeviceType';

const IOS_AGENT = /iphone|ipad|ipod/;
const ANDROID_AGENT = /android/;

const MOBILE_AGENTS = /iphone|ipad|ipod|android|blackberry|windows phone/;
const TABLET_AGENTS = /(ipad|tablet|playbook|silk)|(android(?!.*mobile))/;

const getUserAgent = () => window.navigator.userAgent.toLowerCase();

export const detectDevice = () => ({ deviceOS: getOsType(), deviceType: getDeviceType() });

const getDeviceType = () => {
  let deviceType = DeviceType.DESKTOP;

  if (MOBILE_AGENTS.test(getUserAgent()) && hasTouchSupport()) {
    deviceType = DeviceType.MOBILE;
  }

  if (TABLET_AGENTS.test(getUserAgent()) && hasTouchSupport()) {
    deviceType = DeviceType.TABLET;
  }

  return deviceType;
};

const getOsType = () => {
  let OSType: OsType = undefined;

  if (IOS_AGENT.test(getUserAgent())) {
    OSType = OsType.IOS;
  }

  if (ANDROID_AGENT.test(getUserAgent())) {
    OSType = OsType.ANDROID;
  }

  return OSType;
};

const hasTouchSupport = () => 'ontouchstart' in window || window.navigator.maxTouchPoints > 0;
