import * as React from "react";
const magnifyingSearchFilled: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.938 1.563a9.375 9.375 0 0 1 9.374 9.374c0 2.291-.821 4.39-2.186 6.019l5.197 5.195a.781.781 0 0 1-1.037 1.166l-.068-.06-5.19-5.192a9.338 9.338 0 0 1-6.09 2.247 9.375 9.375 0 0 1 0-18.75zm0 1.562a7.812 7.812 0 1 0 0 15.625 7.812 7.812 0 0 0 0-15.625z"
      fill="#144761"
      fillRule="evenodd"
    />
  </svg>
);
export default magnifyingSearchFilled;
