import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from '@aviobook/_hooks';
import { FormValidationErrors } from '@aviobook/_hooks/useForm';
import { InputField } from '@aviobook/_shared';
import { generateUuid } from '@aviobook/_utils/uuid/generateUuid';
import { ModalForm } from '@aviobook/cms/connect/_screens/roles/_components';
import { FormValidator } from 'shared';
import { QuickReply } from 'types';

type Props = {
  closeModal: () => void;
  isLoading: boolean;
  onSubmit: (values: QuickReply) => void;
};

const initialForm: QuickReply = {
  description: '',
  id: generateUuid(),
  text: '',
};

const validateForm = (values: QuickReply): FormValidationErrors<QuickReply> => {
  const validator = new FormValidator();

  return {
    description: validator.required(values.description),
    text: validator.required(values.text),
  };
};

export const AddQuickReplyModal: FC<Props> = ({ closeModal, isLoading, onSubmit }) => {
  const { t } = useTranslation();

  const form = useForm<QuickReply>({
    initialForm,
    submitForm: onSubmit,
    validateForm,
  });

  return (
    <ModalForm headerTitle={t('QUICK_REPLIES.ACTIONS.ADD')} isSubmitting={isLoading} onCancel={closeModal} onSubmit={form.submit}>
      <InputField
        label={t('QUICK_REPLIES.FORM.TEXT')}
        name="text"
        onChange={form.setAttribute}
        required
        showInlineBottomError
        validation={form.validationErrors?.text}
        value={form.values?.text}
      />
      <InputField
        label={t('QUICK_REPLIES.FORM.DESCRIPTION')}
        name="description"
        onChange={form.setAttribute}
        required
        showInlineBottomError
        validation={form.validationErrors?.description}
        value={form.values?.description}
      />
    </ModalForm>
  );
};
