import * as React from "react";
const chevronUp: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.683 16.235a1.003 1.003 0 0 1-1.413-.055.992.992 0 0 1 .055-1.407l6.527-6.008a1.003 1.003 0 0 1 1.36.003l6.47 6.008a.992.992 0 0 1 .05 1.408 1.003 1.003 0 0 1-1.414.048l-5.79-5.377-5.845 5.38z"
      fillRule="evenodd"
    />
  </svg>
);
export default chevronUp;
