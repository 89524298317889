import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetRole, useUpdateRole } from '@aviobook/cms/connect/_screens/roles/_queries';
import { RoleQuickReply } from 'types';

import { SelectableRoleQuickReply } from '../selectableRoleQuickReply';
import { useQuickReplyTable } from './useQuickReplyTable';

type Props = {
  renderCheckbox: boolean;
};

const mapRoleQuickRepliesToSelectableRoleQuickReplies = (input: RoleQuickReply, index: number): SelectableRoleQuickReply => ({
  ...input,
  index,
  selected: false,
});

const mapSelectableRoleQuickReplyToRoleQuickReply = (input: SelectableRoleQuickReply): RoleQuickReply => {
  const { description, value } = input;

  return {
    description,
    value,
  };
};

export const useRoleQuickReplyContentTable = ({ renderCheckbox }: Props) => {
  const { roleId } = useParams();

  const { data, isLoading: isFetchLoading, refetch } = useGetRole(roleId);
  const { isLoading: isMutateLoading, mutate } = useUpdateRole(data => {
    setState(data.quickReplies.map(mapRoleQuickRepliesToSelectableRoleQuickReplies));
  });

  const [state, setState] = useState<SelectableRoleQuickReply[]>(
    data.quickReplies.map(mapRoleQuickRepliesToSelectableRoleQuickReplies),
  );

  const toggle = (indexes: number[], checked: boolean) => {
    const newState = state.map((item, index) => ({
      ...item,
      selected: indexes.includes(index) ? checked : item.selected,
    }));

    setState(newState);
  };

  const table = useQuickReplyTable({ data: state, onCheckedChange: toggle, renderCheckbox });

  return {
    create: async (quickReply: RoleQuickReply) => {
      mutate({
        ...data,
        quickReplies: [...data.quickReplies, quickReply],
      });

      await refetch();
    },
    isLoading: isFetchLoading || isMutateLoading,
    remove: async () => {
      if (!state.some(({ selected }) => selected)) {
        return;
      }

      const newState = state.filter(({ selected }) => !selected);
      mutate({
        ...data,
        quickReplies: newState.map(mapSelectableRoleQuickReplyToRoleQuickReply),
      });

      await refetch();
    },
    selectedItems: state.filter(({ selected }) => selected),
    table,
  };
};
