import { NestedObjectsPathKeys, Nullable, TypeFromNestedObjectsPathKeys } from 'types';

import BaseFiqlNode from '../base-types/base-fiql-node.abstract.class';
import FiqlOperators from '../types/fiql-operators.enum';

export default class InFiqlNode<T extends object> extends BaseFiqlNode<FiqlOperators.IN, T> {
  protected operator: FiqlOperators.IN = FiqlOperators.IN;

  protected override transform(
    arg: Nullable<Required<TypeFromNestedObjectsPathKeys<T, NestedObjectsPathKeys<Required<T>>>>>[],
  ): string {
    return `${arg.map(segment => `'${segment}'`)}`;
  }
}
