import { FC } from 'react';

import { Button } from '../../_shared';
import Modal from '../Modal';
import { ModalOpener } from '../ModalOpener';

type Props = {
  confirmText: string;
  content: string;
  isLoading?: boolean;
  onConfirm: () => void;
  title: string;
};

const OneButtonModal: FC<Props> = ({ confirmText, content, isLoading, onConfirm, title }) => {
  const onClickCancel = () => {
    if (!isLoading) {
      ModalOpener?.instance.close();
    }
  };

  return (
    <Modal onCancel={onClickCancel}>
      <Modal.Header onCancel={onClickCancel}>{title}</Modal.Header>
      <Modal.Content>
        <p>{content}</p>
      </Modal.Content>
      <Modal.Buttons>
        <Button
          loading={isLoading}
          onClick={() => {
            onConfirm?.();
            ModalOpener?.instance.close();
          }}
          theme="negative"
        >
          {confirmText}
        </Button>
      </Modal.Buttons>
    </Modal>
  );
};

export default Object.assign(OneButtonModal, {
  render: (props: Props) => {
    return <OneButtonModal {...props} />;
  },
});
