import { useEffect, useRef } from 'react';
import { Socket } from 'socket.io-client';
import { IncomingSocketEvents, OutgoingSocketEvents } from 'types';

export const useSocketEvent = <T extends (...args: never[]) => void>(
  socket: Socket<IncomingSocketEvents, OutgoingSocketEvents> | null,
  event: keyof IncomingSocketEvents,
  callback: T,
) => {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!socket) {
      return;
    }
    const handler = (...args: Parameters<T>) => callbackRef.current(...args);
    socket.on(event, handler);

    return () => {
      socket.off(event, handler);
    };
  }, [socket, event]);
};
