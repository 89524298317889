import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { Spinner } from '@aviobook/_shared';
import { AddButton, EmptyState, TableLayout } from '@aviobook/cms/_shared';
import { ConfirmationModal } from '@aviobook/cms/_shared/confirmationModal/ConfirmationModal';
import { customModalStyles } from '@aviobook/cms/_shared/modal/ModalUtils';
import { PermissionsUtil, useAuthenticationContext } from 'shared';
import { QuickRepliesPermissions } from 'types';

import { useSmartActionQuickRepliesContentTable } from './_hooks/useQuickRepliesContentTable';
import { AddQuickReplyModal } from './AddQuickReplyModal';

import './quickReplies.scss';

export const SmartActionQuickReplies: FC = () => {
  const [isOpenAddModal, setOpenAddModal] = useState(false);

  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);

  const { t } = useTranslation();

  const { user } = useAuthenticationContext();

  const permissions = new PermissionsUtil(user);

  const {
    createQuickReply,
    deleteQuickReplies,
    isLoading,
    isLoadingCreateQuickReply,
    isLoadingDeleteQuickReplies,
    itemsToDelete,
    table,
  } = useSmartActionQuickRepliesContentTable({ renderCheckbox: permissions.hasPermission(QuickRepliesPermissions.DELETE) });
  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div>
      <Modal
        isOpen={isOpenAddModal || isOpenDeleteModal}
        onRequestClose={() => (isOpenAddModal ? setOpenAddModal(false) : setOpenDeleteModal(false))}
        style={customModalStyles}
      >
        {isOpenAddModal ? (
          <AddQuickReplyModal
            closeModal={() => setOpenAddModal(false)}
            isLoading={isLoadingCreateQuickReply}
            onSubmit={values => {
              createQuickReply(values);
              setOpenAddModal(false);
            }}
          />
        ) : null}
        {isOpenDeleteModal ? (
          <ConfirmationModal
            cancelButtonAction={() => setOpenDeleteModal(false)}
            cancelButtonLabel={'CANCEL'}
            confirmButtonAction={async () => {
              await deleteQuickReplies();
              setOpenDeleteModal(false);
            }}
            confirmButtonLabel={'Delete'}
            loading={isLoadingDeleteQuickReplies}
            title={`Delete quick ${itemsToDelete?.length > 1 ? 'replies' : 'reply'}?`}
          />
        ) : null}
      </Modal>

      <TableLayout
        addButtonAction={() => setOpenAddModal(true)}
        addButtonLabel={t('QUICK_REPLIES.ACTIONS.ADD')}
        deleteButtonAction={() => setOpenDeleteModal(true)}
        deleteButtonDisabled={itemsToDelete?.length <= 0}
        emptyState={() => (
          <EmptyState
            button={() =>
              permissions.hasPermission(QuickRepliesPermissions.ADD) ? (
                <AddButton
                  label={t('QUICK_REPLIES.ACTIONS.ADD')}
                  onClick={() => {
                    setOpenAddModal(true);
                  }}
                />
              ) : null
            }
            description={t('QUICK_REPLIES.EMPTYSTATE.DESCRIPTION')}
            title={t('QUICK_REPLIES.EMPTYSTATE.TITLE')}
          />
        )}
        isAddButtonPresent={permissions.hasPermission(QuickRepliesPermissions.ADD)}
        isDeleteButtonPresent={permissions.hasPermission(QuickRepliesPermissions.DELETE)}
        table={table}
      />
    </div>
  );
};
