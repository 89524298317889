import { RequestOptionsV1 } from 'types';

export type RequestOptionsRecords = Partial<Record<'fields' | 'limit' | 'offset' | 'query' | 'sort', string>>;

export const getQueryParams = (query?: Record<string, unknown>): string => {
  if (query) {
    const search = new URLSearchParams();

    for (const [key, value] of Object.entries(query)) {
      if (Array.isArray(value)) {
        value.forEach(singleValue => {
          search.append(key, String(singleValue));
        });
      } else {
        search.append(key, String(value));
      }
    }

    return '?' + search.toString();
  }

  return '';
};

export const getUrlWithQueryParams = (url: string, query?: Record<string, unknown>): string => {
  return `${url}${getQueryParams(query)}`;
};

export const parseRequestOptionsV1ToUrlParams = <T extends Record<string, unknown>>(options?: RequestOptionsV1<T>) => {
  const params: RequestOptionsRecords = {};

  if (options?.fields) {
    params.fields = options.fields.join(',');
  }

  if (options?.limit) {
    params.limit = String(options.limit);
  }

  if (options?.offset) {
    params.offset = String(options.offset);
  }

  if (options?.query) {
    params.query = options.query.parse();
  }
  if (options?.queryString) {
    params.query = options.queryString;
  }

  if (options?.sort) {
    params.sort = options.sort.map(sorter => sorter.parse()).join(',');
  }

  return params;
};
