import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Spinner, Text } from '@aviobook/_shared';
import { AddButton, TableLayout } from '@aviobook/cms/_shared';
import { EmptyState } from '@aviobook/cms/_shared/emptyState/EmptyState';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { COLORS } from 'styles';
import { Role } from 'types';

import { useGetRoles } from './_queries';

import './roles.scss';

export const Roles: FC = () => {
  const { t } = useTranslation();
  const { data: roles, isLoading } = useGetRoles();

  const columnHelper = createColumnHelper<Role>();

  const columns = [
    columnHelper.accessor('airlineRole', {
      cell: info => (
        <Link to={`/admin/connect/roles/${info.row.original.id}`}>
          <Text as="span" color={COLORS.zulu.$06} size="MD" width="partial-width">
            {info.getValue()}
          </Text>
        </Link>
      ),
      header: t('ROLE_TAGS_COLUMN_NAME'),
      id: 'airlineRole',
    }),
  ];

  // Provide a default empty array if roles is undefined and filter out any undefined airlineRoles
  const filteredAndSortedRoles = roles?.sort((a, b) => a.airlineRole.localeCompare(b.airlineRole));

  const table = useReactTable({
    columns,
    data: filteredAndSortedRoles,
    getCoreRowModel: getCoreRowModel(),
  });

  if (isLoading || !roles) {
    return <Spinner>{t('SHARED.LOADING_APPLICATION')}</Spinner>;
  }

  return (
    <main className="roles-container" data-test="roles-screen">
      <TableLayout
        emptyState={() => (
          <EmptyState
            button={() => <AddButton label={t('ROLES_ADDBUTTON')} onClick={() => {}} />}
            description={t('ROLES_EMPTYSTATE_DESCRIPTION')}
            title={t('ROLES_EMPTYSTATE_TITLE')}
          />
        )}
        table={table}
      />
    </main>
  );
};
