import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useRequestAccess } from 'shared';
import { QueryKeys } from 'types';

import { Button } from '../../_shared';
import Modal from '../Modal';
import { ModalOpener } from '../ModalOpener';

type Props = {
  close: () => void;
  content: string;
  isVisible?: boolean;
  roomId: string;
  searchQuery: string;
  title: string;
};

export const RequestFlightroomAccessModal: FC<Props> = ({ close, content, roomId, title }) => {
  const queryClient = useQueryClient();
  const [, setSearchParams] = useSearchParams();

  const { isLoading, mutateAsync: requestAccess } = useRequestAccess({
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.FLIGHTROOMS]);
    },
  });
  const { t } = useTranslation();

  const onRequestAccess = async () => {
    await requestAccess(roomId, {
      onSuccess: () => {
        setSearchParams();
        close();
      },
    });
  };

  const onCancel = () => {
    if (!isLoading) {
      ModalOpener.instance.close();
    }
  };

  return (
    <Modal onCancel={onCancel}>
      <Modal.Header onCancel={onCancel}>{title}</Modal.Header>
      <Modal.Content>{content}</Modal.Content>
      <Modal.Buttons>
        <Button isOutline onClick={onCancel} theme="dark">
          {t('SHARED.BUTTONS.CANCEL')}
        </Button>
        <Button loading={isLoading} onClick={onRequestAccess} theme="primary">
          {t('FLIGHTROOMS.REQUEST_ACCESS.REQUEST')}
        </Button>
      </Modal.Buttons>
    </Modal>
  );
};
