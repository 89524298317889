import * as React from "react";
const notificationBell: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5 1.563a2.344 2.344 0 0 1 2.3 2.796 6.34 6.34 0 0 1 3.936 5.884v5.096l2.33 2.298a1.562 1.562 0 0 1-1.096 2.675h-4.345a3.125 3.125 0 0 1-6.25 0H5.007A1.563 1.563 0 0 1 3.9 17.648l2.296-2.304v-5.1c0-2.69 1.659-4.99 4-5.91A2.344 2.344 0 0 1 12.5 1.563zm1.563 18.75h-3.126a1.563 1.563 0 0 0 3.126 0zM12.466 5.469c-2.598 0-4.707 2.135-4.707 4.774v5.1c0 .414-.164.81-.456 1.103L5.007 18.75H19.97l-2.331-2.298a1.563 1.563 0 0 1-.466-1.113v-5.096c0-2.639-2.11-4.774-4.707-4.774zm.034-2.344a.781.781 0 0 0-.781.781l.002.044a6.27 6.27 0 0 1 1.557.009l.003-.053a.781.781 0 0 0-.781-.781z"
      fill="#144761"
      fillRule="evenodd"
    />
  </svg>
);
export default notificationBell;
