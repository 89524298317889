import * as React from "react";
const checked: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 1H3C1.89543 1 1 1.89543 1 3V17C1 18.1046 1.89543 19 3 19H17C18.1046 19 19 18.1046 19 17V3C19 1.89543 18.1046 1 17 1ZM3 0C1.34315 0 0 1.34315 0 3V17C0 18.6569 1.34315 20 3 20H17C18.6569 20 20 18.6569 20 17V3C20 1.34315 18.6569 0 17 0H3Z"
      fill="#002548"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.7469 4.42954C17.3327 5.01533 17.3327 5.96508 16.7469 6.55086L8.9038 14.394C8.62249 14.6753 8.24096 14.8333 7.84314 14.8333C7.44531 14.8333 7.06378 14.6753 6.78248 14.394L2.86091 10.4724C2.27512 9.88665 2.27512 8.9369 2.86091 8.35111C3.4467 7.76533 4.39644 7.76533 4.98223 8.35111L7.84314 11.212L14.6256 4.42954C15.2114 3.84376 16.1612 3.84376 16.7469 4.42954Z"
      fill="#002548"
    />
  </svg>
);
export default checked;
