export const victor = {
  $01: '#f7f8f8',
  $02: '#f5f7f8',
  $03: '#f2f5f7',
  $04: '#eef4f7',
  $05: '#e6eff4',
  $06: '#e0ebf1',
  $07: '#dde9f0',
  $09: '#c6d7e1'
} as const;
