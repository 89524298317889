import { FlightRoom } from 'types';

import { isRoomCompleted, sortFlightroomsByStd } from '../../utils';
import { FlightRoomAction, FlightRoomActionType } from './FlightRooms.actions';

type FlightroomMap = Map<FlightRoom['externalId'], FlightRoom>;

type State = {
  activeFlightrooms: FlightroomMap;
  completedFlightrooms: FlightroomMap;
};
export const flightRoomReducer = (state: State, action: FlightRoomAction) => {
  let activeFlightrooms: Map<string, FlightRoom>;
  let completedFlightrooms: Map<string, FlightRoom>;
  let existingRoom: FlightRoom | undefined;

  switch (action.type) {
    case FlightRoomActionType.ADD_FLIGHTROOM:
    case FlightRoomActionType.UPDATE_FLIGHTROOM:
      activeFlightrooms = new Map(state.activeFlightrooms);
      completedFlightrooms = new Map(state.completedFlightrooms);
      // socket updates do not have unreadMessagesCount and hasUnreadMentions, so we need to take it from previous state
      existingRoom = activeFlightrooms.get(action.payload.externalId) || completedFlightrooms.get(action.payload.externalId);
      if (existingRoom) {
        action.payload = { ...existingRoom, ...action.payload };
      }
      if (isRoomCompleted(action.payload)) {
        completedFlightrooms.set(action.payload.externalId, action.payload);
        completedFlightrooms = new Map(Array.from(completedFlightrooms).sort((a, b) => sortFlightroomsByStd(a[1], b[1])));
        activeFlightrooms.delete(action.payload.externalId);
      } else {
        activeFlightrooms.set(action.payload.externalId, action.payload);
        activeFlightrooms = new Map(Array.from(activeFlightrooms).sort((a, b) => sortFlightroomsByStd(a[1], b[1])));
        completedFlightrooms.delete(action.payload.externalId);
      }
      return { activeFlightrooms, completedFlightrooms };

    case FlightRoomActionType.SET_ACTIVE_FLIGHTROOMS:
      activeFlightrooms = new Map();
      action.payload.forEach(room => activeFlightrooms.set(room.externalId, room));
      return { ...state, activeFlightrooms };

    case FlightRoomActionType.UPDATE_ACTIVE_FLIGHTROOMS:
      activeFlightrooms = new Map(state.activeFlightrooms);
      action.payload.forEach(room => activeFlightrooms.set(room.externalId, room));
      return { ...state, activeFlightrooms };

    case FlightRoomActionType.SET_COMPLETED_FLIGHTROOMS:
      completedFlightrooms = new Map();
      action.payload.forEach(room => completedFlightrooms.set(room.externalId, room));
      return { ...state, completedFlightrooms };

    case FlightRoomActionType.UPDATE_COMPLETED_FLIGHTROOMS:
      completedFlightrooms = new Map(state.completedFlightrooms);
      action.payload.forEach(room => completedFlightrooms.set(room.externalId, room));
      return { ...state, completedFlightrooms };
    case FlightRoomActionType.REMOVE_FLIGHTROOM:
      activeFlightrooms = new Map(state.activeFlightrooms);
      completedFlightrooms = new Map(state.completedFlightrooms);
      completedFlightrooms.delete(action.payload.externalId);
      activeFlightrooms.delete(action.payload.externalId);
      return { activeFlightrooms, completedFlightrooms };

    case FlightRoomActionType.UPDATE_FLIGHTROOM_MESSAGE_COUNT:
      // State is updated directly here because we dont want rerenders for this, this is needed when we receive flightroom updates
      existingRoom = state.activeFlightrooms.get(action.payload.roomId) || state.completedFlightrooms.get(action.payload.roomId);
      if (existingRoom) {
        const updatedRoom = { ...existingRoom, unreadMessagesCount: action.payload.count };
        if (state.activeFlightrooms.has(action.payload.roomId)) {
          state.activeFlightrooms.set(action.payload.roomId, updatedRoom);
        } else {
          state.completedFlightrooms.set(action.payload.roomId, updatedRoom);
        }
      }
      return { ...state };

    default:
      return state;
  }
};
