import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SideModal, Spinner, Text } from '@aviobook/_shared';
import { hasReachedScrollEnd } from '@aviobook/_utils/react/react.utils';
import { NotificationItem } from '@aviobook/notification-center/components/NotificationItem';
import { useNotificationsContext } from 'shared';

import './notificationCenter.scss';

type NotificationCenterProps = {
  isVisible: boolean;
  onClose: () => void;
};

export const NotificationCenter: FC<NotificationCenterProps> = ({ isVisible, onClose }) => {
  const { t } = useTranslation();
  const { error, fetchOlderNotifications, hasOlderNotifications, isFetchingNotifications, notifications } =
    useNotificationsContext();

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (hasReachedScrollEnd(e) && !isFetchingNotifications && hasOlderNotifications) {
      fetchOlderNotifications();
    }
  };

  const renderNotificationsList = () => {
    return (
      <section className="notification-center-content-container" onScroll={handleScroll}>
        {notifications.map(notification => (
          <NotificationItem key={notification.id} notification={notification} onClick={onClose} />
        ))}
      </section>
    );
  };

  const renderContent = () => {
    if (isFetchingNotifications && notifications.length === 0) {
      return (
        <div className="loading-indicator">
          <Spinner size="large" />
        </div>
      );
    }

    if (error) {
      return (
        <Text className="notification-center-text" size="MD">
          {t('ERRORS.GENERAL')}
        </Text>
      );
    }

    if (notifications.length === 0) {
      return (
        <Text className="notification-center-text" size="MD">
          {t('NOTIFICATION_CENTER.EMPTY_NOTIFICATIONS')}
        </Text>
      );
    }

    return renderNotificationsList();
  };

  return (
    <SideModal
      className="notification-center-side-modal"
      content={renderContent()}
      fullwidth
      header={{
        leftIcon: { iconName: 'arrowLeft', onClick: onClose },
        title: { props: { uppercase: false }, text: t('NOTIFICATION_CENTER.TITLE') },
      }}
      isVisible={isVisible}
      onBackgroundClick={onClose}
      placement="left"
    />
  );
};
