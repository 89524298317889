import { AttachmentResponseV1 } from 'types';

import { API_FACTORY, HttpClient } from '../utils';

export const uploadAttachment = async (file: File | { name: string; type: string; uri: string }) => {
  const formData = new FormData();

  formData.append('file', file as File);

  return HttpClient.post<AttachmentResponseV1>(API_FACTORY.v2('messages/attachments'), formData, {
    'Content-Type': 'multipart/form-data',
  });
};
