import * as React from "react";
const aircraftPlaneRightFilled: React.FC<
  React.SVGProps<SVGSVGElement>
> = () => (
  <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.746 17.302c1.553 0 2.813-.79 2.813-2.343 0-1.554-1.26-2.344-2.813-2.344h-7.648a.6.6 0 0 1-.499-.267l-6.028-9.043a1.2 1.2 0 0 0-.998-.534h-.764a.927.927 0 0 0-.853 1.293l3.307 7.715a.6.6 0 0 1-.552.836H7.64a1.2 1.2 0 0 1-1.05-.617L4.934 9.013a1.2 1.2 0 0 0-1.05-.617h-.841a.6.6 0 0 0-.577.765l1.562 5.468a1.2 1.2 0 0 1 0 .66l-1.562 5.467a.6.6 0 0 0 .577.765h.842a1.2 1.2 0 0 0 1.049-.617L6.59 17.92a1.2 1.2 0 0 1 1.049-.618h3.071a.6.6 0 0 1 .552.837l-3.307 7.715a.927.927 0 0 0 .853 1.292h.764c.4 0 .776-.2.998-.534L16.6 17.57a.6.6 0 0 1 .5-.268h7.647z"
      fill="#47AACF"
      fillRule="nonzero"
    />
  </svg>
);
export default aircraftPlaneRightFilled;
