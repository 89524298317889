import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { ResponseErrorCodesV1 } from 'types';

import { ErrorResponse } from './http.types';

export const axios = Axios.create();

export const requestInterceptor = (request: AxiosRequestConfig) => {
  // currently we don't need to modify request, added for future use
  return request;
};

export const errorInterceptor =
  (onError: (response: ErrorResponse) => void) => async (error: AxiosError<{ code: ResponseErrorCodesV1 }>) => {
    const errorResponse = error.response;
    if (errorResponse) {
      onError(errorResponse);
    }
    return Promise.reject(error);
  };
