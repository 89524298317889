import {
  ConnectPermission,
  FlightRoomPermissions,
  GeneralPermissions,
  QuickRepliesPermissions,
  RolesPermissions,
  SmartActionPermissions,
} from 'types';

const mapPermissionsToObjects = (
  input:
    | typeof FlightRoomPermissions
    | typeof SmartActionPermissions
    | typeof GeneralPermissions
    // | typeof ApiKeysPermissions
    | typeof RolesPermissions
    | typeof QuickRepliesPermissions,
) =>
  Object.entries(input).map(([label, permission]: [string, ConnectPermission]) => ({
    label: label.replaceAll('_', ' ').toLowerCase(),
    permission,
  }));

const PermissionGroups: {
  permissions: {
    label: string;
    permission: ConnectPermission;
  }[];
  title: string;
}[] = [
  {
    permissions: mapPermissionsToObjects(FlightRoomPermissions),
    title: 'flightrooms',
  },
  {
    permissions: mapPermissionsToObjects(SmartActionPermissions),
    title: 'smart actions',
  },
  // {
  //   permissions: mapPermissionsToObjects(GeneralPermissions),
  //   title: 'general',
  // },
  // {
  //   permissions: mapPermissionsToObjects(ApiKeysPermissions),
  //   title: 'api keys',
  // },
  {
    permissions: mapPermissionsToObjects(RolesPermissions),
    title: 'roles',
  },
  {
    permissions: mapPermissionsToObjects(QuickRepliesPermissions),
    title: 'quick replies',
  },
];

export const AllPermissions = PermissionGroups.map(({ permissions }) => permissions.map(({ permission }) => permission)).flat();

export default PermissionGroups;
