import * as React from "react";
const circularCheckmarkDisabled: React.FC<
  React.SVGProps<SVGSVGElement>
> = () => (
  <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12.5" cy="12.5" r="10.9375" fill="#B3C4CC" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.6526 12.163C6.95723 11.8575 7.45189 11.8567 7.75745 12.1613L10.7712 15.1663L17.9627 8.00145C18.2465 7.71867 18.6933 7.69925 18.9995 7.94265L19.0676 8.00348C19.3721 8.30914 19.3712 8.8038 19.0655 9.10833L11.5986 16.5479C11.1412 17.0036 10.4013 17.0035 9.94407 16.5476L6.65428 13.2679C6.34872 12.9632 6.34797 12.4686 6.6526 12.163Z"
      fill="white"
    />
  </svg>
);
export default circularCheckmarkDisabled;
