import { CSSProperties, FC, ReactNode } from 'react';
import { ColorName } from 'styles';

import './flexComponent.scss';

type Props = {
  alignItems?: 'center' | 'flex-start' | 'flex-end' | 'stretch' | 'baseline' | 'initial' | 'inherit';
  backgroundColor?: ColorName | 'transparent';
  children: ReactNode;
  flexDirection?: 'row' | 'column';
  gap?: number;
  padding?: number;
  style?: CSSProperties;
  width?: 'full' | 'auto';
};

export const FlexComponent: FC<Props> = ({
  alignItems,
  backgroundColor,
  children,
  flexDirection,
  gap,
  padding,
  style,
  width,
}) => {
  const wrapperStyle: CSSProperties = {
    alignItems: alignItems || 'stretch',
    backgroundColor: backgroundColor,
    flexDirection: flexDirection || 'row',
    gap: gap ? `${gap}rem` : '0',
    justifyContent: 'stretch',
    padding: padding ? `${padding}rem` : '0',
    width: width === 'full' ? '100%' : 'auto',
    ...style,
  };

  return (
    <div className="page-content-wrapper" style={wrapperStyle}>
      {children}
    </div>
  );
};
