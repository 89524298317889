import { useTranslation } from 'react-i18next';
import { toastError, toastSuccess } from '@aviobook/Toastify';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { HttpClient } from 'shared';
import { API_FACTORY } from 'shared/src/utils/factories/api.factory';
import { QueryKeys, QuickReply } from 'types';

const updateQuickReply = (values: QuickReply): Promise<QuickReply[]> =>
  HttpClient.post(API_FACTORY.v1('/admin/quick-replies'), { description: values.description, text: values.text });

export const useUpdateQuickReplies = () => {
  const queryClient = useQueryClient();

  const { t } = useTranslation();

  return useMutation([QueryKeys.QUICK_REPLIES], updateQuickReply, {
    onError: () => {
      toastError(t('TOAST_ERROR'));
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.QUICK_REPLIES]);
      toastSuccess(t('TOAST_SUCCESS'));
    },
  });
};
