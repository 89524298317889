import { createElement, FC } from 'react';
import clsx from 'clsx';
import { ColorName, FontSize, FontWeight } from 'styles';

import './text.scss';

export type TextProps = {
  as?: 'p' | 'span' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  children?: string | number | JSX.Element | JSX.Element[] | string[] | number[];
  className?: string;
  color?: ColorName;
  size?: FontSize;
  style?: 'normal' | 'italic';
  testID?: string;
  textDecoration?: 'underline';
  uppercase?: boolean;
  weight?: FontWeight;
  width?: string;
  wrap?: boolean;
};

export const Text: FC<TextProps> = ({
  as = 'p',
  children,
  className,
  color,
  size,
  style = 'normal',
  testID,
  textDecoration,
  uppercase,
  weight = 'regular',
  width = 'fullWidth',
  wrap,
}) => {
  const testProp = testID ? { 'data-test': testID } : null;
  return createElement(
    as,
    {
      ...testProp,
      className: clsx('text', size, weight, style, className, textDecoration, width, { uppercase, wrap }),
      style: { color: color },
    },
    children,
  );
};
