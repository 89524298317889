import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { useSetupEnvConfig } from '@aviobook/_hooks/useConfigureEnvConfig';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {
  AuthenticationContextProvider,
  EnvConfigContextProvider,
  FlightroomsContextProvider,
  SocketContextProvider,
  UsersContextProvider,
} from 'shared';

import { AppContextProvider, OfflineMessagesContextProvider } from './_context';
import { AxiosInterceptorProvider } from './_http/AxiosInterceptorProvider';
import { queryClient } from './_http/queryClient';
import { Spinner } from './_shared';
import { ProviderComposer } from './_utils/compose';
import { App } from './App';
import * as serviceWorker from './serviceWorker';

import 'translations';

const RootComponent: React.FC = () => {
  const { envConfig, error, loading } = useSetupEnvConfig();

  if (loading) {
    return <Spinner overlay />;
  }

  if (error || !envConfig) {
    return <div>Error: {error}</div>;
  }

  return (
    <EnvConfigContextProvider env={envConfig}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <AuthenticationContextProvider>
            <AxiosInterceptorProvider>
              <SocketContextProvider queryClient={queryClient}>
                <ProviderComposer
                  providers={[
                    AppContextProvider,
                    UsersContextProvider,
                    FlightroomsContextProvider,
                    OfflineMessagesContextProvider,
                  ]}
                >
                  <App />
                  <ReactQueryDevtools initialIsOpen={false} />
                </ProviderComposer>
              </SocketContextProvider>
            </AxiosInterceptorProvider>
          </AuthenticationContextProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </EnvConfigContextProvider>
  );
};

createRoot(document.getElementById('root')!).render(
  <Suspense fallback={<Spinner overlay />}>
    <RootComponent />
  </Suspense>,
);

serviceWorker.unregister();
