import { NestedObjectsPathKeys, Sorting, SortOptionsV1 } from 'types';

import { Sortable } from './sort';

export abstract class BaseSort<Type extends SortOptionsV1, T extends object> implements Sortable<T, Type> {
  private field: NestedObjectsPathKeys<Required<T>>;
  protected type: Type;

  constructor(field: NestedObjectsPathKeys<Required<T>>) {
    this.field = field;
  }

  parse(): Sorting<T, Type> {
    return `${this.field}:${this.type}`;
  }
}
