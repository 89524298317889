import * as React from "react";
const arrowLeft: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.732 4.721a.781.781 0 0 1 .065 1.031l-.065.074-5.696 5.697H22.46a.781.781 0 0 1 .091 1.558l-.091.005H4.034l5.698 5.698a.781.781 0 0 1 .065 1.03l-.065.074a.781.781 0 0 1-1.031.065l-.074-.065-7.031-7.03a.781.781 0 0 1-.065-1.032l.065-.074 7.031-7.031a.781.781 0 0 1 1.105 0z"
      fill="#7E9AA8"
      fillRule="evenodd"
    />
  </svg>
);
export default arrowLeft;
