import { AvioDateTime, DATE_TIME_FORMATS } from 'shared/src/utils';

export const formatDate = (date: Date, formatString = DATE_TIME_FORMATS.DATE_TIME) => AvioDateTime(date).formatUTC(formatString);

export const formatTime = (time: string | Date) => formatDate(new Date(time), DATE_TIME_FORMATS.TIME);

export const formatISOString = (isoString: string, formatString?: string) => formatDate(new Date(isoString), formatString);

export const dateFromString = (dateString: string, formatString = DATE_TIME_FORMATS.DATE_TIME) =>
  AvioDateTime().parse(dateString).format(formatString);

export const dateFromTime = (timeString: string) => dateFromString(timeString, DATE_TIME_FORMATS.TIME);

export const dateFromDateAndTime = (date: string, time: string) => dateFromString(`${date} ${time}`);

export const ISOStringFromDate = (date: Date) => AvioDateTime(date).toISOString();

export const isAfterDate = (date: Date, minDate: Date) => AvioDateTime(date).isAfter(minDate);

export const isBeforeDate = (date: Date, maxDate: Date) => AvioDateTime(date).isBefore(maxDate);
