import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Icon, Text } from '@aviobook/_shared';
import { toastError } from '@aviobook/Toastify';
import classNames from 'classnames';
import { mapNotificationItem, useFlightroomsContext, useNotificationsContext } from 'shared';
import { COLORS } from 'styles';
import { FlightRoom, NotificationType, NotificationV1 } from 'types';

import './notificationItem.scss';

type NotificationItemProps = {
  notification: NotificationV1;
  onClick: () => void;
};

export const NotificationItem: FC<NotificationItemProps> = ({ notification, onClick }) => {
  const { t } = useTranslation();

  const notificationItem = mapNotificationItem(notification);

  const { getRoomById } = useFlightroomsContext();
  const { removeNotification } = useNotificationsContext();
  const [, setSearchParams] = useSearchParams();

  const openRoom = (room: FlightRoom) => {
    setSearchParams({ room: room.externalId });
    onClick();
  };

  const handleRoomNotFound = () => {
    toastError(t('NOTIFICATION_CENTER.ERRORS.ROOM_NOT_FOUND.TITLE'));
    removeNotification(notification);
  };

  const onPressNotification = async () => {
    const room = await getRoomById(notification.room);

    if (room) {
      openRoom(room);
    } else {
      handleRoomNotFound();
    }
  };

  return (
    <div className={classNames('notification-center-item', !notification.isRead && 'unread')} onClick={onPressNotification}>
      <div className="notification-item-icon">
        {notification.type === NotificationType.MENTION ? (
          <MentionIcon outlined={notification.isRead} />
        ) : (
          <UnreadDot hide={notification.isRead} />
        )}
      </div>
      <div className="notification-item-text">
        <Text size="SM" weight={notification.isRead ? 'regular' : 'bold'}>
          {notificationItem.displayText}
        </Text>
        <Text size="XS">{notificationItem.timestamp}</Text>
      </div>
    </div>
  );
};

const UnreadDot: FC<{ hide: boolean }> = ({ hide }) => {
  return <div className={classNames('unread-dot', hide ? 'hide' : null)} />;
};

const MentionIcon: FC<{ outlined: boolean }> = ({ outlined }) => {
  return <Icon className="mention-icon" colorName={COLORS.romeo.$07} name={outlined ? 'mentionOutline' : 'mentionFilled'} />;
};
