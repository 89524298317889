import * as React from "react";
const mentionOutline: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.307 1.563c1.529 0 2.907.333 4.136 1 1.245.652 2.222 1.55 2.931 2.694.709 1.144 1.064 2.423 1.064 3.837 0 .954-.158 1.883-.473 2.789-.315.906-.772 1.645-1.371 2.217-.583.572-1.26.858-2.033.858-.551 0-1.008-.135-1.37-.406a1.963 1.963 0 0 1-.71-1.096c-.598.763-1.528 1.144-2.788 1.144-1.009 0-1.812-.246-2.411-.739a3.97 3.97 0 0 1-1.229-1.787c-.22-.7-.33-1.39-.33-2.074 0-.842.165-1.605.495-2.288a3.983 3.983 0 0 1 1.395-1.62c.614-.414 1.323-.62 2.127-.62.646 0 1.213.127 1.702.38.488.255.89.605 1.205 1.05l.071-.596c.047-.398.307-.596.78-.596.3 0 .512.08.638.238.142.159.19.358.142.596a34.695 34.695 0 0 0-.45 2.646l-.07.572c-.158 1.128-.236 1.954-.236 2.479 0 .46.07.81.212 1.048.142.223.386.334.733.334.52 0 .961-.223 1.323-.667.379-.461.654-1.033.828-1.717.173-.699.26-1.398.26-2.097 0-1.208-.268-2.28-.804-3.218a5.415 5.415 0 0 0-2.245-2.216c-.977-.54-2.112-.81-3.404-.81-1.402 0-2.662.317-3.781.953a6.725 6.725 0 0 0-2.6 2.598c-.614 1.096-.922 2.327-.922 3.694 0 1.366.308 2.574.922 3.623.63 1.049 1.513 1.867 2.647 2.455 1.15.588 2.482.882 3.994.882.505 0 .938-.032 1.3-.096.378-.063.977-.198 1.796-.405a.514.514 0 0 1 .497.12.681.681 0 0 1 .236.524.479.479 0 0 1-.165.381c-.095.096-.26.19-.497.286-.41.159-.93.286-1.56.381a12.01 12.01 0 0 1-1.843.143c-1.717 0-3.254-.365-4.609-1.096-1.339-.715-2.387-1.708-3.143-2.98-.756-1.27-1.135-2.693-1.135-4.266 0-1.589.387-3.035 1.159-4.338a8.376 8.376 0 0 1 3.143-3.074c1.34-.747 2.82-1.12 4.443-1.12zm2.01 7.27c.03-.335-.08-.66-.332-.978a2.632 2.632 0 0 0-.945-.81 2.425 2.425 0 0 0-1.205-.334c-.41 0-.82.119-1.23.357-.393.223-.724.58-.992 1.073-.252.492-.378 1.112-.378 1.859 0 .953.22 1.748.662 2.383.44.62 1.095.93 1.961.93.504 0 .93-.12 1.276-.357.347-.239.6-.596.757-1.073.142-.445.283-1.462.425-3.05z"
    />
  </svg>
);
export default mentionOutline;
