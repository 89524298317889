export const andes = {
  $01: '#fbf4bf',
  $02: '#faf1ac',
  $03: '#f8ec91',
  $04: '#f7e97f',
  $05: '#f6e462',
  $06: '#f4df47',
  $07: '#dbc840',
  $08: '#ab9c32',
  $09: '#92862b',
  $10: '#7a7024',
  $11: '#62591c',
} as const;
