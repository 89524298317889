export const romeo = {
  $01: '#f6fcfe',
  $02: '#eef9fd',
  $03: '#d3eef9',
  $04: '#a7def3',
  $05: '#61c4e9',
  $06: '#4fbde6',
  $07: '#47aacf',
  $08: '#43a1c4',
  $09: '#3b8eac',
  $10: '#2f718a',
  $11: '#204c5c',
} as const;
