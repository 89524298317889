import { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { COLORS } from 'styles';
import { MessageStatus } from 'types';

import { Icon, Spinner, Text } from '../../../../../_shared';

import './messageStatusWrapper.scss';

type Props = {
  children: React.ReactNode;
  isMe: boolean;
  messageStatus: MessageStatus;
  nextMessageBySameUser?: boolean;
  onClickDelete: () => void;
  onClickRetry: () => void;
};

export const MessageStatusWrapper: FC<Props> = ({
  children,
  isMe,
  messageStatus,
  nextMessageBySameUser,
  onClickDelete,
  onClickRetry,
}) => {
  const sharedClassnames = classNames('global-message-container', {
    'next-message-by-same-user': nextMessageBySameUser,
    'slide-right': isMe,
  });

  if (!isMe) {
    messageStatus = null;
  }

  switch (messageStatus) {
    case MessageStatus.PENDING:
      return <PendingMessage sharedClassnames={sharedClassnames}>{children}</PendingMessage>;
    case MessageStatus.FAILED:
      return (
        <FailedMessage onClickDelete={onClickDelete} onClickRetry={onClickRetry} sharedClassnames={sharedClassnames}>
          {children}
        </FailedMessage>
      );
    case MessageStatus.SENT:
    case MessageStatus.DELIVERED:
    case MessageStatus.READ:
    case null:
    case undefined:
    default:
      return <DefaultMessage sharedClassnames={sharedClassnames}>{children}</DefaultMessage>;
  }
};

const PendingMessage: FC<PropsWithChildren<{ sharedClassnames: string }>> = ({ children, sharedClassnames }) => (
  <div className={classNames('message-container', sharedClassnames)}>
    <div className="message-content-container">
      {children}
      <div className="pending-message-spinner">
        <Spinner size="small" theme="faded" />
      </div>
    </div>
  </div>
);

const FailedMessage: FC<PropsWithChildren<{ onClickDelete: () => void; onClickRetry: () => void; sharedClassnames: string }>> = ({
  children,
  onClickDelete,
  onClickRetry,
  sharedClassnames,
}) => {
  const { t } = useTranslation();
  return (
    <div className={classNames('message-container', sharedClassnames)}>
      <div className="message-content-container">
        {children}
        <Icon name="triangleCoreCautionFilled" size={2.5} />
      </div>
      <Text as="span" className="pending-message-retry-text" color={COLORS.fuji.$10} size="XS">
        <>
          {t('MESSAGE.SEND.FAILED.TEXT')}{' '}
          <button onClick={onClickRetry}>
            <Text as="span" color={COLORS.fuji.$10} size="XS" weight="bold">
              {t('SHARED.BUTTONS.RETRY')}
            </Text>
          </button>{' '}
          {t('GENERAL.OR')}{' '}
          <button onClick={onClickDelete}>
            <Text as="span" color={COLORS.fuji.$10} size="XS" weight="bold">
              {t('SHARED.BUTTONS.DELETE')}
            </Text>
          </button>
        </>
      </Text>
    </div>
  );
};

const DefaultMessage: FC<PropsWithChildren<{ sharedClassnames: string }>> = ({ children, sharedClassnames }) => (
  <div className={classNames('message-container', sharedClassnames)}>{children}</div>
);
