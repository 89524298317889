import { useTranslation } from 'react-i18next';
import { toastError, toastSuccess } from '@aviobook/Toastify';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { HttpClient } from 'shared';
import { API_FACTORY } from 'shared/src/utils/factories/api.factory';
import { QueryKeys } from 'types';

const deleteQuickReply = (id: string) => HttpClient.delete(API_FACTORY.v1(`admin/quick-replies/${id}`));

export const useDeleteQuickReplies = () => {
  const queryClient = useQueryClient();

  const { t } = useTranslation();

  const mutation = useMutation(['deleteSmartAction'], deleteQuickReply);

  const deleteSelectedQuickReplies = async (ids: string[]) => {
    const promises = ids.map(id => mutation.mutateAsync(id));
    try {
      return Promise.all(promises);
    } catch (e) {
      toastError(t('TOAST_ERROR'));
    } finally {
      queryClient.invalidateQueries([QueryKeys.SMART_ACTIONS]);
      toastSuccess(t('TOAST_SUCCESS'));
    }
  };

  return { ...mutation, deleteSelectedQuickReplies };
};
