import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../index';

type Props = {
  isDisabled?: boolean;
  label?: string;
  onClick?: () => void;
};

export const SaveButton: FC<Props> = ({ isDisabled, label, onClick }) => {
  const { t } = useTranslation();
  return (
    <Button data-test="save-button" disabled={isDisabled} onClick={onClick} theme="primary" type="submit">
      {label ? label : t('SHARED.BUTTONS.SAVE')}
    </Button>
  );
};
