import * as React from "react";
const phone: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg viewBox="0 0 25 26" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.375 2.844H4.687c-.712 0-1.3.196-1.724.62-.477.476-.614.945-.619 1.62v.102c0 .77.24 2.6.709 4.398.752 2.884 1.978 5.463 3.79 7.457 3.714 4.084 7.468 5.656 13.41 6.113.772.002 1.36-.194 1.784-.617.51-.51.62-.95.62-1.724v-4.688a.781.781 0 0 0-.492-.725l-3.906-1.563-.098-.032a.781.781 0 0 0-.745.205l-2.066 2.065-.157-.027c-1.645-.314-2.984-1.102-4.046-2.376l-.184-.225c-.951-1.193-1.466-2.27-1.569-3.22l-.003-.044 2.099-2.1a.781.781 0 0 0 .173-.842L10.1 3.335a.781.781 0 0 0-.725-.491zm-.529 1.562 1.174 2.936-1.979 1.98a.781.781 0 0 0-.229.553c0 1.51.726 3.106 2.135 4.797 1.43 1.716 3.303 2.725 5.581 3.01l.102.005a.781.781 0 0 0 .547-.228l1.98-1.98 2.937 1.175v4.159c0 .397-.02.477-.162.619-.098.098-.29.162-.62.162-5.51-.426-8.892-1.842-12.312-5.604-1.621-1.783-2.742-4.141-3.435-6.8l-.114-.456c-.343-1.439-.526-2.824-.543-3.453l-.002-.19c.003-.3.03-.39.162-.523.098-.098.29-.162.62-.162h4.158z"
      fill="#47AACF"
      fillRule="nonzero"
    />
  </svg>
);
export default phone;
