import { IconName } from 'assets';
import { JobTitleV1, RoomRoleTitle } from 'types';

export const getIconForOccupation = (input: RoomRoleTitle | JobTitleV1): IconName => {
  switch (input) {
    case RoomRoleTitle.COMM:
    case RoomRoleTitle.COPILOT:
    case JobTitleV1.PILOT:
      return 'rolePilotCapOutline';
    case RoomRoleTitle.SCCM:
    case RoomRoleTitle.CCM:
    case JobTitleV1.CABIN_CREW:
    case RoomRoleTitle.ACM:
      return 'seatbeltOutline';
    case RoomRoleTitle.GH:
    case JobTitleV1.DISPATCHER:
    case JobTitleV1.GROUND_HANDLER:
      return 'roleDispatcherHeadsetOutline';
    case JobTitleV1.MAINTENANCE_CREW:
      return 'roleMaintenanceGogglesOutline';
    case RoomRoleTitle.DEADHEAD:
    case RoomRoleTitle.UNKNOWN:
    case JobTitleV1.ADMINISTRATOR:
    case JobTitleV1.GUEST:
    case JobTitleV1.UNKNOWN:
    default:
      return 'roleDefault';
  }
};
