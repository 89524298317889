export const fuji = {
  $01: '#fce3cb',
  $02: '#fbd8b7',
  $03: '#f8bb83',
  $04: '#f7b06f',
  $05: '#f69f50',
  $06: '#f48e31',
  $07: '#e8872f',
  $08: '#d0792a',
  $09: '#b76a25',
  $10: '#92551d',
  $11: '#623914',
} as const;
