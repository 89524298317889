import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { FlightRoom, NotificationType, NotificationV1, QueryKeys, UserV1 } from 'types';
import { FlightRoomUser } from 'types/src/room';

import { useAuthenticatedUser, useSocketEvent } from '../../hooks';
import { getFlightroomByIdAndUserIsActive } from '../../queries/flightrooms/requests';
import { getUsers } from '../../queries/getUsers';
import { Fiql, InFiqlNode, isRoomInRange, RootFiql } from '../../utils';
import { useAuthenticationContext } from '../authentication';
import { useSocket } from '../SocketContext';
import { useUsersContext } from '../users';
import { FlightroomsContextType } from './flightroomContext.type';
import { FlightRoomActionType } from './FlightRooms.actions';
import { flightRoomReducer } from './FlightRooms.reducer';

export const DEFAULT_FLIGHTROOMS_CONTEXT: FlightroomsContextType = {
  activeFlightrooms: [],
  addFlightroom: () => {},
  closeFilters: () => {},
  completedFlightrooms: [],
  getRoomById: () => Promise.resolve(undefined),
  isFiltersOpen: false,
  openFilters: () => {},
  openTopic: '',
  removeFlightroom: () => {},
  resetFlightrooms: () => {},
  selectedFlightroom: undefined,
  setActiveFlightrooms: () => {},
  setCompletedFlightrooms: () => {},
  setOpenTopic: () => {},
  setSelectedFlightroom: () => {},
  updateActiveFlightRooms: () => {},
  updateCompletedFlightRooms: () => {},
  updateFlightroom: () => {},
  updateMessageCountOfRoom: () => {},
};

export const FlightroomsContext = createContext<FlightroomsContextType>(DEFAULT_FLIGHTROOMS_CONTEXT);

export const useFlightroomsContext = () => useContext(FlightroomsContext);

type Props = {
  children: ReactNode;
};

export const FlightroomsContextProvider: FC<Props> = ({ children }) => {
  const { canViewFlightRooms, hasPendingAccessRequest, isActiveMemberOfRoom, isNotActiveMemberOfRoom } = useAuthenticatedUser();
  const queryClient = useQueryClient();
  const { user } = useAuthenticationContext();

  const [state, dispatch] = useReducer(flightRoomReducer, {
    activeFlightrooms: new Map(),
    completedFlightrooms: new Map(),
  });
  const { socket } = useSocket();
  const [selectedFlightroomId, setSelectedFlightroomId] = useState<string | null>(null);
  const [activeTopic, setActiveTopic] = useState<string>('');
  const [isFiltersOpen, setIsFiltersOpen] = useState<boolean>(false);
  const { updateUsers, users } = useUsersContext();
  const previousSelectedFlightRoomRef = useRef<FlightRoom | undefined>(undefined);

  const setActiveFlightrooms = useCallback((flightRooms: FlightRoom[]) => {
    dispatch({ payload: flightRooms, type: FlightRoomActionType.SET_ACTIVE_FLIGHTROOMS });
  }, []);

  const setCompletedFlightrooms = useCallback((flightRooms: FlightRoom[]) => {
    dispatch({ payload: flightRooms, type: FlightRoomActionType.SET_COMPLETED_FLIGHTROOMS });
  }, []);

  const updateActiveFlightRooms = useCallback((flightRooms: FlightRoom[]) => {
    dispatch({ payload: flightRooms, type: FlightRoomActionType.UPDATE_ACTIVE_FLIGHTROOMS });
  }, []);

  const updateMessageCountOfRoom = useCallback((roomId: string, count: number) => {
    dispatch({ payload: { count, roomId }, type: FlightRoomActionType.UPDATE_FLIGHTROOM_MESSAGE_COUNT });
  }, []);

  const updateCompletedFlightRooms = useCallback((flightRooms: FlightRoom[]) => {
    dispatch({ payload: flightRooms, type: FlightRoomActionType.UPDATE_COMPLETED_FLIGHTROOMS });
  }, []);

  const addFlightroom = useCallback(
    (flightRoom: FlightRoom) => dispatch({ payload: flightRoom, type: FlightRoomActionType.ADD_FLIGHTROOM }),
    [],
  );

  const updateFlightroom = useCallback(
    (flightRoom: FlightRoom) => {
      if (isNotActiveMemberOfRoom(flightRoom) || !isRoomInRange(flightRoom) || flightRoom.archived) {
        dispatch({ payload: flightRoom, type: FlightRoomActionType.REMOVE_FLIGHTROOM });
      } else {
        dispatch({ payload: flightRoom, type: FlightRoomActionType.UPDATE_FLIGHTROOM });
      }
    },
    [isNotActiveMemberOfRoom],
  );

  const removeFlightroom = useCallback(
    (flightroom: FlightRoom) => dispatch({ payload: flightroom, type: FlightRoomActionType.REMOVE_FLIGHTROOM }),
    [],
  );

  const setSelectedFlightRoom = useCallback(
    (flightroom: FlightRoom | null) => {
      if (flightroom) {
        addFlightroom(flightroom);
        setSelectedFlightroomId(flightroom.externalId);
      } else {
        setSelectedFlightroomId(null);
      }
    },
    [addFlightroom],
  );

  const selectedFlightRoom = useMemo(() => {
    if (!selectedFlightroomId) {
      return undefined;
    }
    return state.activeFlightrooms.get(selectedFlightroomId) || state.completedFlightrooms.get(selectedFlightroomId);
  }, [selectedFlightroomId, state.activeFlightrooms, state.completedFlightrooms]);

  const openFilters = () => setIsFiltersOpen(true);

  const closeFilters = () => setIsFiltersOpen(false);

  //ref does not cause a rerender, so we can use it to store the users
  const usersRef = useRef(users);
  useEffect(() => {
    usersRef.current = users;
  }, [users]);

  const fetchUsersByExternalId = async (userExternalIds: string[]) => {
    const query = new RootFiql<FlightRoomUser>(new InFiqlNode<FlightRoomUser>('externalId', userExternalIds));
    let offset = 0;
    let total = 0;
    const fetchedUsers = [];

    if (userExternalIds.length > 0) {
      do {
        const userResponse = await getUsers({
          offset,
          query: query as Fiql<UserV1>,
        });

        offset = userResponse.offset;
        total = userResponse.total;

        if (userResponse?.items) {
          fetchedUsers.push(...userResponse.items);
        }
      } while (total !== 0 && offset >= total);
    }

    return fetchedUsers;
  };

  const processUsersForFlightRoom = useCallback(
    async (room: FlightRoom) => {
      const { externalId, users } = room;

      if (selectedFlightroomId === externalId) {
        const currentUserIds = usersRef.current?.map(({ externalId }) => externalId);
        const newIds = users?.filter(user => !currentUserIds?.includes(user.externalId)).map(user => user.externalId) || [];
        if (newIds) {
          try {
            const updatedUsers = await fetchUsersByExternalId(newIds);

            if (updatedUsers.length > 0) {
              updateUsers(updatedUsers);
            }
          } catch (e) {
            // fail silently
            // eslint-disable-next-line no-console
            console.error(`Something went wrong fetching new users: ${e}`);
          }
        }
      }
    },
    [selectedFlightroomId, updateUsers],
  );

  useEffect(() => {
    if (selectedFlightRoom && selectedFlightRoom.externalId !== previousSelectedFlightRoomRef.current?.externalId) {
      processUsersForFlightRoom(selectedFlightRoom);
    }
    previousSelectedFlightRoomRef.current = selectedFlightRoom;
  }, [processUsersForFlightRoom, selectedFlightRoom]);

  const resetFlightrooms = useCallback(() => {
    setSelectedFlightRoom(null);
    setActiveFlightrooms([]);
    setCompletedFlightrooms([]);
  }, [setActiveFlightrooms, setCompletedFlightrooms, setSelectedFlightRoom]);

  const getLocalStoredRoomById = useCallback(
    (id: FlightRoom['externalId']) => {
      return state.activeFlightrooms.get(id) || state.completedFlightrooms.get(id);
    },
    [state.activeFlightrooms, state.completedFlightrooms],
  );

  const getRoomById = useCallback(
    async (id: FlightRoom['externalId']) => {
      const room = getLocalStoredRoomById(id);

      if (!room) {
        const response = await getFlightroomByIdAndUserIsActive(id);
        const foundRoom = response?.items?.at(0);

        if (foundRoom) {
          dispatch({ payload: foundRoom, type: FlightRoomActionType.UPDATE_FLIGHTROOM });
        }

        return foundRoom;
      }

      return room;
    },
    [getLocalStoredRoomById],
  );

  const isAccessGrantedInRoomUpdate = useCallback(
    (room: FlightRoom) => {
      const currentRoom = getLocalStoredRoomById(room.externalId);
      if (!currentRoom) {
        return false;
      }

      const isCurrentRoomLocked = hasPendingAccessRequest(currentRoom);
      return isCurrentRoomLocked && isActiveMemberOfRoom(room);
    },
    [getLocalStoredRoomById, hasPendingAccessRequest, isActiveMemberOfRoom],
  );

  const processFlightroomUpdate = useCallback(
    (room: FlightRoom) => {
      if (!canViewFlightRooms) {
        return;
      }
      if (!isRoomInRange(room) || room.archived) {
        dispatch({ payload: room, type: FlightRoomActionType.REMOVE_FLIGHTROOM });
        if (room.externalId === selectedFlightroomId) {
          setSelectedFlightRoom(null);
        }
        return;
      }
      if (isAccessGrantedInRoomUpdate(room)) {
        queryClient.invalidateQueries([QueryKeys.FLIGHTROOMS]);
      }
      updateFlightroom(room);
      processUsersForFlightRoom(room);
    },
    [
      canViewFlightRooms,
      isAccessGrantedInRoomUpdate,
      queryClient,
      updateFlightroom,
      processUsersForFlightRoom,
      selectedFlightroomId,
      setSelectedFlightRoom,
    ],
  );

  const handleNotificationCreate = useCallback(
    (notification: NotificationV1) => {
      if (
        user &&
        notification.type === NotificationType.MENTION &&
        notification.user === user.externalId &&
        notification.room !== selectedFlightroomId
      ) {
        const roomToUpdate = state.activeFlightrooms.get(notification.room) || state.completedFlightrooms.get(notification.room);
        if (roomToUpdate) {
          dispatch({ payload: { ...roomToUpdate, hasUnreadMentions: true }, type: FlightRoomActionType.UPDATE_FLIGHTROOM });
        }
      }
    },
    [selectedFlightroomId, state.activeFlightrooms, state.completedFlightrooms, user],
  );

  useSocketEvent(socket, 'room.update', processFlightroomUpdate);
  useSocketEvent(socket, 'notification.create', handleNotificationCreate);

  return (
    <FlightroomsContext.Provider
      value={{
        activeFlightrooms: Array.from(state.activeFlightrooms, ([, value]) => ({ ...value })),
        addFlightroom,
        closeFilters,
        completedFlightrooms: Array.from(state.completedFlightrooms, ([, value]) => ({ ...value })),
        getRoomById,
        isFiltersOpen,
        openFilters,
        openTopic: activeTopic,
        removeFlightroom,
        resetFlightrooms,
        selectedFlightroom: selectedFlightRoom,
        setActiveFlightrooms,
        setCompletedFlightrooms,
        setOpenTopic: setActiveTopic,
        setSelectedFlightroom: setSelectedFlightRoom,
        updateActiveFlightRooms,
        updateCompletedFlightRooms,
        updateFlightroom,
        updateMessageCountOfRoom,
      }}
    >
      {children}
    </FlightroomsContext.Provider>
  );
};
