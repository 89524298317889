import { MutateOptions, useMutation } from '@tanstack/react-query';
import { ApiError, FlightRoom, QueryKeys } from 'types';

import { useFlightroomsContext } from '../context';
import { requestAccess } from '../queries';
import { getFlightRooms } from '../queries/flightrooms/requests';
import { EqualFiqlNode, RootFiql } from '../utils';

type RequestAccessWithOnSuccessReturn = Omit<MutateOptions<void, ApiError, FlightRoom['externalId'], void>, 'onSuccess'> & {
  onSuccess?: (arg: FlightRoom | undefined) => void;
};

export const useRequestAccess = (options?: RequestAccessWithOnSuccessReturn) => {
  const { addFlightroom } = useFlightroomsContext();

  return useMutation([QueryKeys.REQUEST_ACCESS], requestAccess, {
    ...options,
    onSuccess: async (_, roomId) => {
      const { items } = await getFlightRooms({
        limit: 1,
        query: new RootFiql(new EqualFiqlNode<FlightRoom>('externalId', roomId)),
      });

      const room = items?.at(0);

      if (room) {
        addFlightroom(room);
      }

      if (options?.onSuccess) {
        options.onSuccess(room);
      }
    },
  });
};
