export const everest = {
  $01: '#b3e9c9',
  $02: '#9de3b9',
  $03: '#7cdaa2',
  $04: '#67d493',
  $05: '#45ca7b',
  $06: '#25c164',
  $07: '#21ad5a',
  $08: '#1fa455',
  $09: '#1c914b',
  $10: '#16743c',
  $11: '#0f4d28',
} as const;
