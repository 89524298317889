import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { ApiError, AuthenticatedUser, QueryKeys } from 'types';

import { useAuthenticationContext } from '../../context';
import { authenticate, authenticateAdmin } from '../../queries';

export const useAuthenticate = (
  admin: boolean,
  options: UseQueryOptions<AuthenticatedUser, ApiError, AuthenticatedUser, QueryKeys[]>,
) => {
  const { enableAuthentication, enableAuthenticationCms } = useAuthenticationContext();

  return useQuery([QueryKeys.AUTHENTICATE], admin ? authenticateAdmin : authenticate, {
    ...options,
    enabled: (!admin ? enableAuthentication : enableAuthenticationCms) && options?.enabled,
    staleTime: 0,
  });
};
