import { useMemo } from 'react';
import { Division, FlightRoomUser, UserV1 } from 'types';

import { UserV1Util } from '../utils';

export type FlightRoomUserWithName = FlightRoomUser & {
  division: Division;
  name: string | null;
};

export const useEnrichedFlightRoomUsers = (
  flightroomUsers: FlightRoomUser[],
  fetchedUsers?: UserV1[],
): FlightRoomUserWithName[] => {
  return useMemo(() => {
    return flightroomUsers.map(flightroomUser => {
      const matchingUser = fetchedUsers?.find(({ externalId }) => externalId === flightroomUser.externalId);
      return {
        ...flightroomUser,
        division: matchingUser ? UserV1Util.getDivision(matchingUser.jobTitle) : Division.UNKNOWN,
        name: matchingUser ? UserV1Util.getFullName(matchingUser) : null,
      };
    });
  }, [flightroomUsers, fetchedUsers]);
};
