import { NestedObjectsPathKeys, Nullable, TypeFromNestedObjectsPathKeys } from 'types';

import BaseFiqlNode from '../base-types/base-fiql-node.abstract.class';
import FiqlOperators from '../types/fiql-operators.enum';
import { stringified } from '../utils';

export default class EqualFiqlNode<T extends object> extends BaseFiqlNode<FiqlOperators.EQUALS, T> {
  protected operator: FiqlOperators.EQUALS = FiqlOperators.EQUALS;

  override transform(arg: Nullable<Required<TypeFromNestedObjectsPathKeys<T, NestedObjectsPathKeys<Required<T>>>>>): string {
    return stringified(arg);
  }
}
