import * as React from "react";
const circularAccepted: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path
        d="M12.5 0C5.607 0 0 5.607 0 12.5S5.607 25 12.5 25 25 19.393 25 12.5 19.393 0 12.5 0z"
        fill="#D0DADF"
        fillRule="nonzero"
      />
      <path
        d="M7.434 12.966a.84.84 0 0 0-1.188 1.188l5.6 5.6a.84.84 0 0 0 1.331-.192l6.72-12.32a.84.84 0 1 0-1.475-.804L12.24 17.772l-4.806-4.806z"
        fill="#FFF"
      />
    </g>
  </svg>
);
export default circularAccepted;
