export const yuma = {
  $01: '#ffffff',
  $02: '#f9f9f9',
  $03: '#f2f2f2',
  $04: '#ebebeb',
  $05: '#e5e5e5',
  $06: '#d9d9d9',
  $07: '#d1d1d1',
  $08: '#c7c7c7',
  $09: '#bdbdbd',
  $10: '#b2b2b2',
  $11: '#a6a6a6',
  $12: '#999999',
} as const;
