import * as React from "react";
const checkDisabled: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg viewBox="0 0 25 25" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>icon</title>
    <g
      id="Flight-Rooms"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="AvioBookConnect/Web/Flightrooms/Search"
        transform="translate(-355, -319)"
      >
        <g id="list" transform="translate(0, 100)">
          <g id="Card-Copy-4" transform="translate(0, 198)">
            <g id="icon" transform="translate(355, 21)">
              <path
                d="M12.5,0 C5.60738636,0 0,5.60738636 0,12.5 C0,19.3926136 5.60738636,25 12.5,25 C19.3926136,25 25,19.3926136 25,12.5 C25,5.60738636 19.3926136,0 12.5,0 Z"
                id="circle"
                fill="#D0DADF"
                fillRule="nonzero"
              ></path>
              <path
                d="M7.43395342,12.9660592 C7.10591674,12.638025 6.57406419,12.638025 6.24602751,12.9660592 C5.91799083,13.2940935 5.91799083,13.8259422 6.24602751,14.1539765 L11.845964,19.753872 C12.2424981,20.1504032 12.9088183,20.0544478 13.1773508,19.5621417 L19.8972746,7.24237149 C20.1194207,6.83510649 19.9693499,6.32486923 19.5620819,6.10272468 C19.1548139,5.88058013 18.6445729,6.03064992 18.4224267,6.43791492 L12.2400743,17.772145 L7.43395342,12.9660592 Z"
                id="Path"
                fill="#FFFFFF"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default checkDisabled;
