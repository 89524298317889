import * as React from "react";
const roleDispatcherHeadsetFilled: React.FC<
  React.SVGProps<SVGSVGElement>
> = () => (
  <svg viewBox="0 0 25 25" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Icons/Finals/Aviation/Roles/role-dispatcher-headset-filled</title>
    <g
      id="Icons/Finals/Aviation/Roles/role-dispatcher-headset-filled"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M12.5,2.34375 C15.6657892,2.34375 18.3546944,4.32303981 19.2305791,7.0556712 C21.5934758,7.29604532 23.4375,9.2920502 23.4375,11.71875 C23.4375,14.1459823 21.5926665,16.1423306 19.2286862,16.3821082 C18.7573291,17.8544484 17.7535235,19.132253 16.3834697,19.9901438 C16.398705,20.0947411 16.40625,20.2027395 16.40625,20.3125 C16.40625,21.6069174 15.3569174,22.65625 14.0625,22.65625 L10.9375,22.65625 C9.64308262,22.65625 8.59375,21.6069174 8.59375,20.3125 C8.59375,19.0180826 9.64308262,17.96875 10.9375,17.96875 L14.0625,17.96875 C14.6796597,17.96875 15.2411056,18.2072891 15.6596692,18.5971989 C17.0941514,17.6544434 17.96875,16.1213397 17.96875,14.453125 L17.96875,8.984375 C17.96875,6.19147687 15.5311747,3.90625 12.5,3.90625 C9.46882533,3.90625 7.03125,6.19147687 7.03125,8.984375 L7.03125,15.625 C7.03125,16.025653 6.7296561,16.355865 6.34111025,16.400994 L6.25,16.40625 C3.66116524,16.40625 1.5625,14.3075848 1.5625,11.71875 C1.5625,9.2920502 3.40652419,7.29604532 5.76975807,7.05554988 C6.64530557,4.32303981 9.33421076,2.34375 12.5,2.34375 Z"
        id="Path"
        fill="#0F384D"
      ></path>
    </g>
  </svg>
);
export default roleDispatcherHeadsetFilled;
