import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useForm } from '@aviobook/_hooks';
import { FormValidationErrors } from '@aviobook/_hooks/useForm';
import { Spinner } from '@aviobook/_shared';
import { useCreateSmartAction } from '@aviobook/cms/connect/_screens/general/_tabs/smart-actions/_queries/useCreateSmartAction';
import { SmartActionForm } from '@aviobook/cms/connect/_screens/general/_tabs/smart-actions/smart-action/SmartActionForm';
import { FormValidator, PermissionsUtil, useAuthenticationContext } from 'shared';
import { SmartAction, SmartActionPermissions } from 'types';

import './smartAction.scss';

const validateForm = (values: SmartAction): FormValidationErrors<SmartAction> => {
  const validator = new FormValidator();

  return {
    name: validator.required(values.name),
    url: validator.required(values.url),
  };
};

const initialForm: SmartAction = {
  apiKey: '',
  description: '',
  id: '',
  isRequestPublic: false,
  isResponsePublic: false,
  keywords: [],
  name: '',
  url: '',
};

export const CreateSmartAction = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { user } = useAuthenticationContext();

  const { isLoading, mutate } = useCreateSmartAction({
    onSuccess: () => {
      navigate('/admin/connect');
    },
  });

  const permissions = new PermissionsUtil(user);

  const form = useForm<SmartAction>({
    initialForm,
    submitForm: values => mutate(values),
    validateForm: values => validateForm(values),
  });

  if (isLoading) {
    return <Spinner overlay>{t('SHARED.LOADING_APPLICATION')}</Spinner>;
  }

  return (
    <main className="smart-action-container">
      <SmartActionForm canSave={permissions.hasPermission(SmartActionPermissions.ADD)} form={form} />
    </main>
  );
};
