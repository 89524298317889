import React, { ChangeEvent, forwardRef, Ref } from 'react';
import { useInputError } from '@aviobook/_hooks';
import { IconName } from 'assets';
import classnames from 'classnames';
import { COLORS } from 'styles';

import { Icon } from '../../icon/Icon';
import InputWrapper, { InputWrapperProps } from '../InputWrapper';

import './inputField.scss';

export type InputFieldProps = InputWrapperProps & {
  autoComplete?: string;
  autoFocus?: boolean;
  hasClearButton?: boolean;
  iconName?: IconName;
  normalize?: (value: string) => string | number;
  onChange: (value: string | number, name: string) => void;
  onClearClick?: () => void;
  onClick?: () => void;
  onIconClick?: () => void;
  placeholder?: string;
  ref?: Ref<HTMLInputElement>;
  type?: 'email' | 'number' | 'password' | 'text';
  value?: string;
};

export const InputField = forwardRef(
  (
    {
      autoComplete = 'off',
      autoFocus,
      hasClearButton = false,
      iconName,
      normalize,
      onChange,
      onClearClick,
      onClick = () => {},
      onIconClick,
      placeholder,
      type = 'text',
      value,
      ...wrapperProps
    }: InputFieldProps,
    ref: Ref<HTMLInputElement>,
  ) => {
    const { disabled, name, validation } = wrapperProps;
    const { setDirty, showError } = useInputError(validation);

    return (
      <InputWrapper {...wrapperProps} showError={showError}>
        {iconName ? (
          <Icon
            className="input-icon"
            colorName={disabled ? COLORS.zulu.$04 : COLORS.zulu.$08}
            name={iconName}
            onClick={onIconClick}
            size={2}
          />
        ) : null}
        <input
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          className={classnames('input', { disabled: disabled, error: showError, hasClearButton, hasIcon: !!iconName })}
          disabled={disabled}
          name={name}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const { value } = event.target;
            let normalizedValue = type === 'number' ? parseFloat(value) : value;
            if (normalize) {
              normalizedValue = normalize(value.trim());
            }
            onChange(normalizedValue, name);
            setDirty();
          }}
          onClick={onClick}
          placeholder={placeholder}
          ref={ref}
          type={type}
          value={value}
        />
        {hasClearButton && value ? (
          <Icon className="input-clear-icon" name="circularCrossPaddingFilled" onClick={onClearClick} size={2.5} />
        ) : null}
      </InputWrapper>
    );
  },
);
