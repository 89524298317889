import { FC, ReactNode } from 'react';
import classnames from 'classnames';

import './spinner.scss';

type Props = {
  children?: ReactNode;
  overlay?: boolean;
  size?: 'normal' | 'large' | 'small';
  testId?: string;
  theme?: 'normal' | 'inversed' | 'faded';
};

export const Spinner: FC<Props> = ({
  children,
  overlay = false,
  size = overlay ? 'large' : 'normal',
  theme = 'normal',
  testId,
}) => {
  const spinner = (
    <div className={classnames('spinner-wrapper', `theme-${theme}`)} data-test={testId}>
      <div className={classnames('spinner', `size-${size}`)}>
        {Array.from(Array(4).keys()).map(index => (
          <div key={index} />
        ))}
      </div>
      {children && <span className="spinner-content">{children}</span>}
    </div>
  );
  if (!overlay) {
    return spinner;
  }
  return <div className="spinner-overlay">{spinner}</div>;
};
