import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { HttpClient, PushNotificationSetting, useUpdateSettings } from 'shared';
import { API_FACTORY } from 'shared/src/utils/factories/api.factory';
import { ApiError, QueryKeys } from 'types';

import { SettingsItems } from './types';

const fetchSettings = async (): Promise<SettingsItems> => {
  const { pushNotifications, ...settings } = await HttpClient.get<SettingsItems>(API_FACTORY.v1('settings'));
  return {
    ...settings,
    pushNotifications: sortPushNotifications(pushNotifications),
  };
};

const sortPushNotifications = (pushNotifications: PushNotificationSetting[]) => {
  const order = ['USERS', 'MENTIONS', 'SYSTEM_MESSAGES', 'SMART_ACTION'];
  return pushNotifications.sort((a, b) => order.indexOf(a.key) - order.indexOf(b.key));
};

export const useSettings = () => {
  const [localSettings, setLocalSettings] = useState<SettingsItems | null>(null);

  const {
    data: serverSettings,
    isError,
    isFetching,
    refetch,
  } = useQuery<SettingsItems, ApiError>([QueryKeys.SETTINGS], fetchSettings, {
    onSuccess: setLocalSettings,
  });

  const { isLoading: isUpdating, mutate: updateSettings } = useUpdateSettings({
    onError: () => {
      setLocalSettings(serverSettings ?? null); // Revert to the server state if an error occurs
    },
    onSuccess: () => refetch(),
  });

  const onUpdatePushNotificationSetting = (key: PushNotificationSetting['key'], isEnabled: boolean) => {
    const settings = localSettings ?? serverSettings;

    if (!settings) {
      return;
    }

    const updatedPushNotifications = settings.pushNotifications.map(setting =>
      setting.key === key ? { ...setting, isEnabled, isUpdating: true } : setting,
    );

    // Optimistically update the local settings
    const sortedPushNotifications = sortPushNotifications(updatedPushNotifications);
    const updatedSettings = { ...localSettings, pushNotifications: sortedPushNotifications };
    setLocalSettings(updatedSettings);

    updateSettings({ pushNotifications: sortedPushNotifications });
  };

  return {
    fetchSettings,
    isError,
    isLoading: !localSettings && isFetching,
    isUpdating,
    onUpdatePushNotificationSetting,
    refetch,
    settings: localSettings ?? serverSettings,
  };
};
