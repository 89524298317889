import { UserV1 } from 'types';

export enum UserActionType {
  'ADD_USER',
  'UPDATE_USER',
  'UPDATE_USERS',
}

export type UserAction =
  | {
      payload: UserV1[];
      type: UserActionType.UPDATE_USERS;
    }
  | {
      payload: UserV1;
      type: UserActionType.ADD_USER;
    }
  | {
      payload: UserV1;
      type: UserActionType.UPDATE_USER;
    };
