import { FC, ReactNode } from 'react';
import classnames from 'classnames';

import { Icon } from '../icon/Icon';

import './errorMessage.scss';

type Props = {
  children: ReactNode;
  className?: string;
  isGlobal?: boolean;
  isVisible: boolean;
};

export const ErrorMessage: FC<Props> = ({ children, className = '', isGlobal = false, isVisible }) => {
  if (isVisible && !!children) {
    return (
      <div className={classnames('error-message', { global: isGlobal }, className)}>
        {isGlobal && <Icon name="triangleCoreCaution" size={2.5} />}
        {isGlobal && <Icon name="alert" size={2.5} />}
        <span>{children}</span>
      </div>
    );
  }
  return null;
};
