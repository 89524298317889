import { useMemo } from 'react';
import { QueryFunctionContext, QueryKey, useInfiniteQuery } from '@tanstack/react-query';
import { ApiError, NotificationV1, PaginationV1, QueryKeys, RequestOptionsV1 } from 'types';

import { API_FACTORY, HttpClient, parseRequestOptionsV1ToUrlParams, SortDescending } from '../../utils';

const getNotifications = (options: RequestOptionsV1<NotificationV1>) =>
  HttpClient.get<
    PaginationV1<NotificationV1> & {
      totalUnread: number;
    }
  >(API_FACTORY.v1('notifications'), parseRequestOptionsV1ToUrlParams(options));

const OPTIONS: RequestOptionsV1<NotificationV1> = {
  limit: 20,
  sort: [new SortDescending<NotificationV1>('createdAt')],
};

export const useGetNotifications = () => {
  const { data, error, fetchNextPage, hasNextPage, isFetching, isRefetching, refetch } = useInfiniteQuery<
    PaginationV1<NotificationV1> & { totalUnread: number },
    ApiError
  >({
    getNextPageParam: ({ limit, offset, total }) => {
      if (offset + limit >= total) {
        return undefined;
      }

      return offset + limit;
    },
    queryFn: (context: QueryFunctionContext<QueryKey, number>) =>
      getNotifications({
        ...OPTIONS,
        offset: context.pageParam,
      }),
    queryKey: [QueryKeys.NOTIFICATIONS],
  });

  const notifications: NotificationV1[] = useMemo(() => {
    const pages = data?.pages;
    if (!pages) {
      return [];
    }

    return pages.flatMap(page => page.items ?? []);
  }, [data]);

  const unreadNotificationsCount = useMemo(() => {
    const firstPage = data?.pages[0];
    return firstPage ? firstPage.totalUnread : 0;
  }, [data]);

  return {
    data: notifications,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isRefetching,
    refetch,
    unreadNotificationsCount,
  };
};
