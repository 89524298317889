import * as React from "react";
const triangleCoreCautionFilled: React.FC<
  React.SVGProps<SVGSVGElement>
> = () => (
  <svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
    <g fillRule="nonzero" fill="none">
      <path
        d="M13.732 1.711a1.423 1.423 0 0 0-2.463 0L.193 20.894a1.422 1.422 0 0 0 1.232 2.134h22.15a1.422 1.422 0 0 0 1.232-2.134L13.732 1.711z"
        fill="#F48E31"
      />
      <path
        d="M11.7 9.195c0-.097.048-.195.195-.195h1.124c.097 0 .195.048.195.195v6.592c0 .097-.05.194-.195.194h-1.124c-.098 0-.195-.048-.195-.194V9.195zm1.563 10.059c0 .097-.049.195-.196.195h-1.172c-.097 0-.195-.05-.195-.195v-1.269c0-.098.05-.195.195-.195h1.172c.099 0 .196.048.196.195v1.269z"
        fill="#FFF"
      />
    </g>
  </svg>
);
export default triangleCoreCautionFilled;
