import { FC, useEffect, useState } from 'react';
import { MessageProps } from 'react-native-gifted-chat';
import { useOfflineMessagesContext } from '@aviobook/_context';
import { Avatar, Icon, MessageStatusIcon, Spinner, Text } from '@aviobook/_shared';
import { MessageBubbleText } from '@aviobook/chat/_components/messageBubble/_components/MessageBubbleText';
import { IconName } from 'assets';
import classnames from 'classnames';
import clsx from 'clsx';
import { formatDate, toBase64 } from 'shared';
import { COLORS } from 'styles';
import { GiftedMessage } from 'types';

import { MessageStatusWrapper, UserInfo } from './_components';

import './messageBubble.scss';

type Props = MessageProps<GiftedMessage<IconName>> & {
  onClickAttachment: (attachment: string) => void;
  onDelete: (message: GiftedMessage<IconName>) => void;
  onRetry: (message: GiftedMessage<IconName>, pendingImage: File) => void;
  pendingImage: File;
  setActiveMention: (mention: string) => void;
  showMessageInfo: () => void;
};

export const MessageBubble: FC<Props> = ({
  currentMessage,
  nextMessage,
  onClickAttachment,
  onDelete,
  onRetry,
  pendingImage,
  position,
  previousMessage,
  setActiveMention,
  showMessageInfo,
}) => {
  const { deleteStoredMessage } = useOfflineMessagesContext();
  const { failed, image, pending, status, text, user } = currentMessage;
  const nextMessageBySameUser = nextMessage?.user?._id === currentMessage.user?._id;
  const isMe = position === 'right' || pending;
  const timestamp = formatDate(new Date(currentMessage.createdAt), 'HH:mm');
  const hasAvatar = previousMessage.user?._id !== currentMessage.user?._id && !isMe;
  const [pendingImageBase64, setPendingImageBase64] = useState('');

  const [isHovered, setIsHovered] = useState(false);

  const onClickRetry = () => {
    deleteStoredMessage(currentMessage.messageId);
    onRetry(currentMessage, pendingImage);
  };

  const onClickDelete = () => {
    deleteStoredMessage(currentMessage.messageId);
    onDelete(currentMessage);
  };

  useEffect(() => {
    const getFailedImage = async () => {
      const base64 = await toBase64(pendingImage);
      setPendingImageBase64(base64);
    };

    if (pendingImage) {
      getFailedImage();
    }
  }, [pendingImage]);

  const showMessageActionsButton = () => {
    setIsHovered(isMe);
  };

  const hideMessageActionsButton = () => {
    setIsHovered(false);
  };

  const onClickMessageInfo = () => {
    setIsHovered(false);
    showMessageInfo();
  };

  return (
    <MessageStatusWrapper
      isMe={isMe}
      messageStatus={status}
      nextMessageBySameUser={nextMessageBySameUser}
      onClickDelete={onClickDelete}
      onClickRetry={onClickRetry}
    >
      {hasAvatar && <UserInfo user={user} />}
      <div className="message-bubble-container" onMouseEnter={showMessageActionsButton} onMouseLeave={hideMessageActionsButton}>
        {hasAvatar && <Avatar icon={user.icon} isDisabled />}
        <div
          className={classnames('message-bubble-content-container', {
            'by-me': isMe,
            'has-attachment': !!image || pendingImage,
            'has-avatar': hasAvatar,
          })}
        >
          {(!!image || !!pendingImage) && (
            <button className="message-bubble-image-button-wrapper" onClick={() => onClickAttachment(image)}>
              <img crossOrigin="anonymous" src={image || pendingImageBase64} />
              {!!pendingImageBase64 && !pending && <div className="icon-container">{<Spinner />}</div>}
            </button>
          )}
          <MessageBubbleText
            isMe={isMe}
            setActiveMention={setActiveMention}
            text={text}
            withStatus={isMe && !pending && !failed}
          />
          <div className="message-bubble-meta">
            <Text className="text-date" color={isMe ? COLORS.zulu.$08 : COLORS.zulu.$10} size="XXS">
              {timestamp}
            </Text>
            {isMe ? <MessageStatusIcon status={status} /> : null}
          </div>
        </div>
        <div>
          {
            <Icon
              className={clsx('info-icon', {
                hidden: !isHovered,
              })}
              colorName={COLORS.yuma.$12}
              name="circularInfoOutline"
              onClick={onClickMessageInfo}
              size={2}
            />
          }
        </div>
      </div>
    </MessageStatusWrapper>
  );
};
