import { MessageTypes } from './messageTypeV1';
import { MessageV1 } from './messageV1';

export type SystemMessageV1 = MessageV1<MessageTypes.SYSTEM> & {
  level?: SystemMessageLevel;
};

export enum SystemMessageLevel {
  ACCENT = 'ACCENT',
  ATTENTION = 'ATTENTION',
  CAUTION = 'CAUTION',
  IMPORTED = 'IMPORTED',
  OK = 'OK',
  UNCLEAR = 'UNCLEAR',
  UNKNOWN = 'UNKNOWN',
  WARNING = 'WARNING',
}
