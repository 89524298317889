import * as React from "react";
const messageUnreadOutline: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.094 3.906a2.344 2.344 0 0 1 2.343 2.344v12.5a2.344 2.344 0 0 1-2.343 2.344H3.906a2.344 2.344 0 0 1-2.344-2.344V6.25a2.344 2.344 0 0 1 2.344-2.344h17.188zm-6.27 9.656-.673.67a2.344 2.344 0 0 1-3.302 0l-.675-.67-6.015 5.97H20.84l-6.017-5.97zm7.051-6.994-5.94 5.894 5.94 5.895V6.568zm-18.75 0v11.788l5.94-5.894-5.94-5.893zm17.64-1.1H4.235l7.715 7.655a.781.781 0 0 0 1.1 0l7.715-7.654z"
      fill="#144761"
      fillRule="evenodd"
    />
  </svg>
);
export default messageUnreadOutline;
