import { useTranslation } from 'react-i18next';
import { useEnvConfig } from 'shared';
import { COLORS } from 'styles';
import { License } from 'types';

import { Text } from '../_shared';
import licensesAsObject from '../licenses.json';
import { LicenseItem } from './licenseItem/LicenseItem';

import './about.scss';

export const About = () => {
  const { appVersionNumber } = useEnvConfig();
  const { t } = useTranslation();
  const licenses: License[] = Object.entries(licensesAsObject).map(([name, fields]) => ({ name, ...fields }));
  const renderLicense = (license: License) => <LicenseItem key={license.name} {...license} />;

  return (
    <>
      <div className="about-version-container">
        <Text color={COLORS.zulu.$08} size="SM" weight="semiBold">
          {t('ABOUT.VERSION')}
        </Text>
        <Text color={COLORS.zulu.$08} size="SM">
          {/* BUMP VERSION IN .ENV */}
          {appVersionNumber}
        </Text>
      </div>
      <div className="about-licenses-container">
        <Text className="about-licenses-text" color={COLORS.romeo.$06} size="SM" weight="bold">
          {t('ABOUT.LICENSES')}
        </Text>
        <ul className="about-licenses-list">{licenses.map(renderLicense)}</ul>
      </div>
    </>
  );
};
