import { Dispatch, useCallback } from 'react';
import { Socket } from 'socket.io-client';
import { IncomingSocketEvents, OutgoingSocketEvents } from 'types';
import { MessageReceiptUpdateV1 } from 'types/src/v1/MessageReceiptUpdateV1';

import { MessageUnion, useSocketEvent } from '../../../hooks';
import { useAuthenticationContext } from '../../authentication';
import { ChatMessagesActionType } from '../chatMessagesAction.enum';
import { ChatMessagesActions } from '../chatMessagesAction.type';

export const useChatMessageSocketEvents = (
  socket: Socket<IncomingSocketEvents, OutgoingSocketEvents> | null,
  dispatch: Dispatch<ChatMessagesActions>,
  flightroomId: string,
) => {
  const { user } = useAuthenticationContext();

  const handleIncomingMessage = useCallback(
    (message: MessageUnion) => {
      if (message.room === flightroomId) {
        dispatch({ payload: message, type: ChatMessagesActionType.ADD_MESSAGE });
      }
    },
    [dispatch, flightroomId],
  );

  const handleMessageError = useCallback(
    (message: MessageUnion) => {
      dispatch({ payload: message, type: ChatMessagesActionType.ADD_MESSAGE });
    },
    [dispatch],
  );

  const handleReceiptUpdate = useCallback(
    (messageReceiptUpdates: MessageReceiptUpdateV1[]) => {
      const ownMessageReceipts = messageReceiptUpdates.filter(
        messageToUpdate => messageToUpdate.user === user?.externalId && messageToUpdate.room === flightroomId,
      );
      dispatch({
        payload: ownMessageReceipts,
        type: ChatMessagesActionType.UPDATE_MESSAGES_STATUS,
      });
    },
    [dispatch, flightroomId, user?.externalId],
  );

  useSocketEvent(socket, 'message.create', handleIncomingMessage);
  useSocketEvent(socket, 'message.error.stream', handleMessageError);
  useSocketEvent(socket, 'message.receipt.update', handleReceiptUpdate);
};
