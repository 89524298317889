import * as React from "react";
const closeCross: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg viewBox="0 0 25 26" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.464 5.498a.782.782 0 0 1 .046 1.038l-.062.067-6.76 6.56 6.76 6.563.062.067a.782.782 0 0 1-1.15 1.054l-6.794-6.595-6.792 6.595a.781.781 0 0 1-1.15-1.054l.061-.067 6.76-6.563-6.76-6.56-.061-.067a.782.782 0 0 1 1.15-1.054l6.792 6.593 6.793-6.593a.781.781 0 0 1 1.105.016z"
      fillRule="evenodd"
    />
  </svg>
);
export default closeCross;
