export enum JobTitleV1 {
  ADMINISTRATOR = 'ADMINISTRATOR',
  CABIN_CREW = 'CABIN_CREW',
  DISPATCHER = 'DISPATCHER',
  GROUND_HANDLER = 'GROUND_HANDLER',
  GUEST = 'GUEST',
  MAINTENANCE_CREW = 'MAINTENANCE_CREW',
  PILOT = 'PILOT',
  UNKNOWN = 'UNKNOWN',
}
