import { NestedObjectsPathKeys, SortOptionsV1 } from 'types';

import { BaseSort } from './base-sort';
import { Sortable } from './sort';

export class SortDescending<T extends object> extends BaseSort<SortOptionsV1.DESC, T> implements Sortable<T> {
  type: SortOptionsV1.DESC = SortOptionsV1.DESC;

  constructor(field: NestedObjectsPathKeys<Required<T>>) {
    super(field);
  }
}
