import * as React from "react";
const peopleUserManagementUserCircularPlus: React.FC<
  React.SVGProps<SVGSVGElement>
> = () => (
  <svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.034 12.758a5.469 5.469 0 1 1 0 10.937 5.469 5.469 0 0 1 0-10.937zm0 1.562a3.906 3.906 0 1 0 0 7.813 3.906 3.906 0 0 0 0-7.813zM11.88 1c2.95 0 4.589 1.409 5.078 3.685.164.764.187 1.513.123 2.35l-.05.53.011.015c.738 1.08.395 2.989-.622 3.623l-.064.037-.024.099a6.974 6.974 0 0 1-.687 1.637 6.265 6.265 0 0 0-2.263 2.577 2.933 2.933 0 0 1-.187-.63 4.887 4.887 0 0 1-.09-.873.781.781 0 0 1 .234-.564l.118-.126.122-.142c.196-.232.392-.5.575-.793.385-.617.654-1.266.762-1.918a.781.781 0 0 1 .683-.65l.088-.004c-.052 0-.072.006-.062-.013l.013-.018c.082-.107.158-.283.203-.486.092-.421.02-.794-.123-.915a.781.781 0 0 1-.277-.634l.057-.586c.081-.822.076-1.518-.067-2.187-.34-1.576-1.359-2.452-3.55-2.452-2.193 0-3.212.876-3.551 2.452-.126.585-.145 1.191-.094 1.884l.083.89a.781.781 0 0 1-.276.633c-.143.12-.216.494-.123.915.035.163.091.308.154.414l.048.072c.019.024.02.03.006.031h-.054c.382 0 .709.277.77.654.108.652.378 1.3.762 1.918.183.294.38.56.575.793l.122.142.119.126c.15.149.235.352.233.564a4.889 4.889 0 0 1-.09.872c-.143.733-.413 1.24-1.084 1.347-2.106.333-6.188 2.679-6.896 4.477l-.053.157a.781.781 0 1 1-1.503-.43c.698-2.437 5.106-5.084 7.852-5.68l.115-.024.021-.078.015-.07.023-.13.02-.16-.023-.024a8.178 8.178 0 0 1-.657-.875l-.115-.181a7.16 7.16 0 0 1-.852-1.912l-.024-.1-.064-.036c-.974-.608-1.33-2.386-.708-3.484l.097-.154-.022-.212c-.095-.968-.09-1.809.095-2.668C7.293 2.41 8.933 1 11.881 1zm7.153 14.102c.4 0 .73.301.776.69l.005.09v1.563h1.563a.781.781 0 0 1 .09 1.558l-.09.005h-1.563v1.562a.781.781 0 0 1-1.557.091l-.005-.09v-1.563H16.69a.781.781 0 0 1-.09-1.557l.09-.006h1.562v-1.562c0-.432.35-.781.782-.781z"
      fill="#144761"
      fillRule="evenodd"
    />
  </svg>
);
export default peopleUserManagementUserCircularPlus;
