import { useCallback, useEffect, useState } from 'react';
import { MessageUnion, useAuthenticationContext, useFlightroomsContext, useSocket, useSocketEvent } from 'shared';
import { FlightRoom, UserMessageV1 } from 'types';

export const useFlightroomsUnreadCount = (isFocused: boolean) => {
  const { socket } = useSocket();
  const { user: currentUser } = useAuthenticationContext();
  const { activeFlightrooms, completedFlightrooms, selectedFlightroom, updateMessageCountOfRoom } = useFlightroomsContext();

  const [completedRoomsWithUnreadCounts, setCompletedRoomsWithUnreadCounts] = useState<FlightRoom[]>([]);
  const [activeRoomsWithUnreadCounts, setActiveRoomsWithUnreadCounts] = useState<FlightRoom[]>([]);

  const updateRooms = useCallback((rooms: FlightRoom[]) => {
    return rooms.map(room => {
      const unreadCount = room.unreadMessagesCount;
      return {
        ...room,
        unreadMessagesCount: unreadCount,
      };
    });
  }, []);

  useEffect(() => {
    const updatedRooms = updateRooms(completedFlightrooms);
    setCompletedRoomsWithUnreadCounts(updatedRooms);
  }, [completedFlightrooms, updateRooms]);

  useEffect(() => {
    const updatedRooms = updateRooms(activeFlightrooms);
    setActiveRoomsWithUnreadCounts(updatedRooms);
  }, [activeFlightrooms, updateRooms]);

  const resetUnreadCounts = useCallback(() => {
    [...completedFlightrooms, ...activeFlightrooms].forEach(room => {
      updateMessageCountOfRoom(room.externalId, 0);
    });
    setCompletedRoomsWithUnreadCounts(updateRooms(completedFlightrooms));
    setActiveRoomsWithUnreadCounts(updateRooms(activeFlightrooms));
  }, [activeFlightrooms, completedFlightrooms, updateMessageCountOfRoom, updateRooms]);

  const resetUnreadCountsForRoom = useCallback(
    (roomId: string) => {
      updateMessageCountOfRoom(roomId, 0);
      setCompletedRoomsWithUnreadCounts(updateRooms(completedFlightrooms));
      setActiveRoomsWithUnreadCounts(updateRooms(activeFlightrooms));
    },
    [completedFlightrooms, activeFlightrooms, updateMessageCountOfRoom, updateRooms],
  );

  const processMessageForRoom = useCallback(
    (message: MessageUnion) => {
      if (
        isFocused &&
        message.room !== selectedFlightroom?.externalId &&
        (message as UserMessageV1)?.user !== currentUser?.externalId
      ) {
        const roomId = message.room;
        const allRooms = [...activeRoomsWithUnreadCounts, ...completedRoomsWithUnreadCounts];
        const room = allRooms.find(room => room.externalId === roomId);

        if (room) {
          const newCount = (room.unreadMessagesCount || 0) + 1;
          updateMessageCountOfRoom(roomId, newCount);
          setCompletedRoomsWithUnreadCounts(updateRooms(completedFlightrooms));
          setActiveRoomsWithUnreadCounts(updateRooms(activeFlightrooms));
        }
      }
    },
    [
      activeRoomsWithUnreadCounts,
      completedRoomsWithUnreadCounts,
      currentUser?.externalId,
      isFocused,
      selectedFlightroom?.externalId,
      updateMessageCountOfRoom,
      updateRooms,
      completedFlightrooms,
      activeFlightrooms,
    ],
  );

  useSocketEvent(socket, 'message.create', processMessageForRoom);

  return {
    activeRoomsWithUnreadCounts,
    completedRoomsWithUnreadCounts,
    resetUnreadCounts,
    resetUnreadCountsForRoom,
  };
};
