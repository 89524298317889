export enum DeviceType {
  DESKTOP,
  MOBILE,
  TABLET,
}

export enum OsType {
  IOS,
  ANDROID,
}
