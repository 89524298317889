import { FlightRoom, FlightRoomUserStatus, PaginationV1, RequestOptionsV1 } from 'types';

import {
  API_FACTORY,
  getUrlWithQueryParams,
  HttpClient,
  parseRequestOptionsV1ToUrlParams,
  RequestOptionsRecords,
} from '../../utils';
import { getFlightroomByIdFiql } from './fiqls/flightRoomFiqls';

export const FLIGHTROOMS_LIMIT = 20;

export type FlightRoomsQueryOptions = RequestOptionsV1<FlightRoom> & {
  flightRoomUserStatus?: FlightRoomUserStatus[];
  unreadMessagesCount?: boolean;
  useCustomFiqlTransformation?: boolean;
};

export const flightRoomsQuery: FlightRoomsQueryOptions = {
  limit: FLIGHTROOMS_LIMIT,
  unreadMessagesCount: false,
  useCustomFiqlTransformation: false,
};

export const getFlightRooms = (query: FlightRoomsQueryOptions): Promise<PaginationV1<FlightRoom>> => {
  const url = getUrlWithQueryParams(API_FACTORY.v2('rooms'), parseFlightRoomsQueryOptions(query));

  return HttpClient.get<PaginationV1<FlightRoom>>(url);
};

export const getFlightroomById = (id: string): Promise<PaginationV1<FlightRoom>> => {
  const url = getUrlWithQueryParams(
    API_FACTORY.v2('rooms'),
    parseFlightRoomsQueryOptions({
      queryString: getFlightroomByIdFiql(id),
      useCustomFiqlTransformation: true,
    }),
  );

  return HttpClient.get<PaginationV1<FlightRoom>>(url);
};

export const getFlightroomByIdAndUserIsActive = (id: FlightRoom['externalId']) => {
  const query: FlightRoomsQueryOptions = {
    flightRoomUserStatus: [FlightRoomUserStatus.ACTIVE],
    limit: 1,
    queryString: getFlightroomByIdFiql(id),
    useCustomFiqlTransformation: true,
  };

  const url = getUrlWithQueryParams(API_FACTORY.v2('rooms'), parseFlightRoomsQueryOptions(query));

  return HttpClient.get<PaginationV1<FlightRoom>>(url);
};

export const joinFlightRoom = (externalRoomId: FlightRoom['externalId']): Promise<void> => {
  return HttpClient.post<void>(API_FACTORY.v2(`rooms/${encodeURIComponent(externalRoomId)}/join`));
};

export const parseFlightRoomsQueryOptions = (options?: FlightRoomsQueryOptions) => {
  const params: RequestOptionsRecords & {
    flightRoomUserStatus?: FlightRoomUserStatus[];
    unreadMessagesCount?: boolean;
    useCustomFiqlTransformation?: boolean;
  } = parseRequestOptionsV1ToUrlParams(options);

  if (options?.flightRoomUserStatus) {
    params.flightRoomUserStatus = options.flightRoomUserStatus;
  }

  params.unreadMessagesCount = options?.unreadMessagesCount ?? false;
  params.useCustomFiqlTransformation = options?.useCustomFiqlTransformation ?? false;

  return params;
};
