import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Spinner, Text } from '@aviobook/_shared';
import CheckboxGroup from '@aviobook/_shared/input/checkbox/CheckboxGroup';
import { useSettings } from 'shared';
import { COLORS } from 'styles';

import './settings.scss';

export const Settings: FC = () => {
  const { t } = useTranslation();

  const { isError, isLoading, isUpdating, onUpdatePushNotificationSetting, settings } = useSettings();

  const renderLoadingIndicator = () => {
    return (
      <div className="loading-indicator">
        <Spinner size="large" />
      </div>
    );
  };

  if (isLoading) {
    return renderLoadingIndicator();
  }

  if (isError) {
    return <Text size="MD">{t('ERRORS.GENERAL')}</Text>;
  }

  return (
    <>
      <Text className="settings-push-notifications-title" color={COLORS.zulu.$08} size="SM" weight="bold">
        {t('SETTINGS.PUSH_NOTIFICATIONS')}
      </Text>
      <CheckboxGroup name="settings">
        {settings?.pushNotifications.map(pushSetting => (
          <Checkbox
            checked={pushSetting.isEnabled}
            className="settings-checkbox"
            disabled={isUpdating && pushSetting.isUpdating}
            key={pushSetting.key}
            label={t(`SETTINGS.${pushSetting.key}`)}
            name={pushSetting.key}
            onChange={checked => onUpdatePushNotificationSetting(pushSetting.key, checked)}
          />
        ))}
      </CheckboxGroup>
    </>
  );
};
