import { initReactI18next } from 'react-i18next';
import i18n, { use } from 'i18next';

import en from './locales/en.json';

enum Language {
  ENGLISH = 'en',
}

const options = {
  compatibilityJSON: 'v3' as const, 
  fallbackLng: Language.ENGLISH,
  interpolation: { escapeValue: false },
  resources: {
    en: { translation: en },
  },
};

// @ts-expect-error this is following the docs https://react.i18next.com/guides/quick-start
// eslint-disable-next-line react-hooks/rules-of-hooks
use(initReactI18next).init(options);

export default i18n;
