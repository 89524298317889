import React from 'react';
import { Text } from '@aviobook/_shared';
import { flexRender, Table } from '@tanstack/react-table';
import { COLORS } from 'styles';

import { DeleteButton } from '../deleteButton/DeleteButton';
import { AddButton, FlexComponent } from '../index';

import './tableLayout.scss';

type Props<T> = {
  addButtonAction?: () => void;
  addButtonLabel?: string;
  deleteButtonAction?: () => void;
  deleteButtonDisabled?: boolean;
  emptyState?: () => JSX.Element;
  infoTableText?: string;
  isAddButtonPresent?: boolean;
  isDeleteButtonPresent?: boolean;
  isSaveButtonPresent?: boolean;
  saveButtonAction?: () => void;
  table: Table<T>;
};

export const TableLayout = <T,>({
  addButtonAction = () => {},
  addButtonLabel,
  deleteButtonAction = () => {},
  deleteButtonDisabled = false,
  emptyState: EmptyState,
  infoTableText,
  isAddButtonPresent = false,
  isDeleteButtonPresent = false,
  table,
}: Props<T>) => {
  if (table.getRowModel()?.rows.length === 0) {
    return EmptyState ? <EmptyState /> : null;
  }
  // TODO change this
  return (
    <div>
      <FlexComponent backgroundColor="transparent">
        {isDeleteButtonPresent ? (
          <div>
            <DeleteButton deleteButtonDisabled={deleteButtonDisabled} onClick={deleteButtonAction} />
          </div>
        ) : null}
        {isAddButtonPresent ? (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <AddButton key="add-button" label={addButtonLabel} onClick={addButtonAction} />
          </div>
        ) : null}
      </FlexComponent>
      <>
        {infoTableText ? (
          <Text as="span" color={COLORS.zulu.$04} size="SM">
            {infoTableText}
          </Text>
        ) : null}
        <div className="scrollable-table">
          <table>
            <thead>
              {table.getHeaderGroups()?.map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers?.map(header => (
                    <th key={header.id} style={{ width: header.getSize() }}>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel()?.rows.map(row => (
                <tr key={row.id}>
                  {row.getVisibleCells().map(cell => (
                    <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    </div>
  );
};
