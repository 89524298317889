import { parsePhoneNumberFromString } from 'libphonenumber-js';
import LinkifyIt from 'linkify-it';
import tlds from 'tlds';

const linkify = new LinkifyIt();
linkify.tlds(tlds);

const PHONE_NUMBER_REGEX = /(?:\+?(\d{1,3})[-.\s/]?)?(?:\(?(\d{1,4})\)?[-.\s/]?)?(\d{1,4})[-.\s/]?(\d{1,4})[-.\s/]?(\d{1,9})/g;

export const matchDecorator = (text: string) => {
  const phoneNumbers: string[] = text.match(PHONE_NUMBER_REGEX) || [];
  const defaultMatches = linkify.match(text) || [];

  const phoneNumberMatches = phoneNumbers
    .map(match => {
      const phoneNumber = parsePhoneNumberFromString(match, 'BE'); // Specify default country if needed
      if (phoneNumber && phoneNumber.isValid()) {
        return {
          index: text.indexOf(match),
          lastIndex: text.indexOf(match) + match.length,
          schema: '',
          text: match,
          url: `tel:${phoneNumber.number}`,
        };
      }
      return null;
    })
    .filter(Boolean);

  return [...phoneNumberMatches, ...defaultMatches];
};
