import { FC } from 'react';
import { Text } from '@aviobook/_shared';
import classnames from 'classnames';
import { FontSize } from 'styles';

type Props = {
  isActiveTab: boolean;
  name: string;
  setTab: () => void;
  textSize?: FontSize;
};

export const Tab: FC<Props> = ({ isActiveTab, name, setTab, textSize = 'XL' }) => {
  return (
    <li className={classnames('nav-bar-sub-li', { isActiveTab })} key={name} onClick={setTab}>
      <Text size={textSize} weight="bold">
        {name}
      </Text>
    </li>
  );
};
