import { FC } from 'react';
import { Icon, Text } from '@aviobook/_shared';
import { COLORS } from 'styles';

import { Button } from '../index';

import './confirmationModal.scss';

type Props = {
  cancelButtonAction: () => void;
  cancelButtonLabel: string;
  confirmButtonAction: () => void;
  confirmButtonLabel: string;
  loading: boolean;
  title: string;
};

export const ConfirmationModal: FC<Props> = ({
  cancelButtonAction,
  cancelButtonLabel,
  confirmButtonAction,
  confirmButtonLabel,
  loading,
  title,
}) => {
  return (
    <div className="modal-container" data-test="confirmation-modal">
      <Icon className="close-icon" colorName={COLORS.zulu.$08} name="closeCross" onClick={cancelButtonAction} size={2.5} />
      <div>
        <Text as="h5" color={COLORS.zulu.$06} uppercase weight="bold">
          {title}
        </Text>
        <div className="modal-buttons">
          <Button isOutline onClick={cancelButtonAction} theme="neutral">
            {cancelButtonLabel}
          </Button>
          <Button loading={loading} onClick={confirmButtonAction} theme="negative">
            {confirmButtonLabel}
          </Button>
        </div>
      </div>
    </div>
  );
};
