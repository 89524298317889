import { FC } from 'react';
import { getFlightroomAirportNames, useFlightroomsContext } from 'shared';
import { COLORS } from 'styles';

import { Icon, Text } from '../../../_shared';
import { GrantAccess } from './components';

import './chatHeader.scss';

type Props = {
  onClickInfo: (isOpen: boolean) => void;
};

export const ChatHeader: FC<Props> = ({ onClickInfo }) => {
  const { selectedFlightroom: room } = useFlightroomsContext();
  const { arrivalAirport, departureAirport } = getFlightroomAirportNames(room);

  return (
    <header className="chat-header-container">
      <section className="chat-header-left">{/* <StatusPill label="on time" /> */}</section>
      <section className="chat-header-middle">
        <Text as="span" className="chat-header-title" color={COLORS.zulu.$08} size="LG" weight="bold">
          {room.flightNumber}
        </Text>
        <Text as="span" color={COLORS.zulu.$04} size="XXS" weight="semiBold">
          {departureAirport} &rarr; {arrivalAirport}
        </Text>
      </section>
      <section className="chat-header-right">
        <GrantAccess />
        <Icon
          name="circularInfoOutline"
          onClick={() => {
            if (room.externalId) {
              onClickInfo(true);
            }
          }}
          size={2.5}
          testId="chat-header--info-icon"
        />
      </section>
    </header>
  );
};
