import * as React from "react";
const trash: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m10.942 2.344 3.121.001.138.005a2.347 2.347 0 0 1 2.201 2.202l.004.137v.78h3.907a.781.781 0 0 1 .09 1.557l-.09.005h-.783l.001 12.504a2.344 2.344 0 0 1-2.202 2.34l-.138.003-9.375.015a2.345 2.345 0 0 1-2.343-2.206l-.004-.138V7.03h-.782a.781.781 0 0 1-.09-1.557l.09-.005h3.907v-.783l.004-.138a2.34 2.34 0 0 1 2.206-2.2l.138-.004zM17.97 7.03H7.03l.001 12.518c0 .4.302.73.69.776l.093.005 9.375-.014c.4 0 .73-.302.775-.69l.005-.091V7.03zm-8.595 3.125c.4 0 .73.302.776.69l.005.091v6.25a.781.781 0 0 1-1.557.092l-.005-.091v-6.25c0-.432.35-.782.781-.782zm3.125 0c.4 0 .73.302.776.69l.005.091v6.25a.781.781 0 0 1-1.557.092l-.005-.091v-6.25c0-.432.35-.782.781-.782zm3.125 0c.4 0 .73.302.776.69l.005.091v6.25a.781.781 0 0 1-1.557.092l-.005-.091v-6.25c0-.432.35-.782.781-.782zm-4.688-6.25-.092.004a.78.78 0 0 0-.684.684l-.005.091v.784h4.688V4.69l-.006-.09a.785.785 0 0 0-.686-.687l-.091-.005-3.124-.002z"
      fill="#7E9AA8"
      fillRule="evenodd"
    />
  </svg>
);
export default trash;
