import { FC, PropsWithChildren, ReactNode } from 'react';
import { Text } from '@aviobook/_shared';
import { COLORS } from 'styles';

import './pageContentBlock.scss';

type Props = {
  rightButton?: ReactNode;
  title?: string;
};

export const PageContentBlock: FC<PropsWithChildren<Props>> = ({ children, rightButton, title }) => {
  return (
    <section className="page-content-block-container">
      <div className="page-content-block-top">
        <Text color={COLORS.zulu.$08} size="XL" weight="regular">
          {title}
        </Text>
        {rightButton}
      </div>
      <div className="page-content-block-content">{children}</div>
    </section>
  );
};
