import { FC } from 'react';
import { Text } from '@aviobook/_shared';
import { COLORS } from 'styles';

import './emptyState.scss';

type Props = {
  button?: () => JSX.Element;
  description: string;
  title: string;
};
export const EmptyState: FC<Props> = ({ button: Button, description, title }) => {
  return (
    <main className="content-container">
      <div className="content">
        <Text as="span" color={COLORS.zulu.$08} size="LG" uppercase weight="bold">
          {title}
        </Text>
        <Text as="span" className="description" color={COLORS.zulu.$06} size="MD" weight="regular">
          {description}
        </Text>
        <Button />
      </div>
    </main>
  );
};
