import { Iso8601DateString } from '../datetime';

export enum NotificationType {
  ACCESS_REQUEST = 'ACCESS_REQUEST',
  MENTION = 'MENTION',
}

export type NotificationV1 = {
  account: string;
  createdAt: Iso8601DateString;
  flightNumber: string;
  id: string;
  isRead: boolean;
  isVisible: boolean;
  message?: string;
  room: string;
  text?: string;
  triggeredBy: {
    crewRoleName: string;
    externalId: string;
    jobTitle: string;
    username: string;
  };
  type: NotificationType;
  user: string;
};
