import { FC } from 'react';
import { IconName } from 'assets';
import { MessageStatus } from 'types';

import { Icon } from '../icon/Icon';

type Props = {
  size?: number;
  status: MessageStatus;
};

export const MessageStatusIcon: FC<Props> = ({ size = 1.5, status }) => {
  if (![MessageStatus.SENT, MessageStatus.DELIVERED, MessageStatus.READ].includes(status)) {
    return null;
  }

  const translateStatusToIcon = (status: MessageStatus): IconName => {
    switch (status) {
      case MessageStatus.SENT:
        return 'singleCheckmark';
      case MessageStatus.DELIVERED:
        return 'doubleCheckmark';
      case MessageStatus.READ:
        return 'doubleCheckmarkBgCircle';
    }
  };

  return <Icon name={translateStatusToIcon(status)} size={size} />;
};
