import * as React from "react";
const mentionFilled: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#q2z5a3ouaa)" fill="#47AACF">
      <path d="M12.004 8.485c.214.27.308.548.282.833-.121 1.353-.242 2.22-.363 2.598-.134.407-.349.711-.644.914-.295.203-.658.305-1.087.305-.739 0-1.296-.264-1.671-.792-.376-.542-.564-1.218-.564-2.03 0-.637.107-1.165.322-1.584.228-.42.51-.724.846-.914a2.066 2.066 0 0 1 1.047-.304c.348 0 .69.094 1.026.284.336.176.604.406.806.69z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.313 19.375a9.062 9.062 0 1 0 0-18.125 9.062 9.062 0 0 0 0 18.125zm3.784-15.397c-1.046-.569-2.22-.853-3.523-.853-1.382 0-2.644.318-3.785.954A7.135 7.135 0 0 0 4.112 6.7c-.658 1.11-.987 2.341-.987 3.695 0 1.34.322 2.551.966 3.634a6.747 6.747 0 0 0 2.678 2.538c1.154.623 2.463.934 3.926.934.524 0 1.047-.04 1.57-.122.537-.081.98-.19 1.33-.325.2-.08.341-.162.422-.243a.408.408 0 0 0 .14-.325.58.58 0 0 0-.2-.447.438.438 0 0 0-.423-.101c-.698.176-1.208.29-1.53.345a6.515 6.515 0 0 1-1.108.081c-1.288 0-2.422-.25-3.402-.751-.967-.501-1.718-1.198-2.255-2.091-.523-.894-.785-1.923-.785-3.087 0-1.164.262-2.213.785-3.147a5.729 5.729 0 0 1 2.215-2.213c.953-.541 2.026-.812 3.22-.812 1.101 0 2.068.23 2.9.69a4.613 4.613 0 0 1 1.913 1.889c.456.798.684 1.712.684 2.74 0 .596-.074 1.192-.221 1.787-.148.582-.383 1.07-.705 1.462-.309.38-.684.569-1.127.569-.296 0-.504-.095-.625-.285-.12-.203-.18-.5-.18-.893 0-.447.066-1.15.2-2.111l.061-.488c.08-.65.208-1.4.383-2.254.04-.203 0-.372-.121-.507-.108-.135-.289-.203-.544-.203-.402 0-.624.169-.664.507l-.06.508a2.854 2.854 0 0 0-1.027-.893c-.417-.217-.9-.325-1.45-.325-.684 0-1.288.176-1.812.528-.51.338-.906.798-1.188 1.38-.282.582-.423 1.232-.423 1.95 0 .582.094 1.17.282 1.766.202.595.55 1.103 1.047 1.523.51.42 1.195.63 2.054.63 1.073 0 1.865-.326 2.376-.975.093.379.295.69.604.934.308.23.697.345 1.167.345.658 0 1.235-.244 1.732-.731.51-.488.899-1.117 1.167-1.889a7.178 7.178 0 0 0 .403-2.375c0-1.205-.302-2.294-.906-3.269-.604-.975-1.436-1.74-2.497-2.294z"
      />
    </g>
    <defs>
      <clipPath id="q2z5a3ouaa">
        <path fill="#fff" transform="translate(.4 .4)" d="M0 0h19.2v19.2H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default mentionFilled;
