import * as React from "react";
const chevronLeft: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.234 6.679a1.003 1.003 0 0 0-.056-1.413.992.992 0 0 0-1.406.055l-6.01 6.527c-.353.385-.352.978.004 1.361l6.009 6.47a.992.992 0 0 0 1.407.048c.402-.376.424-1.01.049-1.413l-5.378-5.79 5.38-5.845z"
      fillRule="evenodd"
    />
  </svg>
);
export default chevronLeft;
