import { useEffect, useState } from 'react';
import { GiftedChat, MessageProps } from 'react-native-gifted-chat';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Spinner } from '@aviobook/_shared';
import { getIconForOccupation } from '@aviobook/_utils';
import { IconName } from 'assets';
import {
  mapToChatMessage,
  useAuthenticationContext,
  useChatMessagesContext,
  useFlightroomsContext,
  useMentions,
  useSocket,
  useSocketEvent,
  useUsersContext,
} from 'shared';
import { sendRoomOpenEvent } from 'shared/src/sockets/socket';
import { GiftedMessage, MessageV1 } from 'types';

import { useAppContext } from '../_context';
import {
  ChatEmpty,
  ChatHeader,
  CountdownTimer,
  FlightRoomInfoSideModal,
  ImagePreview,
  InputToolbar,
  MessageInfoSideModal,
} from './_components';
import { GiftedChatMessage } from './_components/GiftedChatMessage/GiftedChatMessage';
import { MentionInfoSideModal } from './_components/mentionInfo';

import './chat.scss';

type ScrollEvent = {
  contentOffset: { y: number };
  contentSize: { height: number };
  layoutMeasurement: { height: number };
};

export const Chat = () => {
  const { selectedFlightroom: room } = useFlightroomsContext();
  const { imagePreview, setImagePreview } = useAppContext();
  const { users } = useUsersContext();
  const { user } = useAuthenticationContext();
  const { chat, fetchOlderMessages, hasOlderMessages, isFetching, remove, retry, send } = useChatMessagesContext();
  const { socket } = useSocket();
  const { getUsedMentions } = useMentions();

  const [isFlightRoomInfoModalOpen, setIsFlightRoomInfoModalOpen] = useState(false);
  const [messageInfoSideModal, setMessageInfoSideModal] = useState<MessageV1>(null);
  const [activeMention, setActiveMention] = useState<string>(null);
  const [attachmentPreview, setAttachmentPreview] = useState<string>(null);
  const [isLoadingGiftedChat, setIsLoadingGiftedChat] = useState(false);

  const onSend = (message: string, attachment?: File) => {
    const mentions = getUsedMentions(message);

    send(message, attachment, mentions);
  };

  const handleScroll = ({ contentOffset, contentSize, layoutMeasurement }: ScrollEvent) => {
    // Calculate the scroll position relative to the content size and layout measurement
    const scrollPosition = contentOffset.y + layoutMeasurement.height;
    // Determine the threshold for fetching more messages (e.g., 100 pixels from the bottom)
    const fetchThreshold = 100;
    // Check if the scroll position is close to the bottom and there are more pages to load,
    if (contentSize.height - scrollPosition <= fetchThreshold && !isFetching && hasOlderMessages) {
      fetchOlderMessages();
    }
  };

  useEffect(() => {
    const hideRoomNotifications = async () => {
      const serviceWorker = await window.navigator.serviceWorker?.getRegistration('/firebase-push-notification-scope');
      const notifications = await serviceWorker?.getNotifications();

      notifications?.forEach(notification => {
        const data = notification.data as { url: string };
        if (data.url?.includes(room.externalId)) {
          notification.close();
        }
      });
    };

    hideRoomNotifications();
    setImagePreview(null);
    setAttachmentPreview(null);
  }, [room, setImagePreview]);

  const getPendingImage = (currentMessage: GiftedMessage<IconName>) => {
    if (currentMessage.pending || !currentMessage.sent) {
      return chat.find(message => message.id === currentMessage._id)?.pendingImage;
    }
    return null;
  };

  const handleSocketReconnect = () => {
    sendRoomOpenEvent(room.externalId);
  };

  useSocketEvent(socket, 'connect', handleSocketReconnect);

  useEffect(() => {
    invisibleMessagesLoadingFix();
  }, []);

  // https://github.com/FaridSafi/react-native-gifted-chat/issues/2448
  const invisibleMessagesLoadingFix = () => {
    const gcLoadingContaineEl = document.querySelectorAll('[data-testid="GC_LOADING_CONTAINER"]')[0] as HTMLElement;
    if (gcLoadingContaineEl) {
      gcLoadingContaineEl.style.display = 'none';
      setIsLoadingGiftedChat(true);
      setTimeout(() => {
        gcLoadingContaineEl.style.display = 'flex';
        setIsLoadingGiftedChat(false);
      }, 350);
    }
  };

  return (
    <>
      <FlightRoomInfoSideModal
        isVisible={isFlightRoomInfoModalOpen}
        onClose={() => setIsFlightRoomInfoModalOpen(false)}
        room={room}
      />
      <MessageInfoSideModal message={messageInfoSideModal} onClose={() => setMessageInfoSideModal(null)} room={room} />
      <MentionInfoSideModal activeMention={activeMention} onClose={() => setActiveMention(null)} />
      <section className="chat-container">
        <header>
          <ChatHeader onClickInfo={() => setIsFlightRoomInfoModalOpen(prevState => !prevState)} />
          <CountdownTimer flightroom={room} />
        </header>
        {isLoadingGiftedChat ? <Spinner theme="normal" /> : null}
        <SafeAreaProvider>
          <GiftedChat
            infiniteScroll
            key={room.id}
            listViewProps={{
              onScroll: ({ nativeEvent }: { nativeEvent: ScrollEvent }) => handleScroll(nativeEvent),
              scrollEventThrottle: 400,
            }}
            loadEarlier={hasOlderMessages && !isFetching}
            messages={chat.map(message => mapToChatMessage(getIconForOccupation)(room, users, message))}
            messagesContainerStyle={{ alignSelf: 'center', flex: 1, width: '100%' }}
            renderChatEmpty={() => <ChatEmpty isLoading={isFetching} />}
            renderChatFooter={() =>
              imagePreview ? <ImagePreview image={imagePreview} onClose={() => setImagePreview(null)} /> : null
            }
            renderInputToolbar={() => null}
            renderLoadEarlier={() => null}
            renderMessage={(props: MessageProps<GiftedMessage<IconName>>) => (
              <GiftedChatMessage
                firstUnreadMessage={null}
                key={props.currentMessage._id}
                message={props}
                onDelete={remove}
                onRetry={retry}
                pendingImage={getPendingImage(props.currentMessage)}
                setActiveMention={setActiveMention}
                setAttachmentPreview={setAttachmentPreview}
                showMessageInfo={() => setMessageInfoSideModal(chat.find(m => m.id === props.currentMessage._id))}
                userId={user.externalId}
              />
            )}
            user={{ _id: user.externalId }}
          />
          <InputToolbar flightroom={room} key={room.externalId} onSend={onSend} />
          {attachmentPreview ? (
            <ImagePreview image={attachmentPreview} isFullScreen onClose={() => setAttachmentPreview(null)} />
          ) : null}
        </SafeAreaProvider>
      </section>
    </>
  );
};
