import * as React from "react";
const circularInfoOutline: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5 1.563c6.04 0 10.938 4.896 10.938 10.937 0 6.04-4.897 10.938-10.938 10.938-6.04 0-10.938-4.897-10.938-10.938C1.563 6.46 6.46 1.562 12.5 1.562zm0 1.562a9.375 9.375 0 1 0 0 18.75 9.375 9.375 0 0 0 0-18.75zm-.007 6.25c.4-.004.733.295.782.683l.006.091.073 7.898a.781.781 0 0 1-1.556.105l-.006-.09-.073-7.899a.781.781 0 0 1 .774-.788zM12.5 6.25a.781.781 0 1 1 0 1.562.781.781 0 0 1 0-1.562z"
      fillRule="evenodd"
    />
  </svg>
);
export default circularInfoOutline;
