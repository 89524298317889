export const zulu = {
  $01: '#d0dadf',
  $02: '#c4d1d7',
  $03: '#a1b5c0',
  $04: '#7e9aa8',
  $05: '#668798',
  $06: '#486f84',
  $07: '#2c5a71',
  $08: '#144761',
  $09: '#124057',
  $10: '#10394e',
} as const;
