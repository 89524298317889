import * as React from "react";
const check: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>F8E27217-3817-4742-A0B2-2900C081C652</title>
    <g
      id="Flight-Rooms"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="system-messages" transform="translate(-27, -302)" fill="#FFFFFF">
        <g id="System-message-Copy-3" transform="translate(17, 291)">
          <g
            id="Icons/UIKit/Actions/ui-checkmark"
            transform="translate(10, 11)"
          >
            <path
              d="M14.3092266,5.87319631 C14.6001723,5.54068701 15.1055824,5.506993 15.4380917,5.79793864 C15.770601,6.08888429 15.804295,6.59429438 15.5133493,6.92680369 L9.21334933,14.1268037 C8.9086123,14.4750746 8.37283127,14.4929141 8.04560255,14.1656854 L4.23431458,10.3543975 C3.92189514,10.041978 3.92189514,9.53544604 4.23431458,9.2230266 C4.54673401,8.91060717 5.05326599,8.91060717 5.36568542,9.2230266 L8.572,12.4288 L14.3092266,5.87319631 Z"
              id="Path-51"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default check;
