import { FC } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { OpenWebNotificationsParams } from './open-notification-params.types';
import { WebNotificationType } from './web-notification.types';

export const OpenWebNotification: FC = () => {
  const { payload, type } = useParams<OpenWebNotificationsParams>();

  if (type === WebNotificationType.OPEN_FLIGHTROOM) {
    return <Navigate to={`/dashboard/flightrooms?room=${payload}`} />;
  }

  return <Navigate to="/dashboard" />;
};
